import InputGeneric from './InputGeneric';

export default class InputCountrySelect extends InputGeneric {
    init () {
        //super.init();
        if (!this.id) {
            this.id = this.$el.data('id');
        }
        this.event('click', '.js-shipping-country-input', this.onFocus);
        this.event('keyup', '.js-shipping-country-input', this.onKeyUp);
        this.event('click', '.dropdown-menu li', this.onClick);
        $('.ui-dialog-content_wrapper').off('click', this.onFocusOut).on('click', this.onFocusOut);
        this.$el.find('.dropdown').show();
        this.$field = this.$el.find('.js-shipping-country-input');
    }
    onFocus (el, event) {
        event.stopPropagation();
        var dropdown = this.$el.find('.dropdown');
        dropdown.addClass('active');
        dropdown.find('.dropdown-menu').slideToggle(300);
        this.$field.val('');
    }

    onFocusOut (event){
        var dropdown = $(event.delegateTarget).find('.dropdown');
        if(dropdown.hasClass('active')) {
            dropdown.removeClass('active').find('.dropdown-menu').slideToggle(300); 
        }
        if (dropdown.find('.active').length == 0) {
            dropdown.find('.js-shipping-country-input').val(dropdown.find('.js-shipping-country-input').data('displayValue'));
            dropdown.find('.js-native-value').val( dropdown.find('.js-shipping-country-input').data('value'));
        }
    }
    onClick (el, event) {
        if(!el.classList.contains('selected') && !el.classList.contains('disabled')) {
            this.$field.val(el.dataset.displayValue)
                        .data('value', el.dataset.value);
            this.$el.addClass('state-valid')
                        .find('.js-native-value').val(el.dataset.value);
            this.$field.trigger('update');
        }
    }
    onKeyUp (){
        var value = this.$field.val();
        var filter = value ? value.toLowerCase() : '';
        var li = this.$el.find('.custom-option');
        var txtValue = '';
        $.each(li, function(index, el){
            txtValue = $(el).find('.country-name').text();
            if (txtValue.toLowerCase().indexOf(filter) > -1 || filter == '') {
                $(el).show();
            } else {
                $(el).hide();
            }
        });
    }
}
