import Dropdown from '../common/Dropdown';
import dialog from 'dialog';
import countryRg from './countryRegExp.json';
import {each as $each, isFunction} from 'jquery';
import debounce from 'lodash/debounce';
import {STATES} from 'resources';
import 'checkout';

const addressMap = {
        address1: 'address1',
        address2: 'address2',
        city: 'city',
        countryCode: 'country',
        stateCode: 'states_state',
        county: 'county',
        postalCode: 'postal'
    },
    hideCountyFor = Object.keys(STATES),
    showStatesFor = hideCountyFor;

var pcaListenerInitialized = false;

export default class AddressForm extends Dropdown {
    init () {
        super.init();
        this.country = null;
        this.state = null;
        this.fieldsPrefix = this.config.fieldsPrefix || '';
        this.skipValidation = false;

        this.debouncedUpdateContry = debounce(() => {
            this.getById(this.config.countryField, (countryCmp) => {
                var country = countryCmp.getValue();

                if (country !== this.country) {
                    this.country = country;
                    this.onChangeCountry(countryCmp);
                }
            });
            $each(addressMap, (key, value) => {
                this.callFnForId(this.fieldsPrefix + value, 'update');
            });
            this.showFlyout();
        }, 200);

        this.onChild(this.config.countryField, 'change', this.onChangeCountry);
        this.onChild(this.config.countryField, 'change', this.showZipCodeAndCounty);
        this.onChild(this.config.stateField, 'change', this.onChangeState);

        this.callFnForId(this.config.stateField, 'hide');

        this.onChild('addressSelect', 'change', this.onChangeAddress);
        this.getById('addressSelect', (cmp) => {
            this.addressSelectCmp = cmp;
            this.onChangeAddress(cmp, true);
        });
        this.getById(this.fieldsPrefix + 'address1', (cmp) => {
            if (cmp.getValue()) {
                this.showFlyout();
            }
        });
        $each(addressMap, (key, value) => {
            this.onChild(this.fieldsPrefix + value, 'change', this.onChangeSelectedAddress);
        });

        this.isInDialog = this.$el.parents('.ui-dialog').length > 0;
        if (this.isInDialog) {
            this.$dialogInstance = dialog.getCurrent();
            this.eventMgr('dialog.open', this.checkPostCodeAnywhereStatus);
            this.eventMgr('dialog.close', this.checkPostCodeAnywhereStatus);
        }

        this.initPostCodeAnywhere();
        this.checkBillingAddressError();

        this.getById(this.config.countryField, (countryCmp) => {
            this.onChangeCountry(countryCmp);
        });
        this.event('click', '.js-address-form-label', this.toggleAddressForm);
    }
    onChangeCountry (countrySelector) {
        this.country = countrySelector.getValue() || '';

        if (showStatesFor.indexOf(this.country) === -1) {
            this.callFnForId(this.config.stateField, 'hide');
            this.callFnForId(this.config.countyField, 'show');
        } else {
            this.callFnForId(this.config.countyField, 'hide');
            if (STATES[this.country]) {
                this.getById(this.config.stateField, (stateCmp) => {
                    const currentStateCode = stateCmp.getValue() || '';

                    stateCmp.setOptions(STATES[this.country].map((state) => ({
                        value: state.ID,
                        label: state.displayName
                    })));

                    if (STATES[this.country].some((state) => currentStateCode === state.ID)) {
                        stateCmp.setValue(currentStateCode, true);
                    }
                });
            }
            this.callFnForId(this.config.stateField, 'show');
        }
        this.getById(this.config.postalField, (postalCmp) => {
            postalCmp.clearError();
            postalCmp.config.regEx = countryRg[this.country.toUpperCase()] || '';
        });
        this.hideZipCodeAndSetDefaultVal(this.country);
    }
    showZipCodeAndCounty (countrySelector) {
        if (countrySelector.getValue() != 'AE') {
            this.$el.find('.js-billing-zipcode').removeClass('hidden');
            this.$el.find('.js-billing-postalcode').addClass('hidden');
            this.$el.find('.js-billing-county').removeClass('js-hidden');
        } else {
            this.$el.find('.js-billing-zipcode').addClass('hidden');
            this.$el.find('.js-billing-postalcode').removeClass('hidden');
            this.$el.find('.js-billing-county').addClass('js-hidden');
        }
    }
    hideZipCodeAndSetDefaultVal (countrySelector) {
        const countryList = ['HK'];
        var postalForm = this.$el.find('.js-form-postal'),
            postalInput = this.$el.find('.js-form-postal .form-row_field input');

        if (countryList.indexOf(countrySelector) !== -1) {
            postalForm.addClass('hidden')
            postalInput.val('00000');
        } else {

            if (postalInput.val() === '00000') {
                postalInput.val('');
            }
            postalForm.removeClass('hidden');
        }
    }
    onChangeState (stateSelector) {
        this.state = stateSelector.getValue();
    }
    onShow () {
        this.callFnForId('addressDefault', 'hide');
        this.callFnForId('buttonBlock', 'hide');
    }
    onHide () {
        this.callFnForId('addressDefault', 'show');
        this.callFnForId('buttonBlock', 'show');
    }
    beforeSubmit () {
        var result = true;

        this.getById(this.config.flyout, (flyoutForm) => {
            result = flyoutForm.defaultValidate();
        });

        if (!result) {
            this.callFnForId(this.config.flyout, 'show');
        }

        return result;
    }
    isChildsValid (cb) {
        var valid = true;

        this.eachChild((item) => {
            if (isFunction(item.validate)) {
                if (!item.validate()) {
                    if (valid && item.setFocus) {
                        item.setFocus();
                    }
                    valid = false;
                }
            }
        });
        if (valid && typeof cb === 'function') {
            cb();
        }
        return valid;
    }
    validate () {
        var valid = true;

        if (this.skipValidation) {
            return valid;
        }

        if ((this.id ==='homeDeliveryForm') ||
            (this.id ==='billingAddressForm' && this.$el.find('.js-new-billing-address').is(':checked'))){
            valid = this.isChildsValid();
        }

        this.getById(this.config.insideFormId, (form) => {
            valid = form.validate();
        });

        if (!valid) {
            this.showFlyout();
            this.showAddressForm();
        }

        return valid;
    }
    onChangeAddress (addressSelector, init = false) {
        var optionConfig = addressSelector.getSelectedOptionConfig(),
            address = optionConfig.address;

        this.isInChangeMode = true;
        $each(addressMap, (key, value) => {
            if (address|| (!address && key !== 'countryCode')) {
                this.callFnForId(this.fieldsPrefix + value, 'setValue', address ? address[key] : '', init);
            } else {
                this.callFnForId(this.fieldsPrefix + value, 'setValue', Resources.LOCALE_COUNTRY_CODE, init);
            }
        });

        this.callFnForId(this.config.addressLookupField, 'setValue', '', true);

        if (address && address.ID) {
            this.showFlyout();
            this.showAddressForm();
        } else {
            this.hideFlyout();
            this.hideAddressForm();
        }
        this.isInChangeMode = false;
        this.updateShippingmethod();
    }
    onChangeSelectedAddress () {
        if (!this.isInChangeMode && this.addressSelectCmp && this.addressSelectCmp.getValue()) {
            this.addressSelectCmp.setValue('', true);
        }
    }
    initPostCodeAnywhere () {
        if (window.pca) {
            if (!pcaListenerInitialized) {
                window.pca.on('options', (service, key, options) => {
                    if (service === 'capture+') {
                        options.setCountryByIP = false;
                    }
                });
                window.pca.on('load', (service, key, options) => {
                    var locale = this.$el.find('.js-current-locale').val();

                    if (locale && service === 'capture+') {
                        options.setCulture(locale);
                    }
                });
                window.pca.on('data', () => {
                    this.debouncedUpdateContry();
                    
                    //validate field from pca
                    this.callFnForId(this.config.addressLookupField, 'validate', true, this.config.addressLookupField);
                });
                pcaListenerInitialized = true;
            }
            if (!this.postcodeInited && window.pca.load) {
                window.pca.load();
                this.postcodeInited = true;
            }
        }
    }
    destroyPostCodeAnywhere () {
        if (this.postcodeInited && window.capturePlus && window.capturePlus.destroy) {
            window.capturePlus.destroy();
            this.postcodeInited = false;
        }
    }
    checkPostCodeAnywhereStatus () {
        if (dialog.isOpen(this.$dialogInstance)) {
            this.initPostCodeAnywhere();
        } else {
            this.destroyPostCodeAnywhere();
        }
    }
    clearFields () {
        $each(addressMap, (key, value) => {
            this.callFnForId(this.fieldsPrefix + value, 'setValue', '', true);
        });
    }
    setSkipValidationForFields (newValue = false) {
        this.skipValidation = newValue;
    }
    updateShippingmethod () {}
    toggleAddressForm () {
        this.$el.find('.js-address-form').toggleClass('hidden');
    }
    showAddressForm () {
        this.$el.find('.js-address-form').removeClass('hidden');
        this.$el.find('.js-address-form-label').addClass('checkout-input_quickaddress-label--opened');
    }
    hideAddressForm () {
        this.$el.find('.js-address-form').addClass('hidden');
        this.$el.find('.js-address-form-label').removeClass('checkout-input_quickaddress-label--opened');
    }
    checkBillingAddressError () {
        if (this.$el.hasClass('showAddressError')){
            this.showAddressForm();
        }
    }
}
