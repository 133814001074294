import dialog from 'dialog';
import Cookies from 'js-cookie';
import eventMgr from '../eventMgr';
import screendetector from 'screendetector';
import NewsletterSubmit from './NewsletterSubmit';

export default class NewsletterSubscribe extends NewsletterSubmit {
    init () {
        super.init();

        if (window.location.href.indexOf('source=em_orlebar_brown') > -1) {
            this.setCookie();
            this.skipPopup = true;
        }

        if (!this.config.desktopShow) {
            this.setCookie();
            this.skipPopup = true;
        }

        this.eventMgr('body.crosstopborder', function(data) {
            if (!Cookies.get('onleave_signup_prompted') 
                    && !this.skipPopup 
                    && ($(document).find('.ui-dialog[style*="display: block;"] .js-shipping-country-selector').length === 0)) {
                Cookies.set('onleave_signup_prompted', true, {
                    expires: 365,
                    path: '/'
                });

                eventMgr.execute('newsletter.submit', {
                    method: this.$el.attr('method'),
                    action: this.$el.attr('action')
                }).then(this.callBack);
            }
        });
    }
    setCookie () {
        Cookies.set('signup_prompted', true, {
            expires: 365,
            path: '/'
        });
    }
    callBack (response) {
        var options = {
            modal: true,
            dialogClass: 'ui-dialog_subscribe',
            width: '760'
        };

        dialog.open({
            html: response,
            options: options
        });
    }
}
