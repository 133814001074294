import Block from '../common/Block';

export default class DateSelect extends Block {
    init () {
        super.init();
        this.isValid = true;
        this.onChild(this.config.yearField, 'change', this.check);
        this.onChild(this.config.monthField, 'change', this.check);
    }
    check () {
        if (!this.isValid) {
            this.validate();
        }
    }
    setFocus () {
        this.getById(this.config.monthField, (cmpMonth) => {
            cmpMonth.setFocus();
        });
    }
    validate () {
        var valid = true;

        this.getById(this.config.yearField, (cmpYear) => {
            if (cmpYear.shown && !cmpYear.disabled) {
                const year = cmpYear.getValue();

                valid = cmpYear.validate() && valid;
                this.getById(this.config.monthField, (cmpMonth) => {
                    if (cmpMonth.shown && !cmpMonth.disabled) {
                        const month = cmpMonth.getValue();

                        valid = cmpMonth.validate() && valid;
                        if (valid && new Date(+year, +month - 1) < new Date()) {
                            valid = false;
                            cmpMonth.setError(this.config.error);
                        }
                    }
                });
            }
        });
        this.isValid = valid;
        return valid;
    }
}