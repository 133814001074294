import $ from 'jquery';
import eventMgr from './eventMgr';

const emitter = eventMgr.getEmitter('scroll'),
    $window = $(window),
    emitScroll = () => {
        emitter.emit('scroll', {
            scrollTop: $window.scrollTop()
        });
    };

var scrollEnabled = true;


eventMgr.on('Html.scroll.disable', () => {
    setTimeout(() => {
        emitScroll();
        scrollEnabled = false;
    }, 200);
});

eventMgr.on('Html.scroll.enable', () => {
    scrollEnabled = true;
    emitScroll();
});

$window.on('scroll touchmove', () => {
    if (scrollEnabled) {
        emitScroll();
    }
});
