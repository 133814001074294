'use strict';

import eventMgr from 'eventMgr';
import util from 'utils';

const init = (key) =>
    util.cachedGetScript(util.appendParamsToUrl('//maps.googleapis.com/maps/api/js', {
        key: key
    }));

eventMgr.registerAction('googleMap.init', init);