import InputText from '../forms/InputText';

export default class InputCardNumber extends InputText {
    init () {
        super.init();
    }
    onKeyUp () {
        var currentValue = this.getValue().replace(/[\D-]/g, '');
        this.setValue(currentValue, true);
        this.emitter.emit('keyup', this);
    }
}
