import $ from 'jquery';

var componentsRegister = {};

var RegistryMgr = {
    register: function (nameOrObj, component) {
        if (nameOrObj !== null && typeof nameOrObj === 'object') {
            $.extend(componentsRegister, nameOrObj);
        } else {
            componentsRegister[nameOrObj] = component;
        }
    },
    getComponents: function () {
        return componentsRegister;
    }
};

module.exports = RegistryMgr;
