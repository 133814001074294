import Component from '../Component';
import eventMgr from 'eventMgr';
import ajax from 'ajax';

export default class YotpoReviews extends Component {
	init() {
		 super.init();
		 this.ajaxLoad(this.$el);
	}
	ajaxLoad($el) {
		var url = 'https://api.yotpo.com/reviews/[reviewID]';
		var reviewID = $el.data('reviewId');
		url = url.replace('[reviewID]', reviewID);
		
		ajax({
	        url: url,
	        type: 'GET'
	    }).then((resp) => {
	    	var result = resp.response;
	    	var review = result.review;
	    	
	    	for (var i in review.products) {
	    		var $productEl = $el.find('.product-link-wrapper');
	    		if (i == 0) {
	    			$productEl.css('display', 'none');
	    			continue;
	    		}
	    		
	    		var product = review.products[i].Product;
	    		$productEl.attr('href', product.product_url);
	    		$productEl.find('div').attr('data-product-id', product.id);
	    		$productEl.find('div').text(product.name);
    			$productEl.css('display', 'block');
	    	}
	    });
	}
}
