import Block from '../common/Block';
import eventMgr from 'eventMgr';
import $ from 'jquery';
import lazyImageObserverObj from 'lazyImageObserver';
import util from 'utils';

var $productGrid = $('.js-grid');
var $heroLi1 = $productGrid.find('.hero-li-1');
var $heroLi2 = $productGrid.find('.hero-li-2');
var $heroSlot1 = $productGrid.find('.hero-li-1 .hero-slot');
var $heroSlot2 = $productGrid.find('.hero-li-2 .hero-slot');
var slot1Row = $heroSlot1.data('row') ? Math.abs($heroSlot1.data('row')) : 3; // default value for heroslot 1 row is 3
var slot2Row = $heroSlot2.data('row') ? Math.abs($heroSlot2.data('row')) : 6; // default value for heroslot 2 row is 6
var productRowsBeforeHero1 = slot1Row - 1;
var productRowsBeforeHero2 = slot2Row - 2;
export default class Grid extends Block {
    init () {
        super.init();
        $(window).on('popstate', (event) => {
            if (!event.originalEvent.state) {
                eventMgr.execute('grid.getData', {
                    url: location.href,
                    isPopState: true
                })
            }
        });

        $(window).resize(this.positionHeroSlots);

        this.eventMgr('grid.updated', this.updateGrid);
        this.eventMgr('grid.updated', this.updateLazyLoad);
        this.eventMgr('grid.gotData', this.appendData);
        var selectedOption = $('.plp-viewmode_list--mobile').find(':selected').attr('data-value');
        util.updateViewMode(selectedOption);
    }

    positionHeroSlots() {
        var $allVisibleLIs = $productGrid.find('li.search-result_item:visible');
        var itemsInRow = Math.floor(window.innerWidth / $('.search-result_item:visible').width());

        // Ex. hero slot 1 is set to row 3. We need to find the index of the last tile on row 2 and append the hero slot after it.
        // We get 2 rows (slot1Row - 1) and multiple by itemsInRow. This is the position of the tile.
        // To find the tile index we need to -1 because indexes start from 0;
        var slot1Index = (slot1Row - 1) * itemsInRow - 1;
        var renderedSlot1Row = slot2Row > slot1Row ? itemsInRow : 0; // check if slot 1 and slot 2 are on the same row
        var slot2Index = (slot2Row - 1) * itemsInRow - renderedSlot1Row - 1;

        if ($heroSlot2.data('inited') && (productRowsBeforeHero2) * itemsInRow <= $allVisibleLIs.length) {
            $allVisibleLIs.eq(slot2Index).after($heroLi2);
        }

        if ($heroSlot1.data('inited') && (productRowsBeforeHero1) * itemsInRow <= $allVisibleLIs.length) {
            $allVisibleLIs.eq(slot1Index).after($heroLi1);
        }
    }

    appendSlot($html) {
        // load more
        var loadMore = $html.find('.js-loadmorebtn');
        var searchResults = $('.search-result_item').length;
        if (loadMore.length) {
            var url = new URL(loadMore.attr('href'));
            searchResults = url.searchParams.get('start') * 1;

        }

        //plp-grid-slot
        var $slot = $productGrid.find('.js-slot-1x1');
        if ($slot.length && !$slot.data('inited')) {
            var row = $slot.data('row');
            if (row < searchResults) {
                $productGrid.find('li.search-result_item').eq((row * 1) - 1).after($slot.removeClass('hidden'));
                if (loadMore.length) {
                    $productGrid.find('li.search-result_item:last').addClass('hidden js-single-slot');
                }
                $slot.data('inited', true);
            }
        } else {
            $('.js-grid .js-single-slot').removeClass('hidden');
        }

        // hero slots
        var $allVisibleLIs = $productGrid.find('li.search-result_item:not(".hidden, .hide")');
        var itemsInRow = Math.floor(window.innerWidth / $allVisibleLIs.width());

        // Ex. hero slot 1 is set to row 3. We need to find the index of the last tile on row 2 and append the hero slot after it.
        // We get 2 rows (slot1Row - 1) and multiple by itemsInRow. This is the position of the tile.
        // To find the tile index we need to -1 because indexes start from 0;
        var slot1Index = (slot1Row - 1) * itemsInRow - 1;
        var renderedSlot1Row = slot2Row > slot1Row ? itemsInRow : 0; // check if slot 1 and slot 2 are on the same row
        var slot2Index = (slot2Row - 1) * itemsInRow - renderedSlot1Row - 1;

        // plp-grid-hero-slot-2
        if ($heroSlot2.length && !$heroSlot2.data('inited')) {
            if (loadMore.length) {
                $allVisibleLIs.slice($allVisibleLIs.length - $allVisibleLIs.length % itemsInRow).addClass('hidden js-full-row');
            }

            // determine hero slot 2 row position
            if (productRowsBeforeHero2 * itemsInRow <= $allVisibleLIs.length) {
                $allVisibleLIs.eq(slot2Index).after($heroLi2);
                $heroSlot2.removeClass('hidden')
                $heroSlot2.data('inited', true);
                $heroSlot2.find('.slick-slider').trigger('carouselUpdate');
            }
        } else {
            $('.js-grid .js-full-row').removeClass('hidden');
        }

        //plp-grid-hero-slot-1
        if ($heroSlot1.length && !$heroSlot1.data('inited')) {
            if (loadMore.length) {
                $allVisibleLIs.slice($allVisibleLIs.length - $allVisibleLIs.length % itemsInRow).addClass('hidden js-full-row');
            }

            // determine hero slot 1 row position
            if (productRowsBeforeHero1 * itemsInRow <= $allVisibleLIs.length) {
                $allVisibleLIs.eq(slot1Index).after($heroLi1);
                $heroSlot1.removeClass('hidden')
                $heroSlot1.data('inited', true);
                $heroSlot1.find('.slick-slider').trigger('carouselUpdate');
            }
        } else {
            $('.js-grid .js-full-row').removeClass('hidden');
        }
    }

    updateGrid (data) {
        var $html = $('<div>' + data + '</div>'),
            $grid = $html.find('.js-grid');

        this.$el.attr('class', $grid.attr('class'));
        this.setHtml($grid.html());
        setTimeout(() => { eventMgr.execute('grid.refreshImages') }, 250);
    }
    appendData (data) {
        var $html = $('<div>' + data + '</div>'),
            $grid = $html.find('.js-grid');
        var self = this;
        this.$el.append($grid.html());
        if (!$('.refinement-item_selected').length) {
            this.appendSlot(self.$el);
        }
        /**
         * refresh the lazyImageObserverObj after the ajax call and new images
         * to be registrated for intersection listening.
         */
        lazyImageObserverObj.refreshObserver();
        if ($('.loading-wrapper').length > 0) {
            $('.loading-wrapper').addClass('hidden');
        }

        this.updateLazyLoad();
    }
    updateLazyLoad() {
        setTimeout(() => eventMgr.execute('lazyload.update'), 1000);
    }
}