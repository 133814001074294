import Block from './Block';
import eventMgr from 'eventMgr';
import 'service';

export default class AjaxBlock extends Block {
    init () {
        super.init();
        if (this.config.source) {
            eventMgr.execute('service.get', {
                url: this.config.source
            }).then((data) => {
                this.setHtml(data);
            });
        }
    }

}
