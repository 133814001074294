import Block from '../common/Block';
import $ from 'jquery';
import eventMgr from 'eventMgr';
import 'grid';
import util from 'utils';

const emitter = eventMgr.getEmitter('Sortby');

$(document).on('mouseup touchend', (e) => {
    emitter.emit('outsideClick', e);
});

export default class Sortby extends Block {
    init () {
        super.init();
        this.toggleVisibility();

        $(window).on('resize', () => {
            this.toggleVisibility();
        });

        this.event('click', '.' + this.config.triggerclass, this.onSortbyClick);
        this.event('change', '.plp-sortby_list--mobile', this.onSortbyClick);
        this.event('click', '.js-toggle', this.onToggle);
        this.eventMgr('grid.updated', this.refresh);
        this.eventMgr('grid.gotData', this.refresh);

        this.event('focusout', 'ul.plp-sortby_list li:last-child', this.lastItem);

        if (!this.config.skipOutsideClick) {
            this.eventMgr('Sortby.outsideClick', this.onOutsideClick);
        }
    }

    lastItem () {
        if (this.$el.hasClass(this.config.toggleclass)) {
            this.$el.removeClass(this.config.toggleclass);
            this.$el.find('button').attr('aria-expanded', 'false').focus();
        }
    }

    onOutsideClick (e) {
        if (this.$el.hasClass(this.config.toggleclass) && !this.$el.is(e.target)
            && this.$el.has(e.target).length === 0) {
            this.$el.removeClass(this.config.toggleclass);
        }
    }

    onSortbyClick (el, ev) {
        ev.preventDefault();
        var sortUrl;

        if (el.classList.contains('plp-sortby_list--mobile')) {
            sortUrl = $(el).find(':selected').attr('value');
            $(el).width('fit-content');
        } else if (el.classList.contains('desktop-link')) {
            sortUrl = $(el).attr('href');
            this.$el.toggleClass(this.config.toggleclass);
        }
        var selectedOption = $('.plp-viewmode_list--mobile').find(":selected").attr('data-value');
        sortUrl = util.updateGridurl(sortUrl);
        eventMgr.execute('grid.getData', {
            url: sortUrl
        }).then (function() {
            util.updateViewMode(selectedOption);
        });

        return false;
    }

     onToggle() {
        this.$el.toggleClass(this.config.toggleclass);

        if (this.$el.find('button').length > 0) {
            if (this.$el.hasClass(this.config.toggleclass)) {
                this.$el.find('button').attr('aria-expanded', 'true');
            } else {
                this.$el.find('button').attr('aria-expanded', 'false');
            }
        }
    }

    refresh (data) {
        var $html = $('<div>' + data + '</div>'),
            $sortby = $html.find('.js-sortby');

        this.setHtml($sortby.html());
    }

    toggleVisibility() {
        var windowWidth = window.screen.width;

        switch (true) {
            case (windowWidth < this.tabletBreakpointEnd):
                this.$el.removeClass('js-sortby-visibility');
                break;
            case (windowWidth >= this.tabletBreakpointEnd):
                this.$el.addClass('js-sortby-visibility');
                break;
            default:
                this.$el.removeClass('js-sortby-visibility');
        }
    }
}