import Block from './Block';
import Cookies from 'js-cookie';

export default class CookiesPolicy extends Block {
    init () {
        super.init();

        this.bottom = this.$el.css('bottom');
        this.$el.css('bottom', '0px');

        if (navigator.cookieEnabled || document.cookie.length > 0) {
            if (!Cookies.get('cookiesAccepted')) {
                this.show();
                this.event('click', '.js-close', this.closeBlock);
            }
        }
    }
    closeBlock (el, e) {
        e.preventDefault();
        Cookies.set('cookiesAccepted', true, {
            expires: 365,
            path: '/'
        });
        this.hide();
    }
}
