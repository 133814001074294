import Form from '../forms/Form';
import eventMgr from '../eventMgr';
import $ from 'jquery';
import 'cart';
import dialog from 'dialog';
import preloader from 'preloader';

const cartForm = eventMgr.getEmitter('CartForm');

export default class CartForm extends Form {
    init() {
        super.init();

        /**
         * ORBOPT-3536 Apple Pay - Shipping method issue
         */
        var counter = 0;
        var timer = setInterval(function() {
            if (counter === 2) {
                clearInterval(timer);
            } else {
                document.cookie = 'dwapupreq=' + Math.floor((Math.random() * 100) + 1) + ';';
                counter++;
            }
        }, 500);

        this.eventMgr('LineItemCount.change.' + this.id, this.submit);
        this.eventMgr('Promocode.change.' + this.id, this.submit);
        this.eventMgr('cart.updated', this.update);
        this.event('change', '.cart-giftwrap_field', this.submitGiftInformation);
        this.event('change', '.cart-giftwrap_message', this.submitGiftInformation);
        this.event('click', '.dw-apple-pay-button', this.sendGADataApplePay);
        this.event('click', '.cart-giftwrap_field', this.showGiftTextBox);
        this.event('DOMSubtreeModified', '.cart-footer, .cart-action_mobile--top', this.showDeliveryNote);

        this.showDeliveryNote();
    }

    sendGADataApplePay() {
        window.dataLayer.push({
            hitType: 'event',
            eventCategory: 'Shopping Bag Payment',
            eventAction: 'Express checkout',
            eventLabel: 'Apple Pay Express Checkout'
        });
    }

    showDeliveryNote() {
        if (window.ApplePaySession && (this.$el.find('isapplepay').length === 0)) {
            this.$el.find('.js-apple-pay-enabled').removeClass('hidden');
            this.$el.find('.js-apple-pay-disabled').addClass('hidden');
        } else {
            this.$el.find('.js-apple-pay-disabled').removeClass('hidden');
            this.$el.find('.js-apple-pay-enabled').addClass('hidden');
        }
    }

    showGiftTextBox() {
    	var $giftWrapCHeckbox = $('.cart-giftwrap_field input[name=dwfrm_cart_isGift]');
        if ($giftWrapCHeckbox.length > 0 && $giftWrapCHeckbox.is(':checked')) {
        	$giftWrapCHeckbox.closest('.cart-giftwrap_field').siblings('.cart-giftwrap_message').removeClass('hidden');
        } else {
        	$giftWrapCHeckbox.closest('.cart-giftwrap_field').siblings('.cart-giftwrap_message').addClass('hidden');
        }
    }

    submitGiftInformation() {
        $.ajax({
            url: Urls.submitGiftForm,
            method: 'POST',
            data: {
                isGift: this.$el.find('.cart-giftwrap_field input').prop('checked'),
                giftMessage: this.$el.find('.cart-giftwrap_message textarea').val()
            }
        });
    }

    submit (actionName) {
        preloader.show(this.$el);
        if (this.isChildsValid()) {
            var fields = this.getFormFields();

            fields[actionName] = true;

            eventMgr.execute('cart.update', {
                method: this.$el.attr('method'),
                action: this.$el.attr('action'),
                fields: fields
            });
        }
    }

    update(data) {
        var $html = $('<div>' + data.html + '</div>'),
            $cartForm = $html.find('.js-cart-form');

        dialog.close();

        this.$el.attr('class', $cartForm.attr('class'));
        this.setHtml($cartForm.html());

        var mentionMeScripts = $('script[src*="mention-me"]');

        if (mentionMeScripts.length > 0) {
            var srcToReload;

            mentionMeScripts.each((index, el) => {
                var $this = $(el),
                    src = $this.attr('src');

                if (src.toLowerCase().indexOf('refereefind') > -1) {
                    srcToReload = src;
                }
            }).remove();

            if (srcToReload) {
                $('<script>').attr('src', srcToReload).appendTo('head');
            }
        }
        this.showDeliveryNote();
    }
}
