import Block from './Block';

export default class Footer extends Block {
    init() {
        super.init();

        this.event('click', '.footer-accordion .accordion-title:not(.active)', this.accordionToggle);
        this.event('click', '.footer-accordion .accordion-title.active', this.hideAccordion);

        this.eventMgr('shippingcountry.changed', this.updateCountrySelector);
        this.event('click', '.js-country-selector', this.openCountrySelectorPopup);
    }

    accordionToggle(el, event) {
        var windowWidth = $(window).width();
        // enable accordion functionality for mobile
        if (windowWidth < this.tabletBreakpointEnd) {
            $('.accordion-panel').css('display', 'none').attr('aria-hidden', true);
            $('.accordion-title').removeClass('active').attr('aria-expanded', false).attr('aria-selected', false);
            var $box = $(el).closest('.accordion-list');
            $box.children('.accordion-panel').css('display', 'block').attr('aria-hidden', false);
            $(el).addClass('active').attr('aria-expanded', true).attr('aria-selected', true);
            if ($box.parents('.product-detail').length) {
                var pdpSticky = $('.product-detail').data('sticky');
                if (pdpSticky) {
                    pdpSticky.updateSticky();
                }
            }
        }
    }

    hideAccordion() {
        $('.accordion-panel').css('display', 'none').attr('aria-hidden', true);
        if ($('.accordion-title.active').parents('.product-detail').length) {
            var pdpSticky = $('.product-detail').data('sticky');
            if (pdpSticky) {
                pdpSticky.updateSticky();
            }
        }
        $('.accordion-title').removeClass('active').attr('aria-expanded', false).attr('aria-selected', false);
        return false;
    }

    openCountrySelectorPopup() {
        this.$el.find('.js-open-country-selector-popup-header a').click();
    }

    updateCountrySelector() {
        var self = this;

        $.ajax({
            url: Urls.includeCountrySelector
        }).done(function (html) {
            if (html) {
                self.$el.find('.js-country-selector-wrapper').replaceWith(html);
            }
        });
    }
}
