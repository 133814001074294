'use strict';

import ajax from 'ajax';
import eventMgr from 'eventMgr';
import $ from 'jquery';

const submit = (data) =>
    ajax.load({
        type: data.method,
        url: data.action,
        data: data.fields
    });

const newsletterDataSubmit = (data) => {
    return new Promise(function(resolve, reject) {
        $.ajax({
            method: 'POST',
            url: data.action,
            data: data.fields,
            iframe: true,
            success: resolve,
            error: reject
        });
    });
};

eventMgr.registerAction('newsletter.submit', submit);
eventMgr.registerAction('newsletter.data.submit', newsletterDataSubmit);
