import Form from '../forms/Form';
import $ from 'jquery';

export default class FormOAuthLogin extends Form {
    init() {
        super.init();

        this.event('click', '.js-oauth_icon', (el) => {
            $('.js-oauth_provider').val($(el).data('providerId'));
        });
    }
}
