import Block from '../common/Block';
import $ from 'jquery';
import Cookies from 'js-cookie';

const STICKY_FOOTER_OFFSET = 38,
    MOBILE_OFFSET = 10000;

export default class LiveChat extends Block {
    init () {
        super.init();

        this.livechat = window.zE;

        if (this.livechat) {
            this.handleZopimLoad();
        }
    }
    handleZopimLoad () {
        this.lastStatus = 'offline';
        this.unreadMessages = 0;
        var verticalOffset = 0;
        var horizontalOffset = window.innerWidth / 4 - 100;

        if (horizontalOffset < 0) {
            horizontalOffset = 0;
        }

        if (!Cookies.get('cookiesAccepted')) {
            verticalOffset = STICKY_FOOTER_OFFSET;
        }

        var $locale = $('input[name="lang"]');
        if($locale.length > 0) {
            zE('webWidget', 'setLocale', $locale.val());
        }

        this.event('click', this.onClick);

        if(this.livechat) {
            zE('webWidget:on', 'chat:status', (status) => {
                this.onStatusChange(status);
            });
            zE('webWidget:on', 'chat:unreadMessages', (msgs) => {
                this.onUnreadMessage(msgs);
            });
            window.zESettings = {
                webWidget: {
                    chat: {
                        prechatForm: {
                            greeting: {
                                '*': Resources.LIVE_CHAT_GREETINGS
                            },
                            departmentLabel: {
                                '*': Resources.LIVE_CHAT_DEPARTMENTS
                            }
                        }
                    },
                    offset: {
                        horizontal: horizontalOffset,
                        vertical: verticalOffset,
                        mobile: {
                            vertical: MOBILE_OFFSET
                        }
                    }
                }
            };
        }
    }
    onClick (el, event) {
        event.preventDefault();
        if (this.livechat) {
            zE('webWidget', 'toggle')
        }
    }
    onStatusChange (status) {
        if (this.config[status + 'Text'] && this.unreadMessages === 0) {
            this.$el.html(this.config[status + 'Text']);
        }
        this.lastStatus = status;
    }
    onUnreadMessage (messagesCount) {
        this.unreadMessages = messagesCount;
        if (this.$el.hasClass('line-space')) {
            if (messagesCount > 0) {
                if (messagesCount === 1) {
                    this.$el.html(this.config.unreadOne);
                } else {
                    this.$el.html((this.config.unreadMulti || '').replace('{0}', messagesCount));
                }
            } else {
                this.onStatusChange(this.lastStatus);
            }
        }
    }
}
