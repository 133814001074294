import enquire from 'enquire.js';
import eventMgr from './eventMgr';

const mobileId = 'mobile',
    tabletId = 'tablet',
    desktopId = 'desktop',
    tabletNewId = 'tablet_new',
    desktopNewId = 'desktop_new',
    tabletLandscapeNewId = 'tablets',
    mediaM = 'screen and (max-width: 767px)',
    mediaT = 'screen and (min-width: 768px) and (max-width: 1279px)',
    mediaD = 'screen and (min-width: 1280px)',
    mediaTNew = 'screen and (min-width: 768px) and (max-width: 1023px)',
    mediaDNew = 'screen and (min-width: 1024px)',
    screenT = 768,
    screenTN = 1024,
    screenD = 1280,
    emitter = eventMgr.getEmitter('screenDetector');

var ScreenDetector = {
    currentDevice: desktopId,
    MOBILE: mobileId,
    TABLET: tabletId,
    TABLET_NEW: tabletNewId,
    TABLET_LANDSCAPE: tabletLandscapeNewId,
    DESKTOP: desktopId,
    DESKTOP_NEW: desktopNewId,
    init: function () {
        //Mobile
        enquire.register(
            mediaM,
            [{
                match: () => {
                    this.matchViewport(mobileId);
                }
            }]
        );
        //Tablet
        enquire.register(
            mediaT,
            [{
                match: () => {
                    this.matchViewport(tabletId);
                }
            }]
        );
        //Tablet NEW
        enquire.register(
            mediaTNew,
            [{
                match: () => {
                    this.matchViewport(tabletNewId);
                }
            }]
        );

        //Desktop NEW
        enquire.register(
            mediaDNew,
            [{
                match: () => {
                    this.matchViewport(desktopNewId);
                }
            }]
        );
        //Desktop
        enquire.register(
            mediaD,
            [{
                match: () => {
                    this.matchViewport(desktopId);
                }
            }]
        );

        return this;
    },
    getDeviceId: function () {
        return this.currentDevice;
    },
    getScreenDeviceId: function () {
        var width = window.screen.width,
            deviceId;

        if (width < screenT) {
            deviceId = this.MOBILE;
        } else if (width >= screenT && width < screenD) {
            deviceId = this.TABLET;
        } else if (width >= screenD) {
            deviceId = this.DESKTOP;
        }
        return deviceId;
    },
    matchViewport: function (newDevice) {
        var previousDevice = this.currentDevice;

        this.currentDevice = newDevice;
        emitter.emit(newDevice, newDevice);
        if (previousDevice !== this.currentDevice) {
            emitter.emit('deviceChange', this.currentDevice);
        }
    }
};

ScreenDetector.init();

module.exports = ScreenDetector;
