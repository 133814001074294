'use strict';

import $ from 'jquery';
import {appendParamToURL, toAbsoluteUrl, Alert as alarm} from 'utils';
import resources from 'resources';
import page from 'pageUtils';
import preloader from 'preloader';

var currentRequests = {};

function makeHash(url, data) {
    var params = '';

    if (data) {
        params = JSON.stringify(data);
    }
    return url + params;
}

function handleJsonRedirect(response) {
    response = response || {};
    if (response.redirectTo) {
        page.redirect(response.redirectTo);
    }
}

/**
 * @function
 * @description Ajax request to get json response
 * @param {Boolean} async  Asynchronous or not
 * @param {String} url URI for the request
 * @param {Object} data Name/Value pair data request
 * @param {Function} callback  Callback function to be called
 */
var getJson = function (options) {
    options.url = toAbsoluteUrl(options.url);
    // return if no url exists or url matches a current request
    if (!options.url) {
        return $.Deferred().reject();
    }
    if (currentRequests[makeHash(options.url, options.data)]) {
        return currentRequests[makeHash(options.url, options.data)];
    }

    // make the server call
    var deffered = $.ajax({
        dataType: options.dataType || 'json',
        type: options.type || 'GET',
        url: appendParamToURL(options.url, 'format', 'ajax'),
        async: (typeof options.async === 'undefined' || options.async === null) ? true : options.async,
        data: options.data || {},
        statusCode: {
            401: (response) => {
                handleJsonRedirect(response.responseJSON);
            }
        }
    })
    // success
    .done((response) => {
        if (options.callback) {
            options.callback(response);
        }
    })
    // failed
    .fail((xhr, textStatus) => {
        if (!xhr.readyState && xhr.statusText === 'error') {
            page.refresh();
            return;
        }
        if (textStatus === 'parsererror') {
            alarm(resources.BAD_RESPONSE);
        }
        if (options.callback) {
            options.callback(null);
        }
    })
    // executed on success or fail
    .always(() => {
        // remove current request from hash
        if (currentRequests[makeHash(options.url, options.data)]) {
            delete currentRequests[makeHash(options.url, options.data)];
        }
        if(!options['donthidepreloader']) {
            preloader.hide();
        }
    });

    currentRequests[makeHash(options.url, options.data)] = deffered;
    return deffered;
};
/**
 * @function
 * @description ajax request to load html response in a given container
 * @param {String} url URI for the request
 * @param {Object} data Name/Value pair data request
 * @param {Function} callback  Callback function to be called
 * @param {Object} target Selector or element that will receive content
 */
var load = function (options) {
    options.url = toAbsoluteUrl(options.url);
    // return if no url exists or url matches a current request
    if (!options.url) {
        return $.Deferred().reject();
    }
    if (currentRequests[makeHash(options.url, options.data)]) {
        return currentRequests[makeHash(options.url, options.data)];
    }

    // make the server call
    var deffered = $.ajax({
        type: options.type || 'get',
        url: appendParamToURL(options.url, 'format', 'ajax'),
        data: options.data,
        xhrFields: {
            withCredentials: true
        },
        statusCode: {
            401: (response) => {
                handleJsonRedirect(response.responseJSON);
            }
        }
    })
    .done((response) => {
        // success
        if (options.target) {
            $(options.target).empty().html(response);
        }
        if (options.callback) {
            options.callback(response);
        }
    })
    .fail((xhr, textStatus) => {
        // failed
        if (!xhr.readyState && xhr.statusText === 'error') {
            page.refresh();
            return;
        }
        if (textStatus === 'parsererror') {
            alarm(resources.BAD_RESPONSE);
        }
        if (options.callback) {
            options.callback(null, textStatus);
        }
    })
    .always(() => {
        // remove current request from hash
        if (currentRequests[makeHash(options.url, options.data)]) {
            delete currentRequests[makeHash(options.url, options.data)];
        }
        if(!options['donthidepreloader']) {
            preloader.hide();
        }
    });

    currentRequests[makeHash(options.url, options.data)] = deffered;
    return deffered;
};

module.exports = load;
module.exports.getJson = getJson;
module.exports.load = load;
