import Button from './Button';
import eventMgr from '../eventMgr';

const formSubmitter = eventMgr.getEmitter('FormSubmitter');

export default class FormSubmitter extends Button {
    onClick (el, event) {
        event.preventDefault();
        formSubmitter.emit('submit.' + this.config.formId, this.config.actionName);
    }
}
