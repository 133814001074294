// Placeholder for all scripts needed for Amplience
import Block from './Block';
import $ from 'jquery';
export default class Amplience extends Block {
    init() {
        this.currentViewInit = this.currentView();
        if ($('.no-result-wrapper').length !== 0) {
            this.noResultsInit();
        }

        if ($("#hero-video").length > 0) {
            this.loadHomeVideo();
            $(window).on('resize', this.onResize.bind(this));
        }

        this.toggleLiveChat();
    }

    /**
     * Open live chat widget
     */
    toggleLiveChat() {
        var el = document.getElementById('js_livechat_pdp');
        if (el) {
            el.addEventListener("click", function() {
                zE('webWidget', 'toggle');
            }, false);
        }
    }

    noResultsInit() {
        let wrapper = document.getElementsByClassName('no-result-wrapper')[0],
            div = document.createElement('div');
        div.id = 'no-result-content';

        wrapper.parentNode.insertBefore(div, wrapper);
        let content = document.getElementById('no-result-content');
        content.appendChild(wrapper);
    }

    onResize() {
        const currentView = this.currentView();
        if(this.currentViewInit != currentView) {
            this.currentViewInit = currentView;
            this.loadHomeVideo();
        }
    }
    
   loadHomeVideo() {
        let vdElement = $("#hero-video");
        
        if (this.currentView() === 'mobile') {
            let mobSrc = vdElement.attr('data-mobile-src');
            let mobPoster = vdElement.attr('data-mobile-poster');
    
            vdElement.attr("poster", mobPoster);
            vdElement.attr("src", mobSrc);
        } else {
            let desSrc = vdElement.attr('data-desktop-src');
            let desPoster = vdElement.attr('data-desktop-poster');
    
            vdElement.attr("poster", desPoster);
            vdElement.attr("src", desSrc);
        }
    
        var $breadcrumbColor = $('.plp-banner-wrapper').attr('data-breadcrumb-color');
            
        if ($breadcrumbColor) {
            $('.js-breadcrumbs-wrap').addClass($breadcrumbColor);
        }
    }

    currentView() {
        let screenCheck = $("#hero-video").attr('data-media');
        if (window.matchMedia('(max-width: '+ screenCheck +'px)').matches) {
            return 'mobile';
        } else if (window.matchMedia('(min-width: '+ screenCheck +'px)').matches) {
            return 'desktop';
        }
    }
}