import Component from '../Component';
import eventMgr from '../eventMgr';
import screendetector from 'screendetector';
import _debounce from 'lodash/debounce';
import $ from 'jquery';
import '../scroll';
import ajax from 'ajax';
import utils from 'utils';

const emitter = eventMgr.getEmitter('Header'),
    STICKY_HEADER_CLASS = 'header-sticky';

export default class Header extends Component {
    init() {
        super.init();
        this.scroll = $(window).scrollTop();
        this.lastScrollTop = 0;
        this.isSticky = false;
        this.deviceChanged = false;
        this.dialogShown = false;
        this.$stickyPart = this.$el.find('.js-sticky-part');
        this.isPLP = $('.plp.wrapper').length > 0;
        this.headerDesktopStart = 1000;
        this.changeDevice();
        this.setColorVariant();

        this.eventMgr('scroll.scroll', this.onScroll);

        /**
         * Debounce change device to wait all animation finish
         */
        this.debouncedChangeDevice = _debounce(() => {
            this.deviceChanged = true;
            this.changeDevice();
        }, 400);

        this.eventMgr('screenDetector.mobile', this.debouncedChangeDevice);
        this.eventMgr('screenDetector.tablet', this.debouncedChangeDevice);
        this.eventMgr('screenDetector.desktop', this.debouncedChangeDevice);
        this.eventMgr('dialog.close', this.onDialogClose);
        this.eventMgr('dialog.open', this.onDialogOpen);
        this.eventMgr('hamburgermenu.open', this.menuOpen);
        this.eventMgr('hamburgermenu.close', this.menuClose);
        this.eventMgr('autoScroll.scrolled', this.hideNavBarStandard);
        this.event('click', '.js-search-button', this.openSearchField);
        this.event('click', '.js-search-show-nav', this.showNavigation);
        this.event('click', '.js-open-login-modal', this.openLoginModal);

        $(window).on('resize', () => {
            this.setColorVariant();
        });

        var $headerWrapper = $('.header-wrapper');
        var $headerWrapperHeight = $headerWrapper.innerHeight();
        var $transparentBackgrdOptions = ['fullinverce', 'bgonlyverce'];
        var $headerColor = this.$el.attr('data-color-variant');
        
        if ( ($('.js-top-spacer').length > 0 && !($transparentBackgrdOptions.indexOf($headerColor) !== -1) ) ||  $('.search-template').length > 0) {
            this.addTopSpace($('.js-top-spacer'), $headerWrapperHeight);
        }

        if ($('.js-add-margin').length > 0) {
            this.addTopSpace($('.js-add-margin'), $headerWrapperHeight);
        }

        this.setBannerPosition();

        if($('.header-wrapper .header-promo').length == 0) {
            $('.plp .main').removeClass('has-preheader');
        }

        $('body').on('click', '.overlay-content', () => {
            if(!($('.overlay-content').hasClass('hidden'))){
                $('.overlay-content').addClass('hidden');
            };

            if($('.login-modal-content').hasClass('login-modal-active')) {
                $('.login-modal-content').removeClass('login-modal-active');
                $('.login-modal').hide();
            }
        });

        var $hamburgerMenuBgColor = $('.js-hamburger-bgcolor').val();

        if($hamburgerMenuBgColor) {
            $('.header-hamburger').css("background-color", $hamburgerMenuBgColor);
        }
    }

    addTopSpace(elem, $headerWrapperHeight) {
        elem.css('margin-top', $headerWrapperHeight);

        var windowWidth = $(window).width();
        var iphoneRes = this.iphoneBreakpointEnd;
        var $vHeight = '100%'; //for iphone

        if (windowWidth > iphoneRes) {
            $vHeight = '100vh'; // for tab and desktop
        } 

        if ($('.home-component .video-container').length > 0) { 
            $('.home-component video').css({ height: 'calc('+ $vHeight +' - ' + $headerWrapperHeight + 'px)' });
        }

        if ($('.js-img-animate').length > 0) {
            $('.js-img-animate img').css({ height: 'calc('+ $vHeight +' - ' + $headerWrapperHeight + 'px)' });
        }
    }

    setBannerPosition() {
        var $headerWrapper = $('.header-wrapper');
        var $headerWrapperHeight = $headerWrapper.innerHeight();
        var $transparentBackgrdOptions = ['fullinverce', 'bgonlyverce'];
        var $headerColor = $('.header.transparent').attr('data-color-variant');

        if($('.plp-banner-wrapper').length > 0 ) {
            var $breadcrumheight = $('.js-breadcrumbs-wrap').innerHeight();
            if ($('.plp-banner-wrapper').hasClass('no-banner')) {
                if(($transparentBackgrdOptions.indexOf($headerColor) !== -1)) {
                    var $topHeight = $headerWrapperHeight + $breadcrumheight;
                    $('.plp-banner-wrapper .plp-banner_content').css('padding-top', $topHeight);
                    $('.js-breadcrumbs-wrap').css('top', $headerWrapperHeight);
                } else {
                    $('.plp-banner-wrapper .plp-banner_content').css('padding-top', $breadcrumheight);
                }
            }
        }
    }

    openLoginModal(elem) {
        const loginModalUrl = $(elem).attr('href');
        const loginModal = $('.login-modal');
        this.loadLoginModal(loginModalUrl, loginModal)
    }

    loadLoginModal(loginModalUrl, loginModal) {
        return ajax.load({
            url: utils.appendParamToURL(loginModalUrl)
        }).then((data) => {
            this.$el = loginModal;
            this.$el.appendTo('body');
            this.$el.show();
            this.$el.html(data);
            setTimeout(() => {
                this.$el.find('.login-modal-content').css({'maxHeight': '100%'});
                this.$el.find('.login-modal-content').addClass('login-modal-active');
                $('body').css('overflow', 'hidden');
                $('.overlay-content').removeClass('hidden');
            }, 300);
                
            return data;
        });
    }

    initHeights() {
        this.headerHeight = this.$el.outerHeight(true);
        this.stickyHeight = this.$stickyPart.outerHeight();
        this.header = $('.header').outerHeight(true);

        if (this.header > 0) {
            this.togglePoint = this.header;
        } else {
            this.togglePoint = 1;
        }

        setTimeout(() => {
            this.$el.addClass('header-initialized');
            emitter.emit('headerHeight', this.headerHeight);
            emitter.emit('stickyHeight', this.stickyHeight);
        }, 0);
    }

    changeDevice() {
        this.initHeights();
        setTimeout(() => {
            this.checkStickyHeader();
        }, 0);
    }

    reinitHeight() {
        if (this.deviceChanged) {
            /**
             * If device was changed need reinit all height
             * when header will be expanded
             * delay added for waiting finish of all animation
             */
            setTimeout(() => {
                this.deviceChanged = false;
                this.initHeights();
            }, 400);
        }
    }

    checkStickyHeader() {
        if (!this.isPLP) {
            this.checkStickyHeaderStandard();
        } else {
            this.checkStickyHeaderPLP();
        }
    }

    checkStickyHeaderStandard() {
        if ($('.header-nav_list .hover').length) {
            return;
        }

        /**
         * Make sure they scroll more than delta
         */
        if (this.dialogShown && (Math.abs(this.lastScrollTop - this.scroll) <= this.togglePoint)) {
            this.checkScrolledDown(this.scroll > 0);
            return;
        }

        if (this.scroll > this.lastScrollTop && this.scroll > this.togglePoint) {
            /**
             * Scroll Down
             */
            this.hideNavBarStandard(true);
            this.reinitHeight();
        } else if (this.scroll + $(window).height() < $(document).height()) {
            /**
             * Scroll Up
             */
            this.hideNavBarStandard(false);
        }

        this.lastScrollTop = this.scroll;
    }

    isVisible() {
        return ($('.header-nav_flyout').is(':visible') && screendetector.getDeviceId() !== screendetector.MOBILE) ||
            $('.header-search_suggestions').is(':visible') ||
            $('.js-minicart-flyout').is(':visible');
    }

    checkScrolledDown(scrollCheck) {
        let $header = $('.header');

        if (scrollCheck) {
            $header.addClass('scrolled-down');
        } else {
            $header.removeClass('scrolled-down');
        }
    }

    checkStickyHeaderPLP() {
        /**
         * Do not hide header if its opened
         */
        if ($('.header-nav_list .hover').length) {
            return;
        }

        /**
         * Make sure they scroll more than delta
         */
        if (this.dialogShown && (Math.abs(this.lastScrollTop - this.scroll) <= this.togglePoint)) {
            this.checkScrolledDown(this.scroll > 0);

            return;
        }

        if ((this.scroll > this.lastScrollTop) && (this.scroll > this.togglePoint)) {
            /**
             * Scroll Down
             */
            this.hideNavBar(true);
            this.reinitHeight();
        } else {
            if ((this.scroll + $(window).height()) < $(document).height()) {
                /**
                 * Scroll Up
                 */
                this.hideNavBar(false);
            }
        }

        this.lastScrollTop = this.scroll;
    }

    hideNavBar(hide = true) {
        let $header = $('.header'),
            $filterBar = $('.search-result_options'),
            $headerWrapper = $('.header-wrapper');

        if (hide) {
            /**
             * Scroll Down
             */
            $header.removeClass('nav-down').addClass('nav-up');
            /**
             * Also add this to the filter-nav-bar
             */
            $filterBar.removeClass('nav-down').addClass('nav-up');

            /**
             * setting filter panel position
             */
            $('.search-result_options').css('top', 0);

            this.isSticky = false;
            emitter.emit('isSticky', false);
        } else {
            /**
             * Scroll Up
             */
            $header.removeClass('nav-up').addClass('nav-down');
            /**
             * also add this to the filter-nav-bar
             */
            $filterBar.removeClass('nav-up').addClass('nav-down');

            /**
             * setting filter panel position
             */
            var $headerWrapperHeight = $headerWrapper.innerHeight();

            $('.search-result_options').css('top', $headerWrapperHeight);

            this.isSticky = true;
            emitter.emit('isSticky', true);
        }

        // Transparent header to opque on scroll down and back to transparent when scrolled to top
        this.checkScrolledDown(this.scroll > this.togglePoint)
    }

    hideNavBarStandard(hide = true) {
        let $header = $('.header');

        if (hide) {
            // Scroll Down
            $header.removeClass('nav-down').addClass('nav-up');
            this.isSticky = false;
            emitter.emit('isSticky', false);
        } else {
            // Scroll Up
            $header.removeClass('nav-up').addClass('nav-down');
            this.isSticky = true;
            emitter.emit('isSticky', true);
        }

        // Transparent header to opque on scroll down and back to transparent when scrolled to top
        this.checkScrolledDown(this.scroll > this.togglePoint);
    }

    toggleSticky(enable = true) {
        if (enable) {
            if (!this.isSticky) {
                this.$el.addClass(STICKY_HEADER_CLASS);
                this.isSticky = true;
                emitter.emit('isSticky', true);
            }
        } else {
            if (this.isSticky) {
                this.$el.removeClass(STICKY_HEADER_CLASS);
                this.isSticky = false;
                emitter.emit('isSticky', false);
            }
        }
    }

    onScroll(data) {
        if (!$('.search-result_options').hasClass('opend')) {
            this.scroll = data.scrollTop;
            if (!this.dialogShown) {
                this.checkStickyHeader();
            }
        }
    }

    onDialogOpen() {
        if ($('.delivery-preferences:visible').length) {
            $('html,body').css({'overflow': 'hidden'});
        }
        this.dialogShown = true;
        if (screendetector.getDeviceId() === screendetector.MOBILE) {
            if (!this.isPLP) {
                this.toggleSticky(true);
            } else {
                this.$el.addClass(STICKY_HEADER_CLASS);
            }
        }
    }

    onDialogClose() {
        this.dialogShown = false;
        this.scroll = $(window).scrollTop();
        this.checkStickyHeader();
        this.$el.removeClass(STICKY_HEADER_CLASS);
        $('html,body').css({'overflow': 'initial'});
    }

    openSearchField() {
        $('.header-search_button').attr('disabled', 'disabled');
        $('.header-nav, .header-search').addClass('js-search-field-show');
        $('.header-search_reset').addClass('js-search-show-nav');
    }

    showNavigation() {
        $('.header-search_button').removeAttr('disabled');
        $('.header-nav, .header-search').removeClass('js-search-field-show');
        $('.header-search_reset').removeClass('js-search-show-nav');
    }

    setColorVariant() {
        var windowWidth = $(window).width();
        var tabletRes = this.headerDesktopStart;
        var currentHeaderStyle;

        switch (true) {
            case (windowWidth < tabletRes):
                currentHeaderStyle = this.$el.find('.mobile-variant').val();
                break;
            case (windowWidth >= tabletRes):
                currentHeaderStyle = this.$el.find('.desktop-variant').val();
                break;
            default:
                currentHeaderStyle = 'standard';
        }

        this.$el.attr('data-color-variant', currentHeaderStyle);
    }

    menuOpen() {
        this.$el.removeAttr('data-color-variant');
    }

    menuClose() {
        this.setColorVariant();
    }
}
