import Component from '../Component';
import scrollUtil from 'scrollUtil';
import $ from 'jquery';

export default class GoToBlock extends Component {
    init () {
        super.init();
        this.event('click', this.scrollToBlock);
    }
    scrollToBlock (el, e) {
        if (this.config.blockselector) {
            e.preventDefault();
            scrollUtil.scrollTo($(this.config.blockselector));
            // Move the focus on a new element when the page scroll to the blockselector 
            $(this.config.blockselector).next().find(':focusable').eq(0).focus();
        }
    }
}
