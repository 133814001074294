import Component from '../Component';
import eventMgr from '../eventMgr';
import $ from 'jquery';

const emitter = eventMgr.getEmitter('body'),
    OPENED = 'header-nav--active',
    OPENED_MINICART = 'header-minicart--active',
    ACTIVE_SEARCH = 'header-search--active',
    minTouchMove = 150,
    maxAttitude = 0.3;

export default class Body extends Component {
    init () {
        super.init();
        this.touchInit();
        this.crossTopBorderInit();

        this.eventMgr('hamburgermenu.open', this.hamburgermenuOpen);
        this.eventMgr('hamburgermenu.close', this.hamburgermenuClose);

        this.eventMgr('minicart.mobile.open', this.minicartOpen);
        this.eventMgr('minicart.mobile.close', this.minicartClose);

        this.eventMgr('SearchSuggestion.open', this.searchsuggestionOpen);
        this.eventMgr('SearchSuggestion.close', this.searchsuggestionClose);
    }
    touchInit () {
        this.startOffsetX = 0;
        this.startOffsetY = 0;
        this.currentOffsetX = 0;
        this.currentOffsetY = 0;
        this.event('touchstart', this.touchStart);
        this.event('touchend', this.touchEnd);
        this.event('touchmove', this.touchMove);
    }
    touchStart (el, e) {
        if (typeof e.originalEvent.touches === 'undefined') { return; }
        this.startOffsetX = e.originalEvent.touches[0].screenX;
        this.startOffsetY = e.originalEvent.touches[0].screenY;
    }
    touchEnd (el, e) {
        // Prevent mobile hamburger menu to open on carousel swipe
        if ($(e.target).closest('#amp-pdp-container')[0] || $(e.target).closest('.refinements-mobile')[0]) {
            return;
        }

        var attitude = (this.currentOffsetX !== 0) ? Math.abs(this.currentOffsetY) / Math.abs(this.currentOffsetX) : 1;

        if (Math.abs(this.currentOffsetX) > minTouchMove && attitude < maxAttitude) {
            if (this.currentOffsetX < 0) {
                emitter.emit('touchend', {
                    direction: 'left',
                    length: Math.abs(this.currentOffsetX)
                });
            } else {
                emitter.emit('touchend', {
                    direction: 'right',
                    length: Math.abs(this.currentOffsetX)
                });
            }
        }
        this.currentOffsetX = 0;
        this.currentOffsetY = 0;
    }
    touchMove (el, e) {
        if (typeof e.originalEvent.touches === 'undefined') { return; }
        this.currentOffsetX = e.originalEvent.touches[0].screenX - this.startOffsetX;
        this.currentOffsetY = e.originalEvent.touches[0].screenY - this.startOffsetY;
    }
    hamburgermenuOpen () {
        this.$el.addClass(OPENED);
    }
    hamburgermenuClose () {
        this.$el.removeClass(OPENED);
    }
    minicartOpen () {
        this.$el.addClass(OPENED_MINICART);
    }
    minicartClose () {
        this.$el.removeClass(OPENED_MINICART);
    }
    searchsuggestionOpen () {
        this.$el.addClass(ACTIVE_SEARCH);
    }
    searchsuggestionClose () {
        this.$el.removeClass(ACTIVE_SEARCH);
    }
    crossTopBorderInit () {
        $(document).on('mouseleave', function(e) {
            if( e.clientY <= 0 ) {
                emitter.emit('crosstopborder', {});
            }
        });
    }
}
