import FormGeneric from './FormGeneric';
import dialog from 'dialog';

export default class FormDialog extends FormGeneric {
    init () {
        super.init();
        this.event('click', '.js-close', this.onCancel);
        this.event('focusin', '.js-input_field', this.upResetPasswordButton);
        this.event('focusout', '.js-input_field', this.downResetPasswordButton);
    }
    onSubmit () {
        this.isChildsValid(() => {
            dialog.submit(this.$el.find('[type="submit"]').attr('name'));
        });
        return false;
    }
    onCancel () {
        dialog.close();
        return false;
    }

    toggleResetPasswordButton() {
        if (this.$el.has('#PasswordResetForm')) {
            $('.form-row_button').addClass('keyboard-open');
        }
    }

    upResetPasswordButton() {
        if (this.$el.has('#PasswordResetForm')) {
            $('.form-row_button').addClass('keyboard-open');
        }
    }

    downResetPasswordButton() {
        var timeNeededToPressSubmit = 500;
        if (this.$el.has('#PasswordResetForm')) {
            setTimeout(() => {
                $('.form-row_button').removeClass('keyboard-open');
            }, timeNeededToPressSubmit);
        }
    }
}
