import Component from '../Component';
import screendetector from 'screendetector';
import verge from 'verge';
import debounce from 'lodash/debounce';
import eventMgr from 'eventMgr';
import $ from 'jquery';

const emitter = eventMgr.getEmitter('DynamicImage');

$(window).on('resize', () => {
    emitter.emit('resize');
})

export default class DynamicImage extends Component {
    init () {
        this.eventMgr('screenDetector.mobile', this.setImage);
        this.eventMgr('screenDetector.tablet', this.setImage);
        this.eventMgr('screenDetector.desktop', this.setImage);

        this.debouncedOnScroll = debounce(() => {
            this.onScroll();
        }, 50);

        this.onScroll();

        this.eventMgr('scroll.scroll', this.debouncedOnScroll);
        this.eventMgr('DynamicImage.resize', this.debouncedOnScroll);
    }
    onScroll () {
        if (this.config.skiplazyload) {
            this.setImage();
            return;
        }
        var isInViewPort = verge.inY(this.$el.parent()[0], 200);

        if (isInViewPort) {
            this.setImage();
        }
    }
    setImage (screenType) {
        if (!screenType) {
            screenType = screendetector.getDeviceId();
        }
        /*
         * Uncomment for retina support
        if (window.devicePixelRatio > 1) {
            screenType = screenType + 'Retina';
        }
        */
        if (this.currentDevice === screenType) {
            return;
        }
        this.currentDevice = screenType;
        var imgSrc = this.config[screenType + 'Src'] || this.config.defaultSrc;

        if (!this.$img) {
            this.$img = $('<img/>').attr({
                'alt': this.config.alt,
                'title': this.config.title,
                'class': this.config.class
            });
            this.$el.after(this.$img);
        }
        this.$img.attr('src', imgSrc);
    }
}
