import FormGeneric from './FormGeneric';
import eventMgr from '../eventMgr';
import pageUtils from 'pageUtils';
import preloader from 'preloader';
import $ from 'jquery';
import dialog from 'dialog';
import 'service';

export default class ServerValidationForm extends FormGeneric {
    init () {
        super.init();
        this.event('click', '.js-cancel', this.onCancel);
        this.event('click', '[type="submit"]', this.setAction);
    }
    setAction (el) {
        this.actionName = $(el).attr('name');
        setTimeout(() => {
            // Clear action to prevent submit with wrong action
            this.actionName = null;
        }, 100);
    }
    successAction (response) {
        if (response.redirectTo) {
            preloader.show();
            pageUtils.redirect(response.redirectTo);
        }
    }
    highlightErrors (errors) {
        errors = errors || {};
        this.eachChild((cmp) => {
            var name = cmp.getName && cmp.getName();

            if (name && errors[name]) {
                cmp.setFocus();
                cmp.setError(errors[name]);
            } else if (cmp.id && errors[cmp.id]) {
                cmp.setFocus();
                cmp.setError(errors[cmp.id]);
            }
        });
    }
    onSubmit () {
        if (this.config.skipAjax) {
            return super.onSubmit();
        }
        if (!this.actionName) {
            return;
        }

        this.isChildsValid(() => {
            preloader.show(this.$el);
            var data = this.getFormFields() || {};

            data[this.actionName] = 'true';

            eventMgr.execute('service.form.submit', {
                action: this.$el.attr('action'),
                data: data
            }).then((response) => {
                if (response.success) {
                    this.successAction(response);
                } else {
                    this.failAction(response);
                }
                if (!response.success && response.errors) {
                    this.highlightErrors(response.errors);
                }
            });
        });
        return false;
    }
    onCancel (el, ev) {
        ev.preventDefault();
        dialog.close();
    }
    failAction () {}
}
