'use strict';

import Component from '../Component';
import eventMgr from '../eventMgr';
import 'product';
import $ from 'jquery';
import dialog from 'dialog';
import debounce from 'lodash/debounce';
import 'googleMap';
/* globals google */
export default class DeliveryOptionPopup extends Component {
    init() {
        this.event('click', '.js-close-deliveryOption', this.onClose);
        this.event('click', '.js-change-address-deliveryOption', this.showSearchForm)
        this.event('click', '#changeStore', this.changeStore);
        this.debounceMapInit = debounce(this.mapInit, 100);
        this.loadStoreMap();
    }

    showSearchForm() {
        $('.js-postal-code-subsection').addClass('hidden');
        $('.js-delivery-options-popup').removeClass('hidden');
    }

    changeStore(el, event) {
        var data = {
            storeNumber: this.$el.find('#changeStore').attr('storenumber'),
            url: this.$el.find('#changeStore').attr('url')
        };

        eventMgr.execute('product.next_store.get', data).then((response) => {
            $('#clickAndCollect-main').html(response);
            this.loadStoreMap();
        });
        return false;
    }

    onClose() {
        dialog.close();
    }

    loadStoreMap() {
        if (this.$el.find('.js-store-details:visible').length > 0) {
            var $item = this.$el.find('.store-map-popup');

            eventMgr.execute('googleMap.init', $item.data('key')).done(() => {
                this.debounceMapInit($item.find('.js_map').get(0), $item.data('lat'), $item.data('lng'), google.maps);
            });
        }
    }

    mapInit(el, lat, lng, mapObject) {
        var storeLocation = new mapObject.LatLng(lat, lng),
            map, marker;

        map = new mapObject.Map(el, {
            zoom: 15,
            center: storeLocation
        });

        marker = new mapObject.Marker({
            position: storeLocation
        });

        marker.setMap(map);
    }
}