import Block from '../common/Block';
import $ from 'jquery';
import eventMgr from 'eventMgr';
import 'grid';

export default class Breadcrumbs extends Block {
    init () {
        super.init();
        this.eventMgr('grid.updated', this.onUpdate);
    }

    onUpdate (data) {
        var $html = $('<div>' + data + '</div>'),
            $breadcrumbs = $html.find('.js-breadcrumbs-wrap');

        if ($breadcrumbs.length) {
            $('.js-breadcrumbs-wrap').replaceWith($breadcrumbs);
        }
    }
}
