import Block from '../common/Block';
import eventMgr from 'eventMgr';
import scrollUtil from 'scrollUtil';
import screendetector from 'screendetector';
import Promise from 'promise';

export default class ClickAndCollectItem extends Block {
    init () {
        super.init();
        this.storesLoaded = false;

        this.moveDefaultCountry();

        //this.getById(this.config.countryField, this.onChangeCountry);

        //this.onChild(this.config.countryField, 'change', this.onChangeCountry);

        this.event('change', '.js-ccstore', this.onChangeStoreId);
        this.event('click', '.changestore', this.onStoresBack);
        this.event('click', '.js-search-stores', this.onSearchByPostalCode);
        this.event('click', '.js-search-by-location', this.onSearchByLocation);
        this.event('click', '.js-change-location', this.onChangeLocation);
        this.event('click', '.js-showall-stores', this.onClickShowAllShops);

        eventMgr.on('checkout.stores.updated', this.onStoresUpdate.bind(this));
    }
    getStores () {
        eventMgr.execute('checkout.stores.get', {
            findbycountry: true,
            country: this.country,
            postal: this.postalCode
        });
    }
    onChangeLocation () {
        this.$el.find('.js-search-container').removeClass('hidden');
        this.$el.find('.js-change-location-container').addClass('hidden');
        $('.checkout-shipping_submit').addClass('hidden');
        this.callFnForId('storesList', 'setHtml', '');
        this.$el.find('.click_and_collect--block').removeClass('active');
        this.$el.find('select[id$=_clickcountry]').focus();
    }
    onStoresBack () {
        this.$el.find('.js-ccstore:checked').removeAttr('checked');
        $('.checkout-shipping_submit').addClass('hidden');
        this.selectedStoreId = null;
        this.emitter.emit('store.change', this.selectedStoreId);
        this.toggleStores();
    }
    onStoresUpdate (data) {
        this.callFnForId('storesList', 'setHtml', data);
        this.getSelectedStore();

        this.$el.find('.click_and_collect--block').addClass('active');

        var postalCode = this.$el.find('#' + this.config.postalField).val()
        $('.js-postal-location').html(postalCode)

        if (!this.storesLoaded && this.$el.find('.js-change-location-container').length === 0) {
            this.toggleStores();
        }

        this.storesLoaded = true;

        /* eslint-disable no-unused-expressions */
        if (this.selectedStoreId) {
            this.resolveFn && this.resolveFn();
        } else {
            this.rejectFn && this.rejectFn();
        }
        /* eslint-enable */
        this.resolveFn = null;
        this.rejectFn = null;
        this.$el.find('.js-search-container').addClass('hidden');
        this.$el.find('.js-change-location-container').removeClass('hidden');
    }
    getSelectedStore () {
        var selectedStore = this.$el.find('.js-ccstore:checked');

        this.selectedStoreId = selectedStore.length ? selectedStore.val() : null;
    }
    onSearchByPostalCode() {
        this.country = this.$el.find('#' + this.config.countryField).val();
        this.postalCode = this.$el.find('#' + this.config.postalField).val();
        if (this.postalCode) {
            this.$el.find('.postalCode-error').addClass('hidden');
            this.getStores();
            this.selectedStoreId = null;
            this.emitter.emit('store.change', this.selectedStoreId);
            this.toggleStores();
        } else {
            this.$el.find('.postalCode-error').removeClass('hidden');
        }
    }
    onSearchByLocation() {
        eventMgr.execute('checkout.stores.get', {
            findbylocation: true
        });
        this.selectedStoreId = null;
        this.emitter.emit('store.change', this.selectedStoreId);
        this.toggleStores();
    }
    onChangeCountry (cmp) {
        this.country = cmp.getValue();
        this.getStores();
        this.selectedStoreId = null;
        this.emitter.emit('store.change', this.selectedStoreId);
        this.toggleStores();
    }
    onChangeStoreId (el) {
        if (el.value) {
            this.selectedStoreId = el.value;
            this.emitter.emit('store.change', this.selectedStoreId);
            eventMgr.execute('checkout.stores.set.id', {
                storeId: this.selectedStoreId,
                countryCode: this.country
            });
            this.toggleStores();
        }
    }
    getSelectedStoreId () {
        return this.selectedStoreId;
    }
    checkSelectedStoreId () {
        return new Promise((resolve, reject) => {
            if (this.selectedStoreId) {
                resolve();
            } else if (!this.storesLoaded) {
                this.resolveFn = resolve;
                this.rejectFn = reject;
            } else {
                reject();
            }
        });
    }
    toggleStores () {
        if (this.selectedStoreId) {
            this.$el.find('.js-ccstore-variant:not([data-id=' + this.selectedStoreId + '])').addClass('hidden');
            this.$el.find('.click_and_collect--block-title').html(this.$el.find('.your-selected-shop')[0].value);
            if (this.$el.find('.js-change-location-container').length === 0) {
                this.callFnForId('showAllShops', 'show');
            } else {
                this.callFnForId('showAllShops', 'hide');
            }
            if (screendetector.getDeviceId() === screendetector.MOBILE ||
                screendetector.getDeviceId() === screendetector.TABLET) {
                scrollUtil.scrollTo(this.$el);
            }
        } else {
            this.callFnForId('showAllShops', 'hide');
            this.$el.find('.js-ccstore-variant:not([data-id=' + this.selectedStoreId + '])').removeClass('hidden');
            this.$el.find('.click_and_collect--block-title').html(this.$el.find('.choose-a-shop')[0].value);
        }
    }
    onClickShowAllShops () {
        this.callFnForId('showAllShops', 'hide');
        this.$el.find('.js-ccstore-variant').removeClass('hidden');
        this.$el.find('.js-ccstore').prop('checked',false);
    }
    moveDefaultCountry () {
        this.$el.find('select[id$=_clickcountry] option:selected').insertBefore($('select[id$=_clickcountry] option:first-child'));
    }
}
