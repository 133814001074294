import InputGeneric from './InputGeneric';

export default class InputSelect extends InputGeneric {
    setValue (newVal, silently) {
        if (newVal === undefined) {
            newVal = this.initValue;
        }
        super.setValue(newVal, silently);
    }
    cleanOptions () {
        this.$field.find('option').remove();
    }
    setOptions (options) {
        this.cleanOptions();
        this.$field.append(options.map((option) =>
            `<option label="${option.label}" value="${option.value}"> ${option.label}</option>`
        ).join(''));
    }
    getSelectedOption () {
        return this.$field.find('option:checked');
    }
    getSelectedOptionConfig () {
        return this.$field.find('option:checked').data();
    }
    getOptionsCount () {
        return this.$field.find('option').length;
    }
}
