import Form from '../forms/Form';
import $ from 'jquery';
import dialog from 'dialog';
import urls from 'urls';
import resources from 'resources';
import preloader from 'preloader';
import prefs from 'prefs';
import Cookies from 'js-cookie';
import 'jquery-iframe-ajax/dist/jquery-iframe-ajax';

export default class NewsletterForm extends Form {
    init () {
        super.init();
        var form = this.$el;

        if (prefs.RECAPTCHA_ENABLE && window.grecaptcha) {
            grecaptcha.ready(function() {
                grecaptcha.execute(prefs.RECAPTCHA_SITE_KEY).then(function(token) {
                    $('<input>').attr({
                        type: 'hidden',
                        name: 'g-recaptcha-response',
                        class: 'js-newsletter-captcha',
                        value: token
                    }).appendTo(form);
                });
            });
        }
    }

    onSubmit () {
        if (super.onSubmit()) {
            var form = this.$el;

            if (prefs.RECAPTCHA_ENABLE) {
                checkCaptcha(form);
            }
            else {
                completeSubscription(form);
            }
        }
        return false;
    }
}

function checkCaptcha (form) {
    var captchaResponse = form.find('.js-newsletter-captcha').val() || null;

    $.ajax({
        url: urls.checkCaptcha,
        method: 'POST',
        data: {
            'g-recaptcha-response': captchaResponse
        }
    }).done((response) => {
        if (response.success) {
            completeSubscription(form);
        }
        else {
            form.find('.js-captcha-error').removeClass('hidden');
        }
    });
}

function completeSubscription (form) {
    $.ajax({
        url: form.attr('action'),
        method: 'POST',
        form: form,
        iframe: true
    });

    dialog.open({
        url: urls.successNewsletter,
        options: {
            dialogClass: 'ui-dialog_small',
            buttons: [{
                text: resources.OK,
                'class': 'button button-regular',
                click: () => {
                    dialog.close();
                }
            }]
        }
    });

    Cookies.set('onleave_signup_prompted', true, {
        expires: 365,
        path: '/'
    });
}
