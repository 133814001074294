import InputGeneric from './InputGeneric';
import eventMgr from 'eventMgr';
import $ from 'jquery';
import pageUtils from 'pageUtils';
import util from 'utils';

const emitter = eventMgr.getEmitter('InputSearch');

export default class InputSearch extends InputGeneric {

    init () {
        super.init();
        this.listTotal = -1;
        this.listCurrent = -1;
        this.lastValue = '';
        this.$searchField = this.$el.find('.js-search-input');
        this.$searchForm = this.$el.is('form') ? this.$el : this.$el.find('.js-search-form');
        this.placeholder = this.$el.attr('placeholder');

        this.event('submit', this.onSubmit, this.$searchForm);
        this.event('keyup', '.js-search-input', this.onEmit);
        this.event('focusout', '.js-search-input', this.onFocusOut);
        this.event('focus', '.js-search-input', this.onFocus);
        this.event('click', '.js-search-reset', this.onReset);

        this.eventMgr('hamburgermenu.close', this.onReset);
    }

    onSubmit (element, e) {
        e.preventDefault();
        const searchTerm = this.$searchField.val();

        if (searchTerm === this.placeholder || !searchTerm) {
            return false;
        }

        pageUtils.redirect(util.appendParamToURL($(element).attr('action'), 'q', searchTerm));
    }

    onFocusOut () {
        emitter.emit('focusOut');
    }

    onFocus () {
        $('.header-search_submit').addClass('js-search-focus');
        $('.header-search_reset').addClass('js-search-focus');
    }

    onReset () {
        $('.js-search-input').val('');
        $('.header-search_submit').removeClass('js-search-focus');
        $('.header-search_reset').removeClass('js-search-focus');
        emitter.emit('onReset');
    }

    onEmit (el, event) {
        if (this.handleArrowKeys(event.keyCode)) {
            return;
        }

        // check for an ENTER or ESC
        if (event.keyCode === 13 || event.keyCode === 27) {
            emitter.emit('controlClick');
            return;
        }

        var query = $(el).val();

        // exit if same query
        if (query && this.lastValue === query) {
            return;
        }
        this.lastValue = query;

        if (!this.config.notEmit) {
            emitter.emit('changeField', query);
        }
    }

    onInputFocus () {
        if (this.$searchField.val() === this.placeholder) {
            this.$searchField.val('');
        }
    }

    handleArrowKeys (keyCode) {
        switch (keyCode) {
            case 38:
                break;
            case 40:
                break;
            default:
                return false;
        }
        return true;
    }
}
