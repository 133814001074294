import ServerValidationForm from '../forms/ServerValidationForm';
import dialog from 'dialog';
import urls from 'urls';
import resources from 'resources';
import prefs from 'prefs';

export default class LoginForm extends ServerValidationForm {
    failAction(response) {
        if (response.emailSent) {
            dialog.open({
                url: urls.passwordChangeMsg,
                options: {
                    dialogClass: 'ui-dialog_small',
                    buttons: [{
                        text: resources.OK,
                        'class': 'button button-regular',
                        click: () => {
                            dialog.close();
                        }
                    }]
                }
            });
        }
    }
}
