'use strict';

import $ from 'jquery';
import Form from '../forms/Form';
import eventMgr from '../eventMgr';
import dialog from 'dialog';
import 'product';
import screendetector from 'screendetector';
import scrollUtil from 'scrollUtil';

export default class FindDeliveryOptionSubmit extends Form {
    callBack (response) {
        if (response.error) {
            $('.postalCode-error').text(response.errorMessage);
            $('#postalCode').addClass('state-error');
        } else {
            var options = {
                modal: true,
                dialogClass: 'ui-dialog_zoom deliveryOptions'
            };
    
            dialog.open({
                html: response,
                options: options
            });
        }
    }
    onSubmit (el, ev) {
        ev.preventDefault();
        $('.postalCode-error').text('');
        $('#postalCode').removeClass('state-error');
        if ($('.product-swatches.size').find('.product-item ').hasClass('selected')) {
            var data = {
                value: this.$el.find('#postalCode').val(),
                url: this.$el.attr('action')
            };

            eventMgr.execute('product.finddeliveryoption.submit', data).then(this.callBack);
        } else {
            var $sizeSelector = $('.product-swatches.size').closest('.form-row_select').find('.product-variations_label');

            $sizeSelector.attr('style','border-color:#ff0032').find('.product-variations_size').addClass('product-variations_disabled');
            $('.size-error').addClass('show-error');
            if (screendetector.getDeviceId() === screendetector.MOBILE) {
                setTimeout(() => {
                    scrollUtil.scrollTo($sizeSelector);
                }, 2000);
            }
        }

        return false;
    }
}