import Block from '../common/Block';
import eventMgr from 'eventMgr';
import $ from 'jquery';
import 'product';

export default class QuickView extends Block {
    init () {
        super.init();
        this.lastUrl = null;

        this.event('click', '.js-product-nav a', this.ajaxReload);

        this.eventMgr('product.quickview.updated', this.update);
    }

    ajaxReload (el, event) {
        event.preventDefault();
        this.lastUrl = $(el).attr('href');
        if (this.lastUrl) {
            eventMgr.execute('product.quickview.get', {
                url: this.lastUrl
            });
        }
    }
    update (html) {
        this.setHtml(html);
        if (this.lastUrl) {
            var hash = this.lastUrl.split('#');

            if (hash[1]) {
                window.pageHash = hash[1];
            }
        }
    }
}
