import RegistryMgr from './RegistryMgr';

var components = {
    Component: require('./Component'),
    // Forms components
    form: require('./forms/Form'),
    visibleForm: require('./forms/VisibleForm'),
    label: require('./forms/Label'),
    button: require('./forms/Button'),
    inputText: require('./forms/InputText'),
    inputTel: require('./forms/InputTel'),
    inputHidden: require('./forms/InputHidden'),
    inputSelect: require('./forms/InputSelect'),
    inputCountrySelect: require('./forms/InputCountrySelect'),
    inputCustomSelect: require('./forms/InputCustomSelect'),
    inputPassword: require('./forms/InputPassword'),
    inputRadio: require('./forms/InputRadio'),
    inputCheckbox: require('./forms/InputCheckbox'),
    inputTextarea: require('./forms/InputTextarea'),
    formDialog: require('./forms/FormDialog'),
    inputSearch: require('./forms/InputSearch'),
    inputDate: require('./forms/InputDate'),
    inputEmail: require('./forms/InputEmail'),
    inputMoreLess: require('./forms/InputMoreLess'),
    inputRadioField: require('./forms/InputRadioField'),
    inputNumber: require('./forms/InputNumber'),
    formSubmitter: require('./forms/FormSubmitter'),
    serverValidationForm: require('./forms/ServerValidationForm'),
    dateSelector: require('./forms/DateSelector'),
    // End Forms components
    // Newsletter components
    newsletterSubscribe: require('./newsletter/NewsletterSubscribe'),
    newsletterSubmit: require('./newsletter/NewsletterSubmit'),
    newsletterForm: require('./newsletter/NewsletterForm'),
    // End Newsletter components
    carousel: require('./common/Carousel'),
    filterMobileCarousel: require('./common/FilterMobileCarousel'),
    viewerKit: require('./common/ViewerKit'),
    link: require('./common/Link'),
    block: require('./common/Block'),
    ajaxBlock: require('./common/AjaxBlock'),
    buttonLink: require('./common/ButtonLink'),
    menuLink: require('./common/MenuLink'),
    dialogLink: require('./common/DialogLink'),
    searchSuggestion: require('./common/SearchSuggestion'),
    backToTop: require('./common/BackToTop'),
    header: require('./common/Header'),
    footer: require('./common/Footer'),
    footerSticky: require('./common/FooterSticky'),
    stickySidebarSummary: require('./common/stickySidebarSummary'),
    dropdown: require('./common/Dropdown'),
    dropdownWithCarousel: require('./common/DropdownWithCarousel'),
    body: require('./common/Body'),
    html: require('./common/Html'),
    globalWrapper: require('./common/GlobalWrapper'),
    main: require('./common/Main'),
    cookiesCheck: require('./common/CookiesCheck'),
    hamburgerMenu: require('./common/HamburgerMenu'),
    headerMenu: require('./common/HeaderMenu'),
    readMore: require('./common/ReadMore'),
    printLink: require('./common/PrintLink'),
    livechat: require('./livechat/LiveChat'),
    productQuantity: require('./product/ProductQuantity'),
    productNav: require('./product/ProductNav'),
    addToCartForm: require('./product/AddToCartForm'),
    quickView: require('./product/QuickView'),
    productContent: require('./product/ProductContent'),
    productVariation: require('./product/ProductVariation'),
    dynamicImage: require('./image/DynamicImage'),
    refinements: require('./search/Refinements'),
    breadcrumbs: require('./search/Breadcrumbs'),
    grid: require('./search/Grid'),
    productGrid: require('./search/ProductGrid'),
    sortby: require('./search/Sortby'),
    pagingbar: require('./search/Pagingbar'),
    infinityScroll: require('./common/InfinityScroll'),
    loadmoreBtn: require('./search/LoadmoreBtn'),
    goToBlock: require('./common/GoToBlock'),
    preloaderBlock: require('./search/PreloaderBlock'),
    addressForm: require('./checkout/AddressForm'),
    homeDelivery: require('./checkout/HomeDelivery'),
    inputPostal: require('./checkout/InputPostal'),
    inputCardNumber: require('./checkout/InputCardNumber'),
    clickAndCollect: require('./checkout/ClickAndCollect'),
    creditCardForm: require('./checkout/CreditCardForm'),
    shippingForm: require('./checkout/ShippingForm'),
    paymentForm: require('./checkout/PaymentForm'),
    detectCardType: require('./checkout/DetectCardType'),
    checkoutLoginForm: require('./checkout/CheckoutLoginForm'),
    checkoutSummaryLoginForm: require('./checkout/CheckoutSummaryLoginForm'),
    checkoutSummaryRegisterForm: require('./checkout/CheckoutSummaryRegisterForm'),
    summaryTotals: require('./checkout/SummaryTotals'),
    billingForm: require('./checkout/BillingForm'),
    alternativePhone: require('./checkout/AlternativePhone'),
    summarySitesDropdown: require('./checkout/SummarySitesDropdown'),
    cartForm: require('./cart/CartForm'),
    minicart: require('./cart/Minicart'),
    lineItemCount: require('./cart/LineItemCount'),
    promocode: require('./cart/Promocode'),
    maps: require('./storelocator/Maps'),
    storesBlock: require('./storelocator/StoresBlock'),
    scrollTo: require('./common/ScrollTo'),
    scrollToBlock: require('./common/ScrollToBlock'),
    addToWishlist: require('./product/AddToWishlist'),
    confirmButton: require('./forms/ConfirmButton'),
    toggleClass: require('./common/ToggleClass'),
    cookiesPolicy: require('./common/CookiesPolicy'),
    autoScroll: require('./common/AutoScroll'),
    savedCardForm: require('./account/SavedCardForm'),
    formOAuthLogin: require('./account/FormOAuthLogin'),
    loginForm: require('./account/LoginForm'),
    registrationForm: require('./account/RegistrationForm'),
    sizeGuide: require('./product/SizeGuide'),
    headerBanner: require('./common/HeaderBanner'),
    shippingCountrySelector: require('./countryselector/ShippingCountrySelector'),
    yotpoReviews: require('./yotpo/YotpoReviews'),
    yotpoPagination: require('./yotpo/YotpoPagination'),
    productTile: require('./search/ProductTile'),
    productRecommendation: require('./product/ProductRecommendation'),
    detailView: require('./search/DetailView'),
    findDeliveryOptionSubmit: require('./finddeliveryOption/FindDeliveryOptionSubmit'),
    deliveryOptionPopup: require('./product/DeliveryOptionPopup'),
    magicZoom: require('./product/MagicZoomHelper'),
    amplience: require('./common/Amplience'),
    greenSock: require('./common/Greensock'),
    quickBuy: require('./product/QuickBuy'),
    lazyLoad: require('./common/LazyLoad'),
    customVideoControls: require('./common/CustomVideoControls'),
    customPDPVideoControls: require('./common/CustomPDPVideoControls'),
    viewmode: require('./search/Viewmode'),
    welcomeBackWidget: require('./common/WelcomeBackWidget'),
    welcomeBackCookie: require('./common/WelcomeBackCookie')
};

RegistryMgr.register(components);
