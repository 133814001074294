import Block from '../common/Block';

const HIDDEN = 'hidden',
    ACTIVE = 'active';

export default class SizeGuide extends Block {
    init () {
        super.init();

        this.event('click', '.js-show_cm_value', this.showCmValue);
        this.event('click', '.js-show_inches_value', this.showInchesValue);
    }
    showCmValue () {
        this.$el.find('.js-show_cm_value').addClass(ACTIVE);
        this.$el.find('.js-show_inches_value').removeClass(ACTIVE);
        this.$el.find('.cm').removeClass(HIDDEN);
        this.$el.find('.inches').addClass(HIDDEN);
    }
    showInchesValue () {
        this.$el.find('.js-show_inches_value').addClass(ACTIVE);
        this.$el.find('.js-show_cm_value').removeClass(ACTIVE);
        this.$el.find('.inches').removeClass(HIDDEN);
        this.$el.find('.cm').addClass(HIDDEN);
    }
}
