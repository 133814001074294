import FormGeneric from './FormGeneric';


export default class VisibleForm extends FormGeneric {
    isVisible () {
        return this.$el.is(':visible');
    }
    getFormFields () {
        if (this.isVisible()) {
            return super.getFormFields();
        }
        return {};
    }
    validate () {
        if (this.isVisible()) {
            return super.validate();
        }
        return true;
    }
    defaultValidate () {
        return super.validate();
    }
}
