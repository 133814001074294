import InputRadio from './InputRadio';

export default class InputRadioField extends InputRadio {
    init () {
        this.event('change', this.update);
        this.event('paste', () => {
            setTimeout(this.update.bind(this), 10);
        });
        this.$field = this.$el;
        this.initValue = this.$field.val();
        if (!this.id && this.$field.first().attr('name')) {
            this.id = this.$field.first().attr('name');
        }
        this.shown = true;
        this.disabled = false;
    }
}
