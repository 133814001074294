import ServerValidationForm from '../forms/ServerValidationForm';
import dialog from 'dialog';
import urls from 'urls';
import resources from 'resources';
import prefs from 'prefs';
import preloader from 'preloader';

// Add new credit card in my account payment section / start
function zeroAuth(data, checkout) {
    $.ajax({
        url: window.zeroAuthURL,
        type: 'POST',
        contentType: 'application/; charset=utf-8',
        data: JSON.stringify(data),
        async: false,
        success: function (data) {
            if (data.zeroAuthResult.action) {
                document.querySelector('#buttonsContainer').style.display = 'none';
                checkout.createFromAction(data.zeroAuthResult.action).mount('#newCard');
            }
            if (data.zeroAuthResult.resultCode === 'Authorised') {
                window.location.href = window.paymentInstrumentsList;
            } else if (data.zeroAuthResult.resultCode === 'Refused') {
                window.location.href = window.paymentInstrumentsListError;
            }
        },
    });
}

function paymentsDetails(state) {
    $.ajax({
        type: 'post',
        url: window.paymentsDetails,
        data: JSON.stringify({
            data: state.data
        }),
        contentType: 'application/; charset=utf-8',
        async: false,
        success(data) {
            if (data.response.isSuccessful) {
                window.location.href = window.paymentInstrumentsList;
            } else if (!data.response.isFinal && typeof data.response.action === 'object') {
                checkout.createFromAction(data.action).mount('#action-container');
            } else {
                window.location.href = window.paymentInstrumentsListError;
            }
        },
    });
}

async function initializeAccountEvents() {
    var checkoutConfiguration = window.Configuration;
    checkoutConfiguration.onAdditionalDetails = function(state) {
        paymentsDetails(state);
    };
    checkoutConfiguration.session = window.sessionData;
    var checkout = await AdyenCheckout(checkoutConfiguration);
    var newCard = document.getElementById('newCard');
    var adyenStateData;
    var isValid = false;
    var node = checkout
        .create('card', {
            hasHolderName: true,
            holderNameRequired: true,
            onChange: function (state) {
                adyenStateData = state.data;
                isValid = state.isValid;
            },
            onLoad: function () {
                window.addCVNTooltip();
            }
        })
        .mount(newCard);

    $('#applyBtn').on('click', function (e) {
        e.preventDefault();
        if (!isValid) {
            node.showValidation();
            return false;
        }
        document.querySelector('#adyenStateData').value = JSON.stringify(
            adyenStateData,
        );
        zeroAuth(adyenStateData, checkout);
    });
}
// Add new credit card in my account payment section / end

export default class CardForm extends ServerValidationForm {
    init () {
        super.init();
        this.config.skipAjax = false;

        this.event('click', '#add-card-submit', this.onButtonClick);

        initializeAccountEvents(); // new Adyen version required events for account page
    }

    clearCardData () {
        $('#dwfrm_billing_paymentMethods_creditCard_type').val("");
        $('#dwfrm_billing_paymentMethods_creditCard_owner').val("");
        $('#dwfrm_billing_paymentMethods_creditCard_adyenEncryptedCardNumber').val("");
        $('#dwfrm_billing_paymentMethods_creditCard_adyenEncryptedExpiryMonth').val("");
        $('#dwfrm_billing_paymentMethods_creditCard_adyenEncryptedExpiryYear').val("");
        $('#dwfrm_billing_paymentMethods_creditCard_adyenEncryptedSecurityCode').val("");
    }

    copyCardData(card) {
        $('#dwfrm_billing_paymentMethods_creditCard_type').val(card.state.brand);
        $('#dwfrm_billing_paymentMethods_creditCard_owner').val(card.state.data.holderName);
        $('#dwfrm_billing_paymentMethods_creditCard_adyenEncryptedCardNumber').val(card.state.data.encryptedCardNumber);
        $('#dwfrm_billing_paymentMethods_creditCard_adyenEncryptedExpiryMonth').val(card.state.data.encryptedExpiryMonth);
        $('#dwfrm_billing_paymentMethods_creditCard_adyenEncryptedExpiryYear').val(card.state.data.encryptedExpiryYear);
        $('#dwfrm_billing_paymentMethods_creditCard_adyenEncryptedSecurityCode').val(card.state.data.encryptedSecurityCode);

        if(window.storeDetails) {
            $('#dwfrm_billing_paymentMethods_creditCard_saveCard').val(window.storeDetails);
        }
        else {
            $('#dwfrm_billing_paymentMethods_creditCard_saveCard').val(false);
        }
    }

    getCardFields() {
        var cardFields = {};
        var allFields = $('input[id*="dwfrm_billing_paymentMethods_creditCard_"]');

        $.each(allFields, function(k, v) {
            var name = $(v).attr('name');
            var value = $(v).val();
            if (name && value) {
                cardFields[name] = value;
            }
        });

        return cardFields;
    }

    onButtonClick (el, ev) {
        ev.preventDefault();

        if (prefs.ADYEN_SF_ENABLED) {
            this.clearCardData();
            if (!window.CardValid) {
                window.AdyenCard.showValidation();
                return false;
            }
            this.copyCardData(window.AdyenCard);
        }

        if (!this.actionName) {
            return;
        }

        this.isChildsValid(() => {
            preloader.show(this.$el);

            /* include hidden fields that are not declared as JS components */
            var cardFields = this.getCardFields();
            var data = this.getFormFields(this, cardFields) || {};

            data[this.actionName] = 'true';

            eventMgr.execute('service.form.submit', {
                action: this.$el.attr('action'),
                data: data
            }).then((response) => {
                if (response.success) {
                    this.successAction(response);
                } else {
                    this.failAction(response);
                }
                if (!response.success && response.errors) {
                    this.highlightErrors(response.errors);
                }
            });
        });

        return false;
    }

}