'use strict';

import $ from 'jquery';
import dialog from 'dialog';
import eventMgr from 'eventMgr';

const ADDITIONAL_OFFSET = 10;

var scrollUtil = {
    inScroll: false,
    offset: 0,
    /**
     * @function
     * @description Scrolls a browser window to a given x point
     * @param {String} The x coordinate
     */
    scrollTo: function (topOffsetOrElement, cb, isPdp = false) {
        var $scrollElement,
            scrollToElement = typeof topOffsetOrElement === 'object',
            topOffset;

        if (this.inScroll) {
            return;
        }
        this.inScroll = true;

        if (scrollToElement && dialog.isOpen()) {
            $scrollElement = dialog.getDialogElement();
            topOffset = topOffsetOrElement.position().top;
        } else {
            if (scrollToElement) {
                topOffset = topOffsetOrElement.offset().top - this.offset - ADDITIONAL_OFFSET;
            } else {
                topOffset = topOffsetOrElement;
            }
            $scrollElement = $('html, body');
        }
        if (isPdp) {
            let minus = ($('.header-wrapper').outerHeight() - $('.header-content').outerHeight()) - ADDITIONAL_OFFSET;
            topOffset -= minus;
        }
        
        $scrollElement.animate({
            scrollTop: topOffset
        }, {
            duration: 500,
            complete: () => {
                if (typeof cb === 'function') {
                    cb();
                }
                this.inScroll = false;
            }
        });
    },
    setOffset: function (offset) {
        this.offset = offset;
    }
};

eventMgr.on('Header.stickyHeight', (offset) => {
    scrollUtil.setOffset(offset);
});

module.exports = scrollUtil;
