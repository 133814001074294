'use strict';

import ajax from 'ajax';
import eventMgr from 'eventMgr';
import utils from 'utils';

const emitter = eventMgr.getEmitter('quickbuy');

const loadQuickBuy = (params) => {
    return ajax.load({
        url: utils.appendParamToURL(params.url, 'quickbuy', 'true')
    }).then((data) => {
        emitter.emit('update', {
            qbTemplate: data,
            qbModal: params.qbModal,
            qbBtn: params.qbBtn
        });
        return data;
    });
}

eventMgr.registerAction('quickbuy.load', loadQuickBuy);