import eventMgr from '../eventMgr';
import InputText from '../forms/InputText';

const promocode = eventMgr.getEmitter('Promocode');

export default class Promocode extends InputText {
    init () {
        super.init();

        this.event('click', '.js-coupon-title', this.toggle);
        this.event('click', '.js-submit_btn', this.submit);
        this.event('keypress', this.onKeyPress);
    }
    onKeyPress(el, ev) {
        if (ev.which === 13) {
            ev.preventDefault();
            this.submit(el, ev);
            return false;
        }
    }
    toggle () {
        this.$el.toggleClass(this.config.classOpenned);

        //Add aria-expanded attribute to button to improve accessibility
        if (this.$el.find('button').length > 0) {
            if (this.$el.hasClass(this.config.classOpenned)) {
                this.$el.find('button').attr('aria-expanded', 'true');
            } else {
                this.$el.find('button').attr('aria-expanded', 'false');
            }
        }
    }
    submit (el, ev) {
        ev.preventDefault();

        promocode.emit('change.' + this.config.formId, this.config.actionName);
    }
}