import InputGeneric from './InputGeneric';
import debounce from 'lodash/debounce';
import preloader from 'preloader';

const MAX_DEFAULT_QTY = 99;

export default class InputMoreLess extends InputGeneric {
    init () {
        //super.init(); we don't need init parent here
        if (!this.id) {
            this.id = this.$el.data('id');
        }
        this.$field = this.$el.find('.js-input');
        if (typeof this.config.maxVal !== 'undefined' && this.config.maxVal < 1) {
            this.$field.attr('disabled', true);
        } else {
            this.event('touchstart click', '.js-less', debounce(this.decr, 150));
            this.event('touchstart click', '.js-more', debounce(this.incr, 150));
            this.event('change', '.js-input', this.onChangeValue);
        }
        if (!this.config.maxVal || this.config.maxVal > MAX_DEFAULT_QTY) {
            this.config.maxVal = MAX_DEFAULT_QTY;
        }
        this.shown = true;
    }
    onChangeValue () {
        const newVal = parseInt(this.getValue(), 10);

        if (newVal > (this.config.maxVal)) {
            this.setValue(this.config.maxVal);
        } else {
            this.setValue(newVal);
        }
    }
    decr () {
        preloader.show(this.$el);
        const newVal = parseInt(this.getValue(), 10) - 1;

        if (newVal >= (typeof this.config.minVal === 'undefined' ? 1 : this.config.minVal)) {
            this.setValue(newVal);
        } else {
            this.removeProduct();
        }
    }
    incr () {
        preloader.show(this.$el);
        const newVal = parseInt(this.getValue(), 10) + 1;

        if (newVal <= this.config.maxVal) {
            this.setValue(newVal);
        }
    }
    getValue () {
        return this.$field.val();
    }
    setValue (val = '') {
        this.setError();
        if (val >= (typeof this.config.minVal === 'undefined' ? 1 : this.config.minVal) && val <= this.config.maxVal) {
            this.$field.val(val);
        } else {
            this.$field.val(1);
        }

        this.emitter.emit('change', this);
    }
    setError (err = '') {
        if (err) {
            this.$el.addClass('m-error');
        } else {
            this.$el.removeClass('m-error');
        }
    }
    isValid () {
        const val = parseInt(this.getValue(), 10);

        return val >= 0 && val <= this.config.maxVal;
    }
    validate () {
        return this.isValid();
    }
    removeProduct () {
        this.$el.closest('.cart-row').find('button.js-button_remove').trigger('click');
    }
}
