import Component from '../Component';
import eventMgr from 'eventMgr';
import ajax from 'ajax';
import utils from 'utils';

export default class YotpoPagination extends Component {
	init() {
		 super.init();
		 
		 this.event('click', this.$el, this.changePage);
		 this.event('click', '.js-next', this.nextPage);
		 this.event('click', '.js-prev', this.prevPage);
	}
	changePage(el, event) {
		event.preventDefault();
		var target = event.target;
		
		if (target.tagName !== 'A') {
			return;
		}
		
		$(el).find('li').removeClass('active');
		$(target).closest('li').addClass('active');
		
		var url = utils.appendParamToURL(Urls.yotpoPaginationURL, 'page', $(target).closest('li').data('page'));

		ajax({
	        url: url,
	        type: 'POST'
	    }).then((resp) => {
	    	$('.js-main-widget').empty();
	    	$('.js-main-widget').append(resp);
	    	this.reloadWidgets();
	    });
	}
	nextPage(el, event) {
		var $el = $(el);
		var $prevPage = $('.js-prev');
		var $parent = $(el).closest('.pagination');
		
		var $li = $parent.find('li:eq(' + $el.data('page') + ')');
		$parent.find('li').removeClass('active');
		$li.addClass('active');
		
		var url = utils.appendParamToURL(Urls.yotpoPaginationURL, 'page', $el.data('page'));
		
		ajax({
	        url: url,
	        type: 'POST'
	    }).then((resp) => {
	    	$('.js-main-widget').empty();
	    	$('.js-main-widget').append(resp);
	    	this.reloadWidgets();
	    	
			$prevPage.data('page', parseInt($el.data('page')) - 1);
			if ($el.data('page') >= $el.data('total')) {
				$el.addClass('is-hidden');
			} else {
				$el.data('page', parseInt($el.data('page')) + 1);
			}
			
			if ($prevPage.data('page') > 0) {
				$prevPage.removeClass('is-hidden');
			}
	    });
		
	}
	prevPage(el, event) {
		var $el = $(el);
		var $nextPage = $('.js-next');
		var $parent = $(el).closest('.pagination');
		
		var $li = $parent.find('li:eq(' + $el.data('page') + ')');
		$parent.find('li').removeClass('active');
		$li.addClass('active');
		
		var url = utils.appendParamToURL(Urls.yotpoPaginationURL, 'page', $el.data('page'));

		ajax({
	        url: url,
	        type: 'POST'
	    }).then((resp) => {
	    	$('.js-main-widget').empty();
	    	$('.js-main-widget').append(resp);
	    	this.reloadWidgets();
	    	
	    	$nextPage.data('page', parseInt($el.data('page')) + 1);
			if ($el.data('page') == 0) {
				$el.addClass('is-hidden');
			} else {
				$el.data('page', parseInt($el.data('page')) - 1);
			}
			
			if ($nextPage.data('page') < $nextPage.data('total')) {
				$nextPage.removeClass('is-hidden');
			}
	    });
	}
	reloadWidgets() {
		if (typeof yotpo != 'undefined') {
			yotpo = new Yotpo(yotpo.appKey, yotpo.userSettings);
			Yotpo.ready(function() {
		        yotpo.init();
		    });
		}
	}
}
