import Block from '../common/Block';

export default class ToggleClass extends Block {
    init () {
        super.init();

        this.event('click', this.config.titleSelector, this.toggle);
    }
    toggle () {
        this.$el.toggleClass(this.config.classOpenned);

        //Add aria-expanded attribute to button to improve accessibility
        if (this.$el.find('button').length > 0 || this.$el.is('button')) {
            if (this.$el.hasClass(this.config.classOpenned)) {
                this.$el.find('button').attr('aria-expanded', 'true');

                if (this.$el.is('button')) {
                    this.$el.attr('aria-expanded', 'true');
                }
            } else {
                this.$el.find('button').attr('aria-expanded', 'false');

                if (this.$el.is('button')) {
                    this.$el.attr('aria-expanded', 'false');
                }
            }
        }
    }
}