import InputMoreLess from '../forms/InputMoreLess';
import eventMgr from 'eventMgr';
import screendetector from 'screendetector';

const lineItemCount = eventMgr.getEmitter('LineItemCount');

export default class LineItemCount extends InputMoreLess {
    init () {
        super.init();
        this.setReadonly();
        this.checkIfDisabled();
        this.eventMgr('screenDetector.deviceChange', this.checkIfDisabled);
    }
    setValue (val = '') {
        super.setValue(val);
        lineItemCount.emit('change.' + this.config.formId, this.config.actionName);
    }
    checkIfDisabled () {
        if (screendetector.getDeviceId() === screendetector.MOBILE) {
            this.disabled = this.config.disabledOnMobile;
        } else {
            this.disabled = !this.config.disabledOnMobile;
        }
    }
}
