import Block from './Block';
import dialog from 'dialog';
import $ from 'jquery';
import scrollUtil from 'scrollUtil';
import util from 'utils';

$(document).on('click', function (e) {
    if ($(e.target).hasClass('product-social_list')
        || $(e.target).hasClass('product-social_item')
        || $(e.target).hasClass('product-social_link')) {
        return;
    }

    if ($('.product-social_list').length > 0) {
        $('.product-social_list').slideUp();
    }
});

// Close the modal with the ESC key
$(document).keyup(function (e) {
    if (dialog.isOpen()) {
        if (e.keyCode == 27) {
            dialog.close();
        }
    }
});

export default class Main extends Block {
    init() {
        super.init();
        this.event('DOMSubtreeModified', '.checkout-giftcert_block', this.showDeliveryNote);
        this.event('click', '.js-checkout-giftcert-title', this.showDeliveryNote);
        this.event('click', '.product-accordion .accordion-title:not(.active)', this.accordionToggle);
        this.event('click', '.product-accordion .accordion-title.active', this.hideAccordion);
        this.event('click', '.js-custom-accordion .accordion-title', this.customAccordionToggle);
        this.event('click', '.product-social .product-social_label', this.showSocial);
        this.event('click', '.product-social .close-social_button', this.showSocial);
        this.event('click', '.zooInOut.mobile .in', this.zoomInMobile);
        this.event('click', '.zooInOut.mobile .out', this.zoomOutMobile);
        this.event('click', '.zooInOut.desktop .in', this.zoomInDesktop);
        this.event('click', '.zooInOut.desktop .out', this.zoomOutDesktop);
        this.event('click', '.greenstory-cta', this.showGreenstoryWidget);
        this.event('mouseleave', '.product-image_section_desktop .productImage', this.hideCusrorTxt);

        $('body').off('mousemove mousewheel').on('mousemove mousewheel', '.product-image_section_desktop .productImage', function (e) {
            var pos_x = e.pageX - $(this).offset().left;
            var pos_y = e.pageY - $(this).offset().top;
            pos_x = (pos_x + 10), pos_y = (pos_y + 15);

            $('.cusrsor-content').hide();

            var currentImageIndex = $(this).attr('data-img-count');
            var imageCount = $('.product-image_section_desktop > .productImage').length;
            var text = currentImageIndex + '/' + imageCount;

            $(this).find('.cusrsor-content').css({'left': pos_x, 'top': pos_y, 'display': 'inline-block'});
        });
    }

    hideCusrorTxt() {
        $('.cusrsor-content').hide();
    }

    showDeliveryNote() {
        if (window.ApplePaySession && (this.$el.find('isapplepay').length === 0)) {
            this.$el.find('.js-apple-pay-enabled').removeClass('hidden');
            this.$el.find('.js-apple-pay-disabled').addClass('hidden');
        } else {
            this.$el.find('.js-apple-pay-disabled').removeClass('hidden');
            this.$el.find('.js-apple-pay-enabled').addClass('hidden');
        }
    }

    accordionToggle(el, event) {
        this.closeAllAcordionElements();
        var $box = $(el).closest('.accordion-list');

        if ($box.hasClass('greenstory-widget')) {
            this.toggleGreenstoryWidget();
            return;
        }
        this.expandAccordion($box, el);
    }

    closeAllAcordionElements() {
        $('.accordion-panel').css('display', 'none').attr('aria-hidden', true);
        $('.accordion-title').removeClass('active')
        $('.accordion-list').attr('aria-expanded', false).attr('aria-selected', false);
    }

    expandAccordion($box, el) {
        $box.children('.accordion-panel').css('display', 'block').attr('aria-hidden', false);
        $(el).addClass('active');
        $box.attr('aria-expanded', true).attr('aria-selected', true);
        if ($box.parents('.product-detail').length) {
            var pdpSticky = $('.product-detail').data('sticky');
            
            if (pdpSticky) {
                pdpSticky.updateSticky();
            }
        }
        setTimeout(() => {
            if (!util.isInViewport($(el)[0])){
                scrollUtil.scrollTo($(el), null, true);
            }
        },300);
    }
    customAccordionToggle(el, event) {
        var $box = $(el).closest('.accordion-list');

        if ($(el).parents('.accordion-panel').length > 0) {
            if ($(el).hasClass('active')) {
                this.closeAllSubPanels(el);
            } else {
                this.closeAllSubPanels(el);

                $box.children('.accordion-panel').addClass('active').attr('aria-hidden', false);
                $(el).addClass('active');
                $box.attr('aria-expanded', true).attr('aria-selected', true);
            }
        }

        if ($(el).parents('.accordion-panel').length === 0) {
            if ($(el).hasClass('active')) {
                $box.children('.accordion-panel').removeClass('active').attr('aria-hidden', true);
                $(el).removeClass('active');
                $box.attr('aria-expanded', false).attr('aria-selected', false);
            } else {
                $('.accordion-panel').removeClass('active').attr('aria-hidden', true);
                $('.accordion-title').removeClass('active');
                $('.accordion-list').attr('aria-expanded', false).attr('aria-selected', false);

                $box.children('.accordion-panel').addClass('active').attr('aria-hidden', false);
                $(el).addClass('active');
                $box.attr('aria-expanded', true).attr('aria-selected', true);
            }
        }
    }


    zoomInMobile() {
        MagicZoom.zoomIn('productImageMobile');
    }

    zoomOutMobile() {
        MagicZoom.zoomOut('productImageMobile');
    }

    zoomInDesktop() {
        if (!$('.zooInOut.desktop .in').hasClass('zoomIn')) {
            var windowWidth = $(window).width();
            var zoomID = sessionStorage.getItem('maagicZoomID');
            MagicZoom.zoomIn(zoomID);
            if (windowWidth > this.tabletBreakpointEnd) {
                $('.zooInOut.desktop .in').addClass('zoomIn');
                MagicZoom.zoomOut(zoomID);
                MagicZoom.zoomIn(zoomID);
                $('.zooInOut.desktop .in').removeClass('zoomIn');
            }
        }
    }

    zoomOutDesktop() {
        var zoomID = sessionStorage.getItem('maagicZoomID');
        MagicZoom.zoomOut(zoomID);
    }

    hideAccordion() {
        $('.accordion-panel').css('display', 'none').attr('aria-hidden', true);
        if ($('.accordion-title.active').parents('.product-detail').length) {
            var pdpSticky = $('.product-detail').data('sticky');
            if (pdpSticky) {
                pdpSticky.updateSticky();
            }
        }
        $('.accordion-title').removeClass('active');
        $('.accordion-list').attr('aria-expanded', false).attr('aria-selected', false);
        return false;
    }

    showSocial(el, event) {
        $('.product-social_list').slideToggle();
        event.stopPropagation();
    }

    closeAllSubPanels(el) {
        var $accordionPanel = $(el).parents('.accordion-panel');
        $accordionPanel.find('.accordion-panel').removeClass('active').attr('aria-hidden', true);
        $accordionPanel.find('.accordion-title').removeClass('active');
        $accordionPanel.find('.accordion-list').attr('aria-expanded', false).attr('aria-selected', false);
    }


    showGreenstoryWidget(el, event) {
        if (event) {
            event.preventDefault();
        }
        scrollUtil.scrollTo($('.greenstory-widget'), null, true);
        this.toggleGreenstoryWidget();
    }

    toggleGreenstoryWidget(el, event) {
        this.closeAllAcordionElements();

        $('.greenstory-widget-pdp-detail').removeClass('hide');

        var $box = $('.greenstory-widget');
        var elToExpand = $box.find('.accordion-title');

        this.expandAccordion($box, elToExpand);
    } 
}
