'use strict';

import ajax from 'ajax';
import eventMgr from 'eventMgr';
import utils from 'utils';
import preloader from 'preloader';

const emitter = eventMgr.getEmitter('product');

const getQuickView = (data) => {
    emitter.emit('ajax.start');

    return ajax.load({
        url: utils.appendParamToURL(data.url.replace("#", "?"), 'source', 'quickview')
    }).then((data) => {
        emitter.emit('quickview.updated', data);
        return data;
    });
};

const getVariation = (data) => {
    emitter.emit('ajax.start');

    return ajax.load({
        url: utils.removeParamFromURL(data.url, 'source_variation', 'variation_content'),
        'data': {
            expandcolor: data.expandcolor
        },
        donthidepreloader: data.donthidepreloader
    }).then((data) => {
        emitter.emit('variation.updated', data);
        return data;
    });
};

const getVariationBlock = (data) => {
    emitter.emit('ajax.start');

    return ajax.load({
        url: utils.appendParamToURL(data.url, 'source_variation', 'variation_content'),
        'data': {
            expandcolor: data.expandcolor,
            quantity: data.quantity,
            postalCodeValue: data.postalCodeValue 
        },
        donthidepreloader: data.donthidepreloader
    }).then((data) => {
        preloader.show();
        emitter.emit('variation_block.updated', data);
        return data;
    });
};

const getProductNav = (data) => {
    emitter.emit('ajax.start');

    return ajax.load({
        url: data.url
    });
};

const getApplicablePaymentMethods = (data) => {
    emitter.emit('ajax.start');

    return ajax.load({
        url: data.url
    });
};

const getAllProductsFromPrimaryCategory = (data) => {
    emitter.emit('ajax.start');
    preloader.show();
    return ajax.load({
        url: data.url,
        'data': {
            productStyle: data.productStyle,
            pid: data.pid,
            deviceIpad: data.deviceIpad
        }
    }).then((data) => {
        emitter.emit('allstyle.updated', data);
        return data;
    });
}

const finddeliveryoptions = (data) => {
    emitter.emit('ajax.start');
    preloader.show();
    return ajax.load({
        url: data.url,
        'data': {'postalCode': data.value}
    }).then((data) => {
        return data;
    }).fail(function (jqxhr, status, err) {
        var obj = {jqxhr: jqxhr, status: status, err: err};
        return obj;
    });
};

const getNextStore = (data) => {
    emitter.emit('ajax.start');
    preloader.show();
    return ajax.load({
        url: data.url,
        'data': {'storeNumber': data.storeNumber}
    }).then((data) => {
        return data;
    });
};

eventMgr.registerAction('product.quickview.get', getQuickView);
eventMgr.registerAction('product.variation.get', getVariation);

eventMgr.registerAction('product.navigation.get', getProductNav);
eventMgr.registerAction('product.variation_block.get', getVariationBlock);

eventMgr.registerAction('product.payment_methods.get', getApplicablePaymentMethods);
eventMgr.registerAction('product.all_style.get', getAllProductsFromPrimaryCategory);

eventMgr.registerAction('product.finddeliveryoption.submit', finddeliveryoptions);
eventMgr.registerAction('product.next_store.get', getNextStore);