import Block from '../common/Block';
import eventMgr from 'eventMgr';
import res from 'resources';

export default class BillingForm extends Block {
    init () {
        super.init();
    }

    formLoad (paymentMethodId) {
        eventMgr.execute('checkout.billing.form.get', {
            paymentMethodId: paymentMethodId
        }).then((billingFormHTML) => {
            this.$el.html(billingFormHTML);
        });
    }
}
