import Block from './Block';
import eventMgr from 'eventMgr';
import screendetector from 'screendetector';
import $ from 'jquery';

const MENUOPENED = 'header-nav_active';

export default class HeaderMenu extends Block {
    init () {
        super.init();
        this.eventMgr('hamburgermenu.changestatus', this.menuChangeStatus);
        this.eventMgr('hamburgermenu.open', this.menuOpen);
        this.eventMgr('hamburgermenu.close', this.menuClose);
        this.eventMgr('headermenu.closemenu', this.menuClose);
        this.eventMgr('shippingcountry.changed', this.updateCountrySelector);
        this.eventMgr('SearchSuggestion.onSuggest', this.disableScroll);
        this.eventMgr('SearchSuggestion.close', this.enableScroll);
        this.event('click', '.js-country-selector', this.openCountrySelectorPopup);

        this.eventMgr('dialog.open', () => {
            this.dialogOpened = true;
        });
        this.eventMgr('dialog.close', () => {
            this.dialogOpened = false;
        });

        this.event('focusin', (el, event) => {
            event.stopPropagation();
        });
    }
    menuChangeStatus () {
        this.$el.toggleClass(MENUOPENED);
    }
    menuOpen () {
        this.$el.addClass(MENUOPENED);
    }
    menuClose () {
        this.$el.removeClass(MENUOPENED);
    }
    openCountrySelectorPopup() {
        this.$el.find('.js-open-country-selector-popup-header a').click();
    }
    updateCountrySelector() {
        var self = this;

        $.ajax({
            url: Urls.includeCountrySelector
        }).done(function (html) {
            if (html) {
                self.$el.find('.js-country-selector-wrapper').replaceWith(html);
            }
        });
    }

    disableScroll() {
        this.$el.addClass('js-stop-overflow');
    }

    enableScroll() {
        this.$el.removeClass('js-stop-overflow');
    }
}
