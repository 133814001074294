import Block from './Block';
import $ from 'jquery';
import resources from 'resources';

export default class HeaderBanner extends Block {
    init () {
        super.init();
        var pageURL = window.location.href;
        var array = pageURL.split('/');
        this.urlEnd = array[array.length-2];
        
        this.initHeaderBanner();
    }

    initHeaderBanner() {
        var contentBlock = $('.header-banner .homepage-features_inner');
        var self = this;

        contentBlock.each(function() {
            var styleMe = $(this).find('a').attr('href').toString();
            var snip = styleMe.split('/');
            var lastSegment = snip.pop() || snip.pop(); // handle potential trailing slash

            if (lastSegment.toString() === self.urlEnd) {
                setTimeout(() => {
                    $('html, body').animate({scrollTop: $(".search-result_options").offset().top-100}, 2000);
                },400);

                $(this).addClass('selected');
                var createdElement = $('<span class="homepage-block_label">');
                $(this).append(createdElement);
                createdElement.text(resources.SELECTED_ITEM_HEADER_BANNER);
            }

            if (!$(this).hasClass('selected')) {
                $(this).on('mouseenter',self.onMouseEnter);
                $(this).on('mouseleave',self.onMouseLeave);
            }
        });
    }

    onMouseEnter () {
        var createdElement = $('<span class="homepage-block_label">');
        $(this).append(createdElement);
        createdElement.text(resources.SELECT_ITEM_HEADER_BANNER);
    }
    onMouseLeave () {
        $(this).find('span').remove();
    }
}
