import Block from '../common/Block';
import eventMgr from '../eventMgr';

const emitter = eventMgr.getEmitter('ProductQuantity'),
    MAX_QUANTITY = 99;

export default class ProductQuantity extends Block {
    init () {
        super.init();
        this.currentValue = parseInt(this.getValue(), 10);
        this.available = this.config.available;
        this.callFnForId('Quantity', 'setReadonly');
        if (this.config.disabled) {
            this.callFnForId('Quantity', 'disable');
        } else {
            this.onChild('quantityDown', 'click', this.quantityDown);
            this.onChild('quantityUp', 'click', this.quantityUp);
            this.onChild('Quantity', 'change', this.changeField);
        }
    }
    changeField (quantityInput) {
        this.setValue(quantityInput.getValue());
        this.emitter.emit('change', this);
    }
    setValue (newValue = 1) {
        newValue = parseInt(newValue, 10);
        if (newValue !== this.currentValue) {
            this.currentValue = this.checkValue(newValue);
            this.callFnForId('Quantity', 'setValue', this.currentValue);
        }
        emitter.emit('changed', this.currentValue);
    }
    getValue () {
        var value = '';

        this.getById('Quantity', (quantityInput) => {
            value = quantityInput.getValue();
        });
        return value;
    }
    quantityDown () {
        this.setValue(this.currentValue - 1);
    }
    quantityUp () {
        if (this.currentValue < MAX_QUANTITY) {
            this.setValue(this.currentValue + 1);
        }
    }
    checkValue (newValue = 1) {
        if (!newValue || newValue < 1) {
            newValue = 1;
        }

        if (newValue > this.available) {
            newValue = this.available;
        }
        return newValue;
    }
}
