import Component from '../Component';
import util from 'utils';
import e from 'cors';
import scrollUtil from 'scrollUtil';
import screendetector from 'screendetector';

export default class AddToWishlist extends Component {
    init() {
        this.quantity = 1;
        this.eventMgr('ProductQuantity.changed', function (value) {
            this.quantity = value;
        });
        this.event('click', this.onClick);
    }
    onClick(el, e) {
        var href = this.$el.attr('href');

        href = util.appendParamToURL(href, 'Quantity', this.quantity);
        this.$el.attr('href', href);

        if (!$('.product-swatches.size').find('.product-item ').hasClass('selected')) {
            e.preventDefault();
            var $sizeSelector = $('.product-variations_sizes_container');

            this.chooseSizeError($sizeSelector, true);
            return false;
        }

        return true;
    }
    chooseSizeError ($element, error) {
        if (error) {
            $element.attr('style','border-bottom: 2px solid #ff0032');
            $('.product-add_to_wishlist').addClass('disabled');
            $('.size-error').addClass('show-error');
            $('.product-add_to_bag').attr('style','background-color: #595959; pointer-events: none; cursor: normal');
            $('.size-error').addClass('show-error');
        } else {
            $element.removeAttr('style').find('.product-variations_size').removeClass('product-variations_disabled');
            $('.size-error').removeClass('show-error');
        }
    }
}