import Component from '../Component';
import eventMgr from 'eventMgr';
import util from 'utils';
import urls from 'urls';

export default class ProductNav extends Component {
    init () {
        super.init();
        var pid = this.config.pid,
            hash, url;

        if ((window.location.hash.length <= 1 && !window.pageHash) || !pid) {
            return;
        }

        if (window.pageHash) {
            hash = window.pageHash;
        } else {
            hash = window.location.hash.substr(1);
        }

        url = util.appendParamToURL(urls.productNav + '?' + hash, 'pid', pid);
    }
}
