import Block from './Block';
import scrollUtil from 'scrollUtil';
import $ from 'jquery';

const defaultHeight = '55';

export default class ReadMore extends Block {
    init () {
        super.init();

        if (this.config.contentType === 'list') {
            this.event('click', '.' + this.config.triggerElement, (el, ev) => {
                ev.preventDefault();
                this.$el.find('.js-list-item').removeClass('hidden');
                $(el).addClass('hidden');
            })
        } else {
            this.height = this.$el.height();

            this.defaultHeight = defaultHeight;
            if (this.config.defaultHeight) {
                this.defaultHeight = this.config.defaultHeight;
                this.getById('readMoreContent', (readMoreContent) => {
                    readMoreContent.$el.animate({maxHeight: this.defaultHeight});
                });
            }

            this.event('click', '.js-read-more-link', this.onReadMore);
            this.event('click', '.js-read-less-link', this.onReadLess);

            if (this.height > this.defaultHeight) {
                this.onChild('readMore', 'click', this.open);
                this.onChild('readLess', 'click', this.close);
            } else {
                this.callFnForId('readMore', 'hide');
                this.callFnForId('social', 'show');
            }
        }
        this.loadBreadcrumbClass();
    }
    onReadMore (el, event) {
        event.preventDefault();
        this.$el.addClass(this.config.openedClass);
    }
    onReadLess (el, event) {
        event.preventDefault();
        this.$el.removeClass(this.config.openedClass);
    }
    open () {
        this.callFnForId('readMore', 'hide');
        this.callFnForId('readLess', 'show');
        this.callFnForId('social', 'show');
        this.getById('readMoreContent', (readMoreContent) => {
            readMoreContent.$el.animate({maxHeight: '100%'});
        });
    }
    close () {
        this.callFnForId('readLess', 'hide');
        this.callFnForId('social', 'hide');
        this.callFnForId('readMore', 'show');
        this.getById('readMoreContent', (readMoreContent) => {
            readMoreContent.$el.animate({maxHeight: this.defaultHeight});
        });
        if (this.config.descriptionBlock) {
            scrollUtil.scrollTo($('.' + this.config.descriptionBlock));
        }
    }

    loadBreadcrumbClass() {
        var $breadcrumbColor = $('.plp-banner-wrapper').attr('data-breadcrumb-color');
        
        if ($breadcrumbColor) {
            $('.js-breadcrumbs-wrap').addClass($breadcrumbColor);
        }
    } 
}
