import ajax from 'ajax';
import Block from '../common/Block';
import eventMgr from 'eventMgr';
import $ from 'jquery';
import 'product';
import 'quickbuy';
import preloader from 'preloader';
import lazyImageObserverObj from 'lazyImageObserver';
import util from 'utils';

const PRODUCT_IMAGE = '.product-image';
const PRODUCT_SIZE = '.product-size';
const PRODUCT_INFO = '.product-info-wrapper';

export default class ProductTile extends Block {
    init () {

        super.init();

        this.event('click', '.plp-size:not(.product-link_disabled)', this.onSizeClick);
        /** Add click listener for mobile and tablet class */
        this.event('click', '.plp-size-mobile', this.onSizeClick);
        this.event('click', '.qb-product-color .tile-color-swatch', this.onColorClick);
        this.event('click', '.colors-swatches-items-mobile .tile-color-swatch', this.onTileSwatchClick);

        // removing based on client's comment ORBOPT-2838
        // this.event('click', '.produt-images-mobile', this.handleMobileImageClick);

        this.event('mouseenter', '.product-image', this.onProductImageHover);

        this.event('mouseenter', this.$el, this.onProductTileMouseEnter);
        this.id = this.$el.data('uuid');
        /**
         * will add event handling for add to bag button
         */
        this.event('click', '.add-to-bag-btn', this.addToBagClick);
        /**
         * listen for product.added event to the cart and if it is from mobile
         * will show success message, will add id to the event also, because
         * otherwise all productTiles on the page will execute this listener,
         * which is not a behaviour we want.
         */
        this.eventMgr(`cart.product.added.${this.id}`, this.mobileCartAdd);
    }

    mobileCartAdd(params) {
        var isMobileClick = params.isMobileClick;
        if (!isMobileClick) return;

        /**
            * show the alert message popup and hide the sizes from
            * product tile.
        */
        $('.alertMessage').show();
        setTimeout(() => {
            $('.alertMessage').hide();
        }, 3000);
    }

    addImagesToObserver(){
        /**
         * Convert the jQuery object to jsObsect and pass proper DOMElement to the observer.
         * It will not work with jQuery object.
         */
        let jsel = this.$el[0];
        let images = jsel.querySelector('ul').querySelectorAll('img');
        for (let i = 0; i < images.length; i++){
            images[i].classList.add('lazy_load_backgroud');
            lazyImageObserverObj.addImage(images[i]);
        }
        lazyImageObserverObj.refreshObserver();
    }

    handleMobileImageClick (el, event) {
        let currentIndex = 0 || $(el).find('.slick-active').data('slick-index');

        if ($(el).hasClass('clicked') || currentIndex !== 0) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();

        this.getById('plpProdutTileMoboleCarousel', (cmp) => {
            cmp.executeSlickFn('slickNext');
        });

        $(el).addClass('clicked');
    }

    onColorClick (el, event) {
        let $tile = $(el).closest('.tile');
        let url = $(el).data('href');

        var viewMode = $('select.plp-viewmode_list--mobile').find(':selected').attr('data-value');
        var URLParameterToEnableModelViewType = $('.refinements').attr('data-url-parameter-to-enable-mode-viewtype');

        if (viewMode != ''){
            url = util.removeParamFromURL(url, URLParameterToEnableModelViewType);
            url = url + '&' + URLParameterToEnableModelViewType + '=' + viewMode;
        }

        var gridCount = $('.grid-mode').find('.clicked').attr('data-grid');

        url = util.appendParamsToUrl(url, {
            'gc': gridCount, 'plpTile': true
        });

        if ($(el).hasClass('selected')) {
            return false;
        }

        $tile.find('.tile-color-swatch.selected').removeClass('selected');
        $(el).addClass('selected');

        preloader.show($tile);
        ajax({
            url: url,
            type: 'GET'
        }).then((resp) => {
            let $producImage = $tile.find(PRODUCT_IMAGE);
            let $sizeSelector = $tile.find(PRODUCT_SIZE);
            let $productInfo = $tile.find(PRODUCT_INFO);

            $producImage.empty().replaceWith($(resp).find(PRODUCT_IMAGE));
            $sizeSelector.empty().replaceWith($(resp).find(PRODUCT_SIZE));
            $productInfo.empty().replaceWith($(resp).find(PRODUCT_INFO));

            var selectedOption = $('.plp-viewmode_list--mobile').find(':selected').attr('data-value');
            var productImg;
            var modelImg;

            $tile.find('.not-roundend').removeClass('lazy');
            $tile.find('.main-image').removeClass('lazy');
            if (selectedOption == 'model'){
                productImg = $tile.find('.product-link.main > picture').html();
                modelImg = $tile.find('.product-image_hover').find('.item-list:first-child').find('picture').html();

                $tile.find('.product-link.main > picture').html(modelImg);
                $tile.find('.product-image_hover').find('.item-list:first-child').find('picture').html(productImg);
            } else {
                productImg = $tile.find('.product-link.main > picture').html();
                modelImg = $tile.find('.product-image_hover').find('.item-list:first-child').find('picture').html();

                $tile.find('.product-link.main > picture').html(productImg);
                $tile.find('.product-image_hover').find('.item-list:first-child').find('picture').html(modelImg);
            }

            this.addImagesToObserver();
            this.productImageCarousel = false;
            this.productSizeCarousel = false;

            if ($tile.find('.qb-modal').hasClass('qb-active')) {
                $tile.find('.add-to-bag-btn').hide();
            }

            preloader.hide();
        });
    }
    onSizeClick (el, event) {
        // add product to cart on PLP
        event.preventDefault();
        event.stopPropagation();

        var isMobileClick = $(el).hasClass('plp-size-mobile');
        var pid = $(el).data('variant-id');
        var $sizeSwatch = $(el).closest('.product-swatches.size');

        // add selected class
        $sizeSwatch.find('.plp-size.selected').removeClass('selected');
        $(el).addClass('selected');

        eventMgr.execute('cart.add', {
            pid: pid,
            Quantity: 1,
            productName: this.config.productName,
            isMobileClick: isMobileClick,
            id: this.id
        });
        return false;
    }

    onProductImageHover (el, event) {
        if (!this.productImageCarousel) {
            preloader.show(this.$el);
            this.getById('plpProdutTileCarousel', (cmp) => {
                cmp.initSlick();
                preloader.hide();
            });
            if (this.$el.find('.single').length) {
                preloader.hide();
            }
            this.productImageCarousel = true;
        }
    }
    onProductTileMouseEnter (el, event) {
        if (!this.productSizeCarousel) {
            this.getById('plpProductSize', (cmp) => {
                cmp.initSlick();
            });
            this.productSizeCarousel = true;
        }
    }

    addToBagClick(el, event) {
        const qbUrl = this.$el.find('input.qbUrl').val();
        eventMgr.execute('quickbuy.load', {url: qbUrl, qbModal: this.$el.find('.qb-modal'), qbBtn: el});
    }

    onTileSwatchClick(el, event) {
        let pdpUrl = $(el).attr('data-linkURL');
        window.location = pdpUrl;
    }
}

