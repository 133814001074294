import InputGeneric from './InputGeneric';
export default class InputCustomSelect extends InputGeneric {
    init () {
        var firstOptionText = $('.js-select').data('first-option-text');

        this.setCustomSelect(firstOptionText);
        this.event('click', this.closeAllSelect);
    }

    setCustomSelect (firstOptionText) {
        var x, i, j, selElmnt, a, b, c;

        x = document.getElementsByClassName('js-select');

        for (i = 0; i < x.length; i++) {
            selElmnt = x[i].getElementsByTagName('select')[0];
            a = document.createElement('div');
            a.setAttribute('class', 'select-selected');
            a.innerHTML = firstOptionText ? firstOptionText + ' : ' + selElmnt.options[selElmnt.selectedIndex].innerHTML : selElmnt.options[selElmnt.selectedIndex].innerHTML;
            x[i].appendChild(a);
            b = document.createElement('ul');
            b.setAttribute('class', 'select-items select-hide');
            for (j = 0; j < selElmnt.length; j++) {
                /*for each option in the original select element,
                create a new li that will act as an option item:*/
                c = document.createElement('li');
                c.innerHTML = selElmnt.options[j].innerHTML;
                c.addEventListener('click', function(e) {
                    var y, i, k, s, h;
                    s = this.parentNode.parentNode.getElementsByTagName('select')[0];
                    h = this.parentNode.previousSibling;
                    for (i = 0; i < s.length; i++) {
                        if (s.options[i].innerHTML === this.innerHTML) {
                            s.selectedIndex = i;
                            h.innerHTML = firstOptionText ? firstOptionText + ' : ' + this.innerHTML : this.innerHTML;
                            y = this.parentNode.getElementsByClassName('same-as-selected');
                            for (k = 0; k < y.length; k++) {
                                y[k].removeAttribute('class');
                            }
                            this.setAttribute('class', 'same-as-selected');
                            break;
                        }
                    }
                    h.click();
                });
                b.appendChild(c);
            }
            x[i].appendChild(b);
            a.addEventListener('click', function(e) {
                e.stopPropagation();
                this.nextSibling.classList.toggle('select-hide');
                this.classList.toggle('select-arrow-active');
            });
        }
    }

    closeAllSelect(el) {
        var x, y, i, arrNo = [];
        x = document.getElementsByClassName('select-items');
        y = document.getElementsByClassName('select-selected');
        for (i = 0; i < y.length; i++) {
            if (el == y[i]) {
                arrNo.push(i)
            } else {
                y[i].classList.remove('select-arrow-active');
            }
        }
        for (i = 0; i < x.length; i++) {
            if (arrNo.indexOf(i)) {
                x[i].classList.add('select-hide');
            }
        }
    }

}
