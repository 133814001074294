import FormGeneric from '../forms/FormGeneric';
import dialog from 'dialog';
import util from 'utils';
import eventMgr from '../eventMgr';

const emitter = eventMgr.getEmitter('shippingcountry');

export default class ShippingCountrySelector extends FormGeneric {
    init () {
        super.init();
        this.event('click', '.js-save-shipping-country', this.saveSelection);
        this.event('click', '.js-cancel', this.cancelSelection);
        this.event('click', '.ui-dialog-titlebar-close', this.cancelSelection);
        this.event('update', '.js-shipping-country-input', this.update);
        this.event('change', '.language select, .currency select', this.updateLocale);
        this.eventMgr('dialog.close', this.cancelSelection);
    }
    cancelSelection () {
        util.setCrossSubdomainCookie('shipping_country_prompt', true, 43200);
        dialog.close();
    }
    saveSelection () {
        var country = this.$el.find('.js-shipping-country-input').data('value'),
            currency = this.$el.find('.js-currency select').val(),
            locale = this.$el.find('.js-locale select').val(),
            needToRedirect = this.$el.find('.js-is-redirect-required').val(),
            needToRefresh = this.$el.find('.js-is-refresh-required').val();

        util.setCrossSubdomainCookie('shipping_country', country + '|' + currency + '|' + locale,
            window.SitePreferences.COOKIE_COUNTRY_LIFE_TIME);

        if (needToRedirect === 'true') {
            this.$el.find('.js-delivery-form').submit();
        } else {
            if (needToRefresh === 'true') {
                window.location.reload();
            } else {
                emitter.emit('changed');
            }
        }

        dialog.close();
    }
    update () {
        var self = this;
        var country = this.$el.find('.js-shipping-country-input').data('value');
        var currency = this.$el.find('.js-currency select').val();

        this.$el.find('.js-save-shipping-country').prop('disabled', true);

        $.ajax({
            url: util.appendParamsToUrl(Urls.updateShippingCountries, {
                country: country,
                currency: currency,
                currentPlace: this.$el.data('currentPlace'),
                format: 'ajax'
            })
        }).done(function (html) {
            if (html) {
                self.$el.replaceWith(html);
            }
        });
    }

    updateLocale () {
        this.$el.find('.js-is-redirect-required').val('true');
    }
}
