import Block from '../common/Block';
import $ from 'jquery';
import eventMgr from 'eventMgr';
import 'grid';

export default class Paging extends Block {
    init () {
        super.init();

        this.event('click', '.' + this.config.triggerclass, this.onViewAllClick);
        this.eventMgr('grid.updated', this.refresh);
        this.eventMgr('grid.gotData', this.refresh);
    }
    onViewAllClick (el, ev) {
        ev.preventDefault();

        eventMgr.execute('grid.getMoreItems', {
            url: $(el).attr('href')
        });
        
        window.history.pushState({pagination: true}, '', $(el).attr('href'));
    }
    refresh (data) {
        var $html = $('<div>' + data + '</div>'),
            $sortby = $html.find('.js-pagingbar');

        this.setHtml($sortby.html());
    }
}
