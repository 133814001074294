import Block from '../common/Block';


export default class StoresBlock extends Block {
    init () {
        super.init();
        this.onChild('country-label', 'click', this.onLabelClick);
        if (this.$el.find('.js-store-content:visible').length > 0) {
            this.onLabelClick();
        }
    }
    onLabelClick () {
        this.callFnForId('map', 'loadMap');
    }
}
