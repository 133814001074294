
import $ from 'jquery';
import EventEmitter from 'events';
import eventMgr from './eventMgr';

export default class Component {
    constructor (el, config = {}) {
        this.$el = $(el);
        this.config = config;
        this.listeners = [];
        this.eventMgrListeners = [];
        this.items = [];
        this.emitter = new EventEmitter();
        if (config.cmpId) {
            this.id = config.cmpId;
        } else {
            if (this.$el.attr('id')) {
                this.id = this.$el.attr('id');
            }
            if (!this.id && config.id) {
                this.id = config.id;
            }
        }

        this.tabletBreakpointEnd = 1366;
        this.mobileBreakpointEnd = 845;
        this.iphoneBreakpointEnd = 430;
    }
    // jscs:disable disallowEmptyBlocks
    init () {}
    // jscs:enable disallowEmptyBlocks
    destroy () {
        var i, item, event, $element;

        while ((event = this.listeners.pop())) {
            $element = event.shift();
            $element.off.apply($element, event);
        }
        while ((event = this.eventMgrListeners.pop())) {
            eventMgr.off.apply(eventMgr, event);
        }
        for (i = 0; i < this.items.length; ++i) {
            item = this.items[i];
            if (item) {
                if (typeof item.destroy === 'function') {
                    item.destroy();
                }
                delete this.items[i];
            }
        }
        delete this.$el;
        this.emitter.removeAllListeners();
    }
    isBindedToDom () {
        return !!this.$el.closest('html').length || this.$el.is('html');
    }
    event (eventName, selector, cb, $element) {
        var self = this,
            fn = function () {
                return cb.apply(self, [this].concat(Array.prototype.slice.call(arguments)));
            };

        if (typeof selector === 'function') {
            $element = cb || self.$el;
            cb = selector;
            $element.on(eventName, fn);
            self.listeners.push([$element, eventName, fn]);
        } else {
            $element = $element || self.$el;
            $element.on(eventName, selector, fn);
            self.listeners.push([$element, eventName, selector, fn]);
        }
        return self;
    }
    on (eventName, cb) {
        return this.emitter.on(eventName, cb);
    }
    off (eventName, cb) {
        return this.emitter.off(eventName, cb);
    }
    once (eventName, cb) {
        return this.emitter.once(eventName, cb);
    }
    eventMgr (event, handler) {
        handler = handler.bind(this);
        eventMgr.on(event, handler);
        this.eventMgrListeners.push([event, handler]);
    }
    callFnForId (id, name, ...args) {
        this.getById(id, (cmp) => {
            cmp[name](...args);
        });
    }
    getById (id, cb) {
        for (var c = 0; c < this.items.length; ++c) {
            if (this.items[c]) {
                if (this.items[c].id === id) {
                    cb.call(this, this.items[c]);
                } else {
                    this.items[c].getById(id, cb);
                }
            }
        }
    }
}
