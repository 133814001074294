import Block from '../common/Block';
import preloader from 'preloader';
import eventMgr from 'eventMgr';
import $ from 'jquery';
import 'product';
import util from 'utils';

export default class ProductVariation extends Block {
    init () {
        super.init();

        this.event('click', '.js-only_variation_button', this.ajaxReload);

        this.eventMgr('product.variation_block.updated', this.update);
    }
    ajaxReload (el, event) {
        if (event)
            event.preventDefault();

        var $el = $(el),
            url = $el.attr('href') || $el.data('variationUrl'),
            selected = $el.data('selected'),
            expandColor = $el.data('expandcolor') || false,
            quantity = '';

            this.getById('Quantity', (quantityInput) => {
                quantity = quantityInput.getValue();
            });

        if (selected) {
            return;
        }
        var postalCodeValue = $('#postalCode').val() ? $('#postalCode').val() : null;
        if (url) {
            preloader.show();
            eventMgr.execute('product.variation_block.get', {
                url: url,
                expandcolor: expandColor,
                quantity: quantity,
                postalCodeValue: postalCodeValue
            }).then(() => {
                this.getApplicablePaymentMethods();
                util.updateReviewsView();
            });
        }
    }
    update (html) {
        this.setHtml(html);
        this.$el.find('.form-row_select--unselected').removeClass('form-row_select--unselected');

        if (!this.$el.closest('.pdp-main[data-cmp="quickView"]').length) {
            var variationUrl = this.$el.find('.js-variation-block-url').data('url');
            variationUrl && history.replaceState({}, document.title, variationUrl);
        }

        if (yotpo) {
            util.refreshYotpoWidget();
        }
    }
    getApplicablePaymentMethods() {
        var $el = $('.product-alternative_payment'),
            url = $el.data('applicablepaymentmethods');

        if (url) {
            eventMgr.execute('product.payment_methods.get', {
                url: url
            }).then((data) => {
                if (data) {
                    var responseData = data.methodResp;

                    if (responseData) {
                        if (!responseData.klarna) {
                            $('.product-klarna_descriptions').addClass('hidden');
                        } else {
                            $('.product-klarna_descriptions').removeClass('hidden');
                        }
                        if (!responseData.payPal) {
                            $('#PDP_paypal').addClass('hidden');
                        } else {
                            $('#PDP_paypal').removeClass('hidden');
                        }
                        if (!responseData.applePay) {
                            $('.js-apple-pay-pdp-button').addClass('hidden');
                        } else {
                            setTimeout(() => {
                                if (window.ApplePaySession && ($('isapplepay').length === 0)) {
                                    if (!$('.js-apple-pay-pdp-button').hasClass('applepay-button')) {
                                       $('.js-apple-pay-pdp-button').addClass('applepay-button');
                                        $('.js-apple-pay-pdp-button').removeClass('hidden');
                                    }
                                } else {
                                    $('.js-apple-pay-pdp-button').removeClass('applepay-button');
                                    $('.js-apple-pay-pdp-button').addClass('hidden');
                                }
                            }, 250);
                        }
                    }
                }
            });
        }
    }
}
