import InputGeneric from './InputGeneric';
import util from 'utils';

export default class InputTextarea extends InputGeneric {
    init () {
        super.init();
        if (this.config.maxlength) {
            this.event('keyup', '.js-input_field', this.handleKeyUp);
            this.event('keydown', this.onKeyDown);
            this.handleKeyUp();

            this.$el.find('.input-textarea').attr('aria-describedby', 'id-count-chart');
        }
    }
    handleKeyUp () {
        var currentValue = (this.getValue() + '').replace(/\r?\n/g, '\r\n');

        if (currentValue.length > this.config.maxlength) {
            currentValue = currentValue.substring(0, this.config.maxlength);
            this.setValue(currentValue);
        }

        var charactersMessage = this.leftCharacters(currentValue);

        this.$el.find('.js-count_chart').remove();
        this.$el.find('.js-input_field').after(charactersMessage);
    }
    onKeyDown (elem, e) {
        e.stopPropagation();
    }
    leftCharacters (currentValue) {
        var currentNumber =  this.config.maxlength - currentValue.length;

        var mMaxNumber = util.format(
            this.config.maxlengthtext,
            this.config.maxlength
        );

        var mCurrentNumber = util.format(
            this.config.charactersLeft,
            currentNumber
        );
        
        var roleAlert = 'role="alert"';
        
        if(currentNumber < this.config.maxlength) {
            roleAlert = ' ';
        }

        var mCount = '<span>' + mCurrentNumber + '</span>';
        if (currentNumber === 100 || currentNumber <= 20) {
            mCount = '<span aria-live="polite" aria-atomic="true">' + mCurrentNumber + '</span>';
        }

        return  '<p id="id-count-chart" class="js-count_chart count_chart" ' + roleAlert + '><span>' + mMaxNumber + '</span> '+  mCount + '</p>';  
    }
}
