import Block from './Block';

export default class CookiesCheck extends Block {
    init () {
        super.init();
        if (!navigator.cookieEnabled && (!document.cookie || document.cookie.length === 0)) {
            this.show();
        }
    }
}
