import Block from '../common/Block';
import eventMgr from 'eventMgr';
import $ from 'jquery';
import resources from 'resources';

export default class CustomVideoControls extends Block {
    init () {
        super.init();
        this.video = this.$el.closest('.video-controls-wrapper').find('video')[0];
        this.playBtn = this.$el.find('.playPause');
        this.muteBtn = this.$el.find('.muteUnmute');
        this.isDesktopView = $(window).width() > this.tabletBreakpointEnd;

        if (this.video && this.video.muted) {
            this.muteBtn.removeClass('unmuted').addClass('muted');
        }

        this.attachEvents();
        this.setBottom();
    }

    attachEvents() {
        if (this.video) {
            this.event('click', '.playPause', this.playPauseHandler);
            this.event('click', '.muteUnmute', this.muteUnmuteHandler);

            $(window).on('scroll', this.setBottom.bind(this));
        }
    }

    playPauseHandler() {
        if (this.playBtn.hasClass('pause')){
            this.video.pause();
            this.playBtn.removeClass('pause').addClass('play');
        } else if (this.playBtn.hasClass('play')){
            this.video.play();
            this.playBtn.removeClass('play').addClass('pause');
        }
    }

    muteUnmuteHandler() {
        if (this.muteBtn.hasClass('unmuted')){
            this.video.muted = true;
            this.muteBtn.removeClass('unmuted').addClass('muted');
        } else if (this.muteBtn.hasClass('muted')){
            this.video.muted = false;
            this.muteBtn.removeClass('muted').addClass('unmuted');
        }
    }

    setBottom() {
        if ($(window).scrollTop() === 0){
            this.$el.css({'bottom': '2.5rem'});
        } else {
            if (this.isDesktopView) {
                this.$el.css({'bottom': '0.5rem'});
            } else {
                this.$el.css({'bottom': '0.25rem'});
            }
        }
    }
}
