import Block from '../common/Block';
import preloader from 'preloader';

export default class PreloaderBlock extends Block {
    init () {
        super.init();
        if (this.config.preloaderEvent) {
            this.eventMgr(this.config.preloaderEvent, this.showProgress);
        }
    }
    showProgress () {
        preloader.show(this.$el);
    }
}