import Button from '../forms/Button';
import pageUtils from 'pageUtils';

export default class ButtonLink extends Button {
    init () {
        super.init();

        if (this.config.href) {
            this.href = this.config.href;
        } else {
            this.href = this.$el.attr('href');
        }
    }
    onClick (el, event) {
        if (!this.config.dontPrevent) {
            event.preventDefault();
        }

        if (this.href) {
            pageUtils.redirect(this.href);
        }
    }
}
