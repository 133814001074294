import Form from '../forms/Form';
import eventMgr from 'eventMgr';
import prefs from 'prefs';

export default class RegistrationForm extends Form {
    init () {
        super.init();
        var form = this.$el;

        if (prefs.RECAPTCHA_ENABLE && window.grecaptcha) {
            grecaptcha.ready(function() {
                grecaptcha.execute(prefs.RECAPTCHA_SITE_KEY).then(function(token) {
                    $('<input>').attr({
                        type: 'hidden',
                        name: 'g-recaptcha-response',
                        value: token
                    }).appendTo(form);
                });
            });
        }
    }
}
