import Component from '../Component';
import debounce from 'lodash/debounce';
import eventMgr from 'eventMgr';
import 'googleMap';
/* globals google */

export default class Maps extends Component {
    init () {
        super.init();
        this.debounceMapInit = debounce(this.mapInit, 100);
    }
    loadMap () {
        if (!this.mapLoaded) {
            eventMgr.execute('googleMap.init', this.config.key).done(() => {
                this.debounceMapInit(this.$el.find('.js_map').get(0), this.config.lat, this.config.lng, google.maps);
            });
            this.mapLoaded = true;
        }
    }
    mapInit(el, lat, lng, mapObject) {
        var storeLocation = new mapObject.LatLng(lat, lng),
            map, marker;

        map = new mapObject.Map(el, {
            zoom: 15,
            center: storeLocation
        });

        marker = new mapObject.Marker({
            position: storeLocation
        });

        marker.setMap(map);
    }
}
