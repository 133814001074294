'use strict';

import ajax from 'ajax';
import eventMgr from 'eventMgr';

const getSuggest = (data) =>
    ajax.load({
        url: data.url
    });

eventMgr.registerAction('search.getSuggest', getSuggest);