import Form from '../forms/Form';
import eventMgr from '../eventMgr';
import dialog from 'dialog';
import 'newsletter';

export default class NewsletterSubmit extends Form {
    callBack () {
        dialog.close();
    }
    onSubmit (el, ev) {
        ev.preventDefault();

        if (this.isChildsValid()) {
            var data = {
                method: this.$el.attr('method'),
                action: this.$el.attr('action'),
                fields: this.getFormFields()
            };

            eventMgr.execute('newsletter.submit', data).then(this.callBack);
        }

        return false;
    }
}