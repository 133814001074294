import FormGeneric from './FormGeneric';
import $ from 'jquery';

export default class Form extends FormGeneric {

}

if ($('.preference-update_button').length > 0) {
    $('.preference-update_button').on('click', function(event) {
        $('.loader').show();
    });
}
