import Block from '../common/Block';
import screendetector from '../screendetector';
import $ from 'jquery';

export default class ViewerKit extends Block {
    init () {
        super.init();
        let div = document.getElementById('ampviewerkitdata');
        let ampClient = div.getAttribute('data-amp-client');
        let ampBasepath = div.getAttribute('data-amp-basepath');
        let ampSet = div.getAttribute('data-amp-set');
        let badge = div.getAttribute('data-amp-badge');

        let badgeHtml = '';

        if (badge) {
            badgeHtml = `
                <div class="product-badge">
                    <span class="badge">
                        ${badge}
                    </span>
                </div>
            `;
        }

        let isMobile = screendetector.getDeviceId() === screendetector.MOBILE;

        let configuration = {
            target: '#amp-pdp-container',
            client: ampClient,
            imageBasePath: ampBasepath,
            set: ampSet,
            secure: true,
            isMobile: isMobile,
            initCallback: function () {
                // we show the badge only for the first image
                // this in this context is the viewer component
                if (badgeHtml && this.currentAssetIndex === 0) {
                    $('.main-container').append(badgeHtml);
                }
            },
            navIconsMain: {
                next: 'nav-arrow nav-next',
                prev: 'nav-arrow nav-prev'
            },
            navIconsNav: {
                next: 'nav-arrow nav-next',
                prev: 'nav-arrow nav-prev'
            },
            ampConfigs: {
                navElementsWidthPx: 100,
                navElementsWidthPxMobile: 60,
                navContainerCarousel: {
                    onActivate: {
                        select: true,
                        goTo: false
                    }
                }
            }
        };

        var sitePreferenceConfiguration;

        try {
            sitePreferenceConfiguration = JSON.parse(window.SitePreferences.VIEWER_KIT_CONFIGURATION)
        } catch (e) {
            sitePreferenceConfiguration = {};
        }

        window.viewer = new amp.Viewer(Object.assign(configuration, sitePreferenceConfiguration));

        $('body').on('ampcarouselchange', (e, data) => {
            // we want to subscribe only for the main carousel change and this one fires
            // for the navigational one too
            if ($(e.target).parent().hasClass('main-container')) {
                if (data.index === 1 && badgeHtml) {
                    $('.main-container').append(badgeHtml);
                } else {
                    $('.main-container').find('.product-badge').remove();
                }
            }
        });
    }
}
