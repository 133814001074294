import Component from '../Component';
import eventMgr from 'eventMgr';

const NOSCROLL = 'noscroll',
    emitter = eventMgr.getEmitter('Html');

export default class Body extends Component {
    init () {
        super.init();
        this.dialogOpened = false;
        this.menuOpened = false;
        this.openSelectCountryPopup();
        this.lastScrollTop = 0;
        this.eventMgr('dialog.open', () => {
            this.dialogOpened = true;
            //this.disableScroll();
        });
        this.eventMgr('dialog.close', () => {
            this.dialogOpened = false;
            if (!this.dialogOpened && !this.menuOpened) {
                //this.enableScroll();
            }
        });

        this.eventMgr('hamburgermenu.open', this.onMenuOpen);
        this.eventMgr('hamburgermenu.close', this.onMenuClose);
        this.eventMgr('minicart.mobile.open', this.onMenuOpen);
        this.eventMgr('minicart.mobile.close', this.onMenuClose);
    }
    openSelectCountryPopup () {
        if (this.$el.find('.js-open-country-selector-popup').length > 0) {
            this.$el.find('.js-open-country-selector-popup a').trigger('click');
        }

    }
    onMenuOpen () {
        this.menuOpened = true;
        this.disableScroll();
    }
    onMenuClose () {
        this.menuOpened = false;
        if (!this.dialogOpened && !this.menuOpened) {
            this.enableScroll();
        }
    }
    disableScroll () {
        this.$el.addClass(NOSCROLL);
        emitter.emit('scroll.disable');
    }
    enableScroll () {
        emitter.emit('scroll.enable');
        this.$el.removeClass(NOSCROLL);
    }
}
