import Block from '../common/Block';
import $ from 'jquery';
import eventMgr from 'eventMgr';
import 'grid';
import util from 'utils';

const emitter = eventMgr.getEmitter('Viewmode');

$(document).on('mouseup touchend', (e) => {
    emitter.emit('outsideClick', e);
});

export default class Viewmode extends Block {
    init () {
        super.init();
        this.toggleVisibility();

        $(window).on('resize', () => {
            this.toggleVisibility();
        });

        this.event('click', '.' + this.config.triggerclass, this.onViewmodeClick);
        this.event('change', '.plp-viewmode_list--mobile', this.onViewmodeClick);
        this.event('click', '.js-toggle', this.onToggle);
        this.event('click', '.js-viewmode-toggle', this.onToggle);
        this.eventMgr('grid.updated', this.refresh);
        this.eventMgr('grid.gotData', this.refresh);

        this.event('focusout', 'ul.plp-viewmode_list li:last-child', this.lastItem);

        if (!this.config.skipOutsideClick) {
            this.eventMgr('Viewmode.outsideClick', this.onOutsideClick);
        }
    }

    lastItem () {
        if (this.$el.hasClass(this.config.toggleclass)) {
            this.$el.removeClass(this.config.toggleclass);
            this.$el.find('button').attr('aria-expanded', 'false').focus();
        }
    }

    onOutsideClick (e) {
        if (this.$el.hasClass(this.config.toggleclass) && !this.$el.is(e.target)
            && this.$el.has(e.target).length === 0) {
            this.$el.removeClass(this.config.toggleclass);
        }
    }

    onViewmodeClick (el, ev) {
        ev.preventDefault();
        var viewmodeUrl;
        var currentViewMode = $('.plp-viewmode_value').text();

        if (el.classList.contains('plp-viewmode_list--mobile')) {
            var selectedOption = $(el).find(':selected').attr('data-value');

            if (currentViewMode === selectedOption) {
                return;
            }
            viewmodeUrl = $(el).find(':selected').attr('value');
            $(el).width('fit-content');
            $('select.plp-viewmode_list--mobile option').attr('selected', null);
            $('select.plp-viewmode_list--mobile option[value="' + selectedOption + '"]').attr('selected', 'selected');

        } else if (el.classList.contains('desktop-link')) {
            viewmodeUrl = $(el).attr('href');
            this.$el.toggleClass(this.config.toggleclass);
        }
        viewmodeUrl = util.updateGridurl(viewmodeUrl);

        eventMgr.execute('grid.getData', {
            url: viewmodeUrl
        }).then(function() {
            util.updateViewMode(selectedOption);
        });

        return false;
    }

    onToggle() {
        this.$el.toggleClass(this.config.toggleclass);

        if (this.$el.find('button').length > 0) {
            if (this.$el.hasClass(this.config.toggleclass)) {
                this.$el.find('button').attr('aria-expanded', 'true');
            } else {
                this.$el.find('button').attr('aria-expanded', 'false');
            }
        }
    }

    refresh (data) {
        var $html = $('<div>' + data + '</div>'),
            $viewmode = $html.find('.js-viewmode');

        this.setHtml($viewmode.html());
    }

    toggleVisibility() {
        var windowWidth = window.screen.width;

        switch (true) {
            case (windowWidth < this.tabletBreakpointEnd):
                this.$el.removeClass('js-videwmode-visibility');
                break;
            case (windowWidth >= this.tabletBreakpointEnd):
                this.$el.addClass('js-viewmode-visibility');
                break;
            default:
                this.$el.removeClass('js-viewmode-visibility');
        }
    }
}