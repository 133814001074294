import LoginForm from '../account/LoginForm';

export default class CheckoutSummaryLoginForm extends LoginForm {
    init () {
        super.init();
        this.disableEmailField();
    }
    disableEmailField () {
        this.$el.find('.js-email input').prop('disabled', true);
    }
}
