'use strict';

import ajax from 'ajax';
import eventMgr from 'eventMgr';


const sendForm = (params) =>
    ajax.getJson({
        type: params.method || 'POST',
        url: params.action,
        data: params.data
    });

const getData = (params) =>
    ajax.load({
        type: params.method || 'GET',
        url: params.url
    });

eventMgr.registerAction('service.get', getData);
eventMgr.registerAction('service.form.submit', sendForm);
