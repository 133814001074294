'use strict';

import 'jquery-ui/dialog';
import eventMgr from 'eventMgr';
import resources from 'resources';
import ajax from 'ajax';
import util from 'utils';
import $ from 'jquery';

const emitter = eventMgr.getEmitter('dialog');

var dialog = {
    /**
     * @function
     * @description Appends a dialog to a given container (target)
     * @param {Object} params  params.target can be an id selector or an jquery object
     */
    create: function (params) {
        var $target, id;

        if ($.type(params.target) === 'string') {
            if (params.target.charAt(0) === '#') {
                $target = $(params.target);
            } else {
                $target = $('#' + params.target);
            }
        } else if (params.target instanceof $) {
            $target = params.target;
        } else {
            $target = $('#dialog-container');
        }

        // if no element found, create one
        if ($target.length === 0) {
            if ($target.selector && $target.selector.charAt(0) === '#') {
                id = $target.selector.substr(1);
                $target = $('<div>').attr('id', id).addClass('dialog-content').appendTo('body');
            }
        }
        if ($target.data('ui-dialog')) {
            $target.dialog('destroy');
        }
        // create the dialog
        this.$container = $target;
        if (!this.$container.data('dialogEventAttached')) {
            // Dialog action listener to emit component's event
            this.$container.on('dialogopen', () => {
                this.$pageYOffset = window.pageYOffset;
                emitter.emit('open');
            }).on('dialogclose', () => {
                emitter.emit('close');
                if (this.$pageYOffset) {
                    window.scrollTo(0, this.$pageYOffset);
                }
            }).on('dialogopen', () => {
                var $dialogContainer = this.$container.parents('.ui-dialog'),
                    $wrapper = $dialogContainer.children('.ui-dialog-content_wrapper');

                if (!$wrapper.length) {
                    $wrapper = $('<div class="ui-dialog-content_wrapper"/>')
                        .appendTo($dialogContainer)
                        .on('click', (e) => {
                            e.stopPropagation();
                        });

                    $dialogContainer.on('click', () => {
                        this.close();
                    });
                }
                $dialogContainer.children().not('.ui-dialog-content_wrapper').appendTo($wrapper);
                var $backButton = $dialogContainer.find('.ui-dialog-buttonpane .js-back-button');

                if ($backButton.length) {
                    $backButton.prependTo($dialogContainer.find('.ui-dialog-titlebar'));
                }
                $dialogContainer.scrollTop(0);
                this.$wrapper = $wrapper;
            });
            this.$container.data('dialogEventAttached', true);
        }
        // closing dialog on outside click
        if (!this.overlayInited) {
            $('body').on('click', '.ui-widget-overlay', () => {
                this.close();
            });
            this.overlayInited = true;
        }
        this.$container.dialog($.extend(true, {}, this.settings, params.options || {}));
    },
    /**
     * @function
     * @description Creates, fills and appends a custom raw dialog to a given container (target)
     * @param {Object} params  params.target can be an id selector or an jquery object
     */
    createRaw: function (params) {
        var $target, id;

        if ($.type(params.target) === 'string') {
            if (params.target.charAt(0) === '#') {
                $target = $(params.target);
            } else {
                $target = $('#' + params.target);
            }
        } else if (params.target instanceof $) {
            $target = params.target;
        } else {
            $target = $('#dialog-raw-container');
        }

        // if no element found, create one
        if ($target.length === 0) {
            if ($target.selector && $target.selector.charAt(0) === '#') {
                id = $target.selector.substr(1);
                $target = $('<div>').attr('id', id).addClass('hidden').appendTo('body');
            }
        }

        if (params.class) {
            $target.addClass(params.class);
        }

        if (params.url) {
            params.url = util.appendParamToURL(params.url, 'format', 'ajax');
            ajax.load({
                url: params.url,
                type: 'GET',
                data: params.data,
                target: $target,
                callback: function (response) {
                    if ($target.not(':visible')) {
                        $target.removeClass('h-hidden');
                    }
                    if (typeof params.callback === 'function') {
                        params.callback(response);
                    }
                }
            });
        }

        // create the raw dialog
        this.$containerRaw = $target;
        return this.$containerRaw;
    },
    /**
     * @function
     * @description Opens a dialog using the given url (params.url) or html (params.html)
     * @param {Object} params
     * @param {Object} params.url should contain the url
     * @param {String} params.html contains the html of the dialog content
     */
    open: function (params) {
        // close any open dialog
        this.close();
        this.create(params);
        this.replace(params);
    },
    /**
     * @description populate the dialog with html content, then open it
     **/
    openWithContent: function (params) {
        var content, position, callback;

        if (!this.$container) {
            this.create(params);
        }
        content = params.content || params.html;
        if (!content) { return; }
        this.$container.empty().html(content);
        if (!this.$container.dialog('isOpen')) {
            this.$container.dialog(params.options);
            this.$container.dialog('open');
        }

        if (params.options) {
            position = params.options.position;
        }
        if (!position) {
            position = this.settings.position;
        }

        callback = (typeof params.callback === 'function') ? params.callback : function () {};
        callback();
        eventMgr.execute('componentmgr.update');
    },
    /**
     * @description Replace the content of current dialog
     * @param {object} params
     * @param {string} params.url - If the url property is provided, an ajax call is performed to get the
     * content to replace
     * @param {string} params.html - If no url property is provided, use html provided to replace
     */
    replace: function (params) {
        if (!this.$container) {
            return;
        }
        if (params.url) {
            params.url = util.appendParamToURL(params.url, 'format', 'ajax');
            ajax.load({
                url: params.url,
                type: 'GET',
                data: params.data,
                callback: function (response) {
                    params.content = response;
                    this.openWithContent(params);
                }.bind(this)
            });
        } else if (params.html || params.content) {
            this.openWithContent(params);
        }
    },
    /**
     * @function
     * @description Closes the dialog
     */
    close: function () {
        if (!this.$container) {
            return;
        }
        this.$container.dialog('close');
        this.$container.dialog('option', 'buttons', []);
        eventMgr.execute('componentmgr.release');
    },
    /**
     * @function
     * @description Closes the raw dialog
     */
    closeRaw: function () {
        if (!this.$containerRaw) {
            return;
        }
        this.$containerRaw.addClass('hidden');
    },
    /**
     * @function
     * @description Submits the dialog form with the given action
     * @param {String} The action which will be triggered upon form submit
     */
    submit: function (action) {
        var $form = this.$container.find('form:first'),
            data,
            url;

        // set the action
        $('<input/>').attr({
            name: action,
            type: 'hidden'
        }).appendTo($form);
        // serialize the form and get the post url
        data = $form.serialize();
        url = $form.attr('action');
        // make sure the server knows this is an ajax request
        if (data.indexOf('ajax') === -1) {
            data += '&format=ajax';
        }
        // post the data and replace current content with response content
        $.ajax({
            type: 'POST',
            url: url,
            data: data,
            dataType: 'html',
            success: function (html) {
                this.$container.html(html);
            }.bind(this),
            failure: function () {
                util.Alert(resources.SERVER_ERROR);
            }
        });
    },
    restore: function ($oldInstance) {
        this.close();
        this.$container = $oldInstance;
        if (!this.$container.dialog('isOpen')) {
            this.$container.dialog('open');
        }
    },
    exists: function () {
        return this.$container && (this.$container.length > 0);
    },
    getCurrent: function () {
        return this.$container;
    },
    isActive: function () {
        return this.exists() && (this.$container.children.length > 0);
    },
    isOpen: function ($instance) {
        if ($instance) {
            return $instance.dialog('isOpen');
        }
        return this.$container && this.$container.dialog('isOpen');
    },
    getDialogElement: function () {
        return this.$container && this.$container.parents('.ui-dialog');
    },
    getWrapper: function () {
        return this.$wrapper;
    },
    settings: {
        autoOpen: false,
        height: 'auto',
        modal: true,
        dialogClass: '',
        overlay: {
            opacity: 0.5,
            background: 'black'
        },
        resizable: false,
        draggable: false,
        title: '',
        width: '600',
        close: function () {
            dialog.close();
        },
        position: {
            my: 'center',
            at: 'center',
            of: window,
            collision: 'flipfit'
        }
    }
};

module.exports = dialog;
