import {
    wrap
} from 'lodash';
import Block from './Block';

/**
 * Carousel in vanilla javascript without using slick or any other external carousel
 */

let staticAmountToMove = 0;
let staticWidth = 0;

export default class FilterMobileCarousel extends Block {
    init() {
        super.init();

        let jsElem = this.$el[0];
        this.parentDiv = jsElem.parentElement;
        let wrapperDiv = document.createElement('div');
        let progressDiv = document.createElement('div');
        this.carouselHidden = false;

        wrapperDiv.classList.add('wrapper');
        progressDiv.classList.add('progress');

        wrapperDiv.appendChild(jsElem);
        this.parentDiv.appendChild(wrapperDiv);
        this.parentDiv.appendChild(progressDiv);

        this.refresh();
    }

    refresh() {
        let jsElem = this.$el[0];
        const wrapperDiv = this.parentDiv.querySelector('.wrapper');
        const slides = jsElem.querySelectorAll('.ref-group');

        let amountToMove = undefined;
        let totalImages = 0;
        let amountFound = false;

        for (let slide of slides) {
            let imagesAmount = slide.querySelectorAll('.content_item').length;
            if (imagesAmount > 1) {
                amountToMove = Math.round(slide.getBoundingClientRect().width / imagesAmount);
                amountFound = true;
                break;
            }
        }

        slides.forEach((slide) => {
            let imagesAmount = slide.querySelectorAll('.content_item').length;
            totalImages += imagesAmount;

            if (totalImages < 4) {
                if (imagesAmount == 1 && !amountFound) {
                    amountToMove = slide.getBoundingClientRect().width;
                }
            }

            if (imagesAmount == 0) {
                slide.remove();
            }
        });

        let scrollWidth = wrapperDiv.scrollWidth;
        let screenWidth = screen.width;

        if (amountToMove !== 0) {
            staticAmountToMove = amountToMove;
        }

        if (amountToMove === 0) {
            amountToMove = staticAmountToMove;
        }

        if (wrapperDiv.scrollWidth !== 0) {
            staticWidth = wrapperDiv.scrollWidth;
        }

        if (wrapperDiv.scrollWidth === 0) {
            scrollWidth = window.screen.width;
        }

        if (window.screen.width >= this.tabletBreakpointEnd) {
            wrapperDiv.classList.remove('wrapper');
            this.parentDiv.classList.add('flex_centered');

            return;
        }

        if (totalImages < 4) {
            wrapperDiv.classList.remove('wrapper')
            this.parentDiv.classList.add('flex_centered');

            return;
        }

        let scrollToEnd = wrapperDiv.scrollWidth - wrapperDiv.offsetWidth;
        let isScrolledToEnd = undefined;

        updateProgress(wrapperDiv.scrollLeft, wrapperDiv, true);

        wrapperDiv.addEventListener('touchmove', (e) => {
            updateProgress(wrapperDiv.scrollLeft, wrapperDiv, false);
        });

        window.addEventListener('orientationchange', (e) => {
            if (window.matchMedia('(orientation: portrait)').matches) {
                wrapperDiv.classList.remove('wrapper')
                this.parentDiv.classList.add('flex_centered');
            } else {
                wrapperDiv.classList.add('wrapper')
                this.parentDiv.classList.remove('flex_centered');
            }
        });
    }
}

function updateProgress(scrollLeft, wrapperDiv, refresh) {
    var scrollWidth = wrapperDiv.scrollWidth;
    var $progressBar = $('.progress');
    var screenWidth = screen.width //width of the mobile screen
    var defaultProgress = 30;
    var calc = 0;

    if (scrollLeft > 0) {
        calc = (scrollLeft + defaultProgress) / (scrollWidth - (screenWidth)) * 100;
    } else {
        calc = (scrollLeft + defaultProgress);
    }
    if (scrollWidth > screenWidth) {
        $progressBar
            .css('background-size', calc + '% 100%')
             .css('display', 'block')
            .attr('aria-valuenow', calc)
            .attr('role', 'progressbar');
    } else {
        if (!refresh) {
            $progressBar
            .css('display', 'none');
        }
    }
}
