import Dropdown from './Dropdown';

export default class DropdownWithCarousel extends Dropdown {
    onShow () {
        if (!this.carouselInited) {
            this.callFnForId(this.config.carousel, 'initSlick');
            this.carouselInited = true;
        }
        this.recalculateSticky();
    }
}
