import FormGeneric from '../forms/FormGeneric';
import eventMgr from '../eventMgr';
import dialog from 'dialog';
import 'cart';
import preloader from 'preloader';
import screendetector from 'screendetector';
import resources from 'resources';
import debounce from 'lodash/debounce';
import scrollUtil from 'scrollUtil';

export default class AddToCartForm extends FormGeneric {
    init () {
        super.init();
        this.quantity = this.getQuantity();
        this.$notificationBar = $('.notification-bar');
        this.$addToBagButton = this.$el.find('.product-add_to_bag');
        this.event('change', '.product-quantity', this.onQuantityChange);
        this.onChild('addToCart', 'click', this.onAddToCart);
        this.onChild('updateProduct', 'click', this.updateProduct);
        this.event('DOMSubtreeModified', '.product-alternative_payment', this.showDeliveryNote);
        this.event('DOMSubtreeModified', '.product-alternative_payment', this.showApplePayBtn);
        this.event('click', '.paypal-button', this.sendGADataPayPal);
        this.event('click', '.applepay-button', this.sendGADataApplePay);
        this.eventMgr('scroll.scroll', debounce(() => {
            this.onScroll();
        }, 300));
        this.enableApplePay();
        this.showDeliveryNote();
    }
    enableApplePay() {
        if ($('.product-swatches.size').find('.product-item ').hasClass('selected')) {
            $('.js-apple-pay-pdp-button').removeClass('no-click');
        } else {
            $('.js-apple-pay-pdp-button').addClass('no-click');
        }
    }
    sendGADataPayPal(el, e) {
        if ($('.product-swatches.size').find('.product-item ').hasClass('selected')) {
            var action = (el.id === 'PDP_paypal') ? 'PDP Payment' : 'Quick View Payment';

            window.dataLayer.push({
                hitType: 'event',
                eventCategory: 'Express checkout',
                eventAction: action,
                eventLabel: 'Pay Pal Express Checkout'
            });
        } else {
            e.preventDefault();
            var $sizeSelector = $('.product-swatches.size').closest('.form-row_select').find('.product-variations_label');

            this.chooseSizeError($sizeSelector, true);
            if (screendetector.getDeviceId() === screendetector.MOBILE) {
                setTimeout(() => {
                    scrollUtil.scrollTo($sizeSelector);
                }, 2000);
            }
            return false;
        }
    }
    sendGADataApplePay(el, e) {
        if ($('.product-swatches.size').find('.product-item ').hasClass('selected')) {
            $('.js-apple-pay-pdp-button').removeClass('no-click');
            var action = ($(el).parents('.quick-view').length > 0) ? 'Quick View Payment' : 'PDP Payment';

            window.dataLayer.push({
                hitType: 'event',
                eventCategory: 'Express checkout',
                eventAction: action,
                eventLabel: 'Apple Pay Express Checkout'
            });
        } else {
            $('.js-apple-pay-pdp-button').addClass('no-click');
            var $sizeSelector = $('.product-swatches.size').closest('.form-row_select').find('.product-variations_label');

            this.chooseSizeError($sizeSelector, true);
            if (screendetector.getDeviceId() === screendetector.MOBILE) {
                setTimeout(() => {
                    scrollUtil.scrollTo($sizeSelector);
                }, 2000);
            }
            return false;
        }
    }
    onQuantityChange (quantityItem) {
        this.quantity = quantityItem.value;
        var index = 0;

        // ORBOPT-3374 : Wishlist form to be updated with qunatity when add to cart form quantity is updated.
        while ($('.option-quantity_desired .option-value').find('.input-text').length > index) {
            $('.option-quantity_desired .option-value').find('.input-text')[index].value = quantityItem.value;
            index++;
        }
    }
    //If there is no size element (sunglasses etc.) - add to bag
    //If there is size element and size is selected - add to bag
    //If there is size element, but no size is selected - show error
    onAddToCart () {
        if ($('.product-swatches.size').length === 0 || $('.product-swatches.size').find('.product-item ').hasClass('selected')
            || $('.wishlist-wrapper').length > 0) {
            preloader.show(this.$el);
            var quantity = parseInt($('.select-selected').text().split(':')[1]);

            eventMgr.execute('cart.add', {
                pid: this.config.pid,
                Quantity: quantity,
                productName: this.config.productName
            });
            if (dialog.isOpen()) {
                dialog.close();
            }

            // if (screendetector.getDeviceId() !== screendetector.DESKTOP && screendetector.getDeviceId() !== screendetector.DESKTOP_NEW) {
            //     this.showAddToBagNotification();
            // }
        } else {
            var $sizeSelector = $('.product-variations_sizes_container');

            this.chooseSizeError($sizeSelector, true);
        }
        return false;
    }
    updateProduct () {
        eventMgr.execute('cart.update.product', {
            pid: this.config.pid,
            Quantity: this.quantity,
            productName: this.config.productName,
            uuid: this.config.uuid
        });

        return false;
    }
    getQuantity () {
        var quantity = null;

        this.getById('productQuantity', (quantityItem) => {
            quantity = quantityItem.$el.val();
        });

        return quantity || 1;
    }
    showApplePayBtn() {
        if (window.ApplePaySession && (this.$el.find('isapplepay').length === 0)) {
            if (!this.$el.find('.js-apple-pay-pdp-button').hasClass('applepay-button')) {
                this.$el.find('.js-apple-pay-pdp-button').addClass('applepay-button');
                this.$el.find('.js-apple-pay-pdp-button').removeClass('hidden');
            }
        } else {
            this.$el.find('.js-apple-pay-pdp-button').removeClass('applepay-button');
            this.$el.find('.js-apple-pay-pdp-button').addClass('hidden');
        }
    }
    showDeliveryNote() {
        if (window.ApplePaySession && (this.$el.find('isapplepay').length === 0)) {
            this.$el.find('.js-apple-pay-enabled').removeClass('hidden');
            this.$el.find('.js-apple-pay-disabled').addClass('hidden');
        } else {
            this.$el.find('.js-apple-pay-disabled').removeClass('hidden');
            this.$el.find('.js-apple-pay-enabled').addClass('hidden');
        }
    }
    showAddToBagNotification() {
        let header = $('.header-initialized'),
            headerOffset = header.offset(),
            headerHeight = header.outerHeight(),
            viewInterval = window.SitePreferences.ADD_TO_BAG_NOTIFICATION_INTERVAL;

        // Hide add to bag button and display view bag link
        //this.$addToBagButton.hide();
        //$(resources.VIEW_BAG).insertAfter(this.$addToBagButton);

        // Show notification bar
        this.$notificationBar.append(resources.ITEM_ADDED_TO_BAG_TICK + this.quantity + ' ' + resources.ITEM_ADDED_TO_BAG);
        let top = headerHeight;
        if(header.hasClass('nav-up')) {
            top = 0;
        }
        this.$notificationBar.css({'position': 'fixed', 'top': top});
        this.$notificationBar.slideDown('slow');
        

        // Trigger close button
        /* $('.notification-bar_close').on('click', () => {
            this.closeNotificationBar();
        }); */

        // Hide notification bar
        setTimeout(() => {
            this.closeNotificationBar();
        }, viewInterval);
    }
    onScroll () {
        this.closeNotificationBar();
    }
    closeNotificationBar () {
        // Remove view bag link and show add to bag button
        $('.view-bag').remove();
        this.$addToBagButton.show();
        // Hide notification bar
        this.$notificationBar.hide('slow').empty();
        this.$notificationBar.css({'position':'absolute', 'top': 'initial'});
    }
    chooseSizeError ($element, error) {
        if (error) {
            $element.attr('style','border-bottom: 2px solid #ff0032');
            $('.size-error').addClass('show-error');
            $('.product-add_to_bag').attr('style','background-color: #595959; pointer-events: none; cursor: normal');
            $('.size-error').addClass('show-error');
        } else {
            $element.removeAttr('style').find('.product-variations_size').removeClass('product-variations_disabled');
            $('.size-error').removeClass('show-error');
        }
    }
}
