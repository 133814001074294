import Grid from './Grid';
import _get from 'lodash/get';
import util from 'utils';
import eventMgr from 'eventMgr';
import $ from 'jquery';
import 'grid';

let oldValue = 0;
const tile1 = $('.js-grid .hero-li-1 .tile');
const tile2 = $('.js-grid .hero-li-2 .tile');
export default class ProductGrid extends Grid {
    init () {
        super.init();

        this.event('click', '.js-product_tile a', this.addNavigation);

        this.initIdx();
        super.appendSlot($('.primary-content'));

        $(window).on('scroll', this.checkHeroSlotInView);
        this.hideHeroIfEmpty();
    }

    checkHeroSlotInView() {
        let tileArr = [];
        var scrollDirection;
        var newValue = window.pageYOffset;
        tile1[0] ? tileArr.push(tile1) : '';
        tile2[0] ? tileArr.push(tile2) : '';

        //Define scroll direction
        if (oldValue - newValue < 0){
            scrollDirection = 'down';
        } else if (oldValue - newValue > 0){
            scrollDirection = 'up';
        }
        oldValue = newValue;

        if (tileArr.length > 0) {
            tileArr.forEach((tile) => {
                var tilePosition = $(tile)[0].getBoundingClientRect();
                if (tilePosition.top < window.innerHeight && scrollDirection === 'down') {
                    tile.addClass('in-view');
                } else {
                    tile.removeClass('in-view');
                }
            });
        }
    }

    addNavigation (el) {
        var $a = $(el);
        // get current page refinement values
        var wl = window.location,
            cgid = _get(window, 'pageContext.cgid'),
            hash = wl.hash || window.pageHash || '';

        var qsParams = (wl.search.length > 1) ? util.getQueryStringParams(wl.search.substr(1)) : {};
        var hashParams = (wl.hash.length > 1) ? util.getQueryStringParams(hash.substr(1)) : {};

        // merge hash params with querystring params
        var params = $.extend({}, hashParams, qsParams);

        if (!params.start) {
            params.start = 0;
        }
        if (cgid) {
            params.cgid = cgid;
        }
        // get the index of the selected item and save as start parameter
        var tile = $a.closest('.js-product_tile');
        var idx = tile.data('idx') ? +tile.data('idx') : 0;


        if (this.config.sorting) {
            params.srule = this.config.sorting;
        }

        // convert params.start to integer and add index
        params.start = (+params.start) + (idx + 1);
        // set the hash and allow normal action to continue
        $a[0].hash = $.param(params);
        window.pageHash = $.param(params);
    }
    initIdx () {
        this.$el.find('.js-product_tile').each(function (idx) {
            $(this).data('idx', idx);
        });
    }
    updateGrid (data) {
        super.updateGrid(data);

        var $html = $('<div>' + data + '</div>'),
            $grid = $html.find('.js-grid');

        this.config.sorting = $grid.data('sorting');
        this.initIdx();

        setTimeout(() => { eventMgr.execute('grid.refreshImages') }, 250);

    }
    appendData (data) {
        super.appendData(data);

        this.initIdx();

        setTimeout(() => { eventMgr.execute('grid.refreshImages') }, 250);
    }

    hideHeroIfEmpty() {
        $('.js-grid .hero-li-1, .js-grid .hero-li-2').each(function() {
            if ($(this).children().length === 0){
                $(this).hide();
            }
        });
    }
}

/**
 * For switching  the gridview to 2x2 and 4x4 for desktop version
 */
$('.js-two-grid').on('click', function() {
    if ($('.js-two-grid').hasClass('clicked')) {
        return;
    }
    $(this).addClass('clicked').removeClass('two-grid-disabled');
    $('.js-four-grid').removeClass('clicked').addClass('four-grid-disabled');
    updateGrid(2);
});

$('.js-four-grid').on('click', function() {
    if ($('.js-four-grid').hasClass('clicked')) {
        return;
    }
    $(this).addClass('clicked').removeClass('four-grid-disabled');
    $('.js-two-grid').removeClass('clicked').addClass('two-grid-disabled');
    updateGrid(4);
});

function updateGrid(gridCount) {
    var currenturl = location.href;
    currenturl = util.updateGridurl(currenturl, gridCount);

    eventMgr.execute('grid.getData', {
        url: currenturl,
        isPopState: true
    }).then(function() {
        var selectedOption = $('.plp-viewmode_value').text();
        util.updateViewMode(selectedOption);
    });
}