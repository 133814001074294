'use strict';
import $ from 'jquery';

var gsap = require('gsap/dist/gsap').gsap;
var ScrollTrigger = require('gsap/dist/ScrollTrigger').ScrollTrigger;

gsap.registerPlugin(ScrollTrigger);

/**
 * Function to activate parallax motion for the homepage banners using scrollTrigger
 * @param {*} container - container that holds the animated elemnent
 */

function triggerParallax(container) {
    let image = container.querySelector('img');
    let tl = gsap.timeline({
        scrollTrigger: {
            trigger: container,
            scrub: true,
            pin: false
        }
    });

    tl.from(image, {
        yPercent: -30,
        ease: 'none'
    }).to(image, {
        yPercent: 30,
        ease: 'none'
    });
}

/**
 * Function to activate the parallax motion for the videeo on the home page using scrollTrigger
 * @param {*} container - container that holds the animated elemnent
 */
function triggerVideoParallax(container) {
    let video = container.querySelector('video');
    let tl = gsap.timeline({
        scrollTrigger: {
            trigger: container,
            scrub: true,
            pin: false
        }
    });

    tl.from(video, {
        yPercent: -30,
        ease: 'none'
    }).to(video, {
        yPercent: 30,
        ease: 'none'
    });
}

gsap.utils.toArray('.js-img-animate').forEach(function(container) {
    triggerParallax(container);
});

gsap.utils.toArray('.js-fullbleed-img-animate').forEach(function(container) {
    triggerParallax(container);
});

gsap.utils.toArray('.js-video-animate').forEach(function(container) {
    triggerVideoParallax(container);
});
