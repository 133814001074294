import Component from '../Component';
import scrollUtil from 'scrollUtil';
import screendetector from 'screendetector';
import eventMgr from '../eventMgr';

const emitter = eventMgr.getEmitter('autoScroll');
export default class AutoScroll extends Component {
    init () {
        if (this.$el.hasClass('product-image_container')) {
            // Enable auto scroll to PDP images for mobile
            if (screendetector.getDeviceId() === screendetector.MOBILE) {
                this.autoScrollPDP(this.$el, true);
                emitter.emit('scrolled', false);
            }
        } else {
            setTimeout(() => {
                scrollUtil.scrollTo(this.$el);
            }, 100);
        }
    }
    autoScrollPDP (el, isPdp = false) {
        setTimeout(() => {
            if (isPdp) {
                scrollUtil.scrollTo(el, undefined, isPdp);
            }
            else {
                scrollUtil.scrollTo(el);
            }
        }, 1000);
    }
}