import VisibleForm from '../forms/VisibleForm';
import {each as $each} from 'jquery';
import scrollUtil from 'scrollUtil';

const addressMap = {
        owner: 'owner',
        type: 'type',
        number: 'number',
        month: 'expiration_month',
        year: 'expiration_year'
    },
    validateArr = [
        'expiration_month',
        'cvn'
    ];

export default class CreditCardForm extends VisibleForm {
    init () {
        super.init();

        this.numberFieldId = this.config.numberField;
        this.scrollTo = [];

        this.onChild('cardSelect', 'change', this.onChangeCard);
        this.getById('cardSelect', (cmp) => {
            if (cmp.getValue()) {
                this.onChangeCard(cmp);
            }
        });
        this.onChild(this.numberFieldId, 'change', this.onChangeNumber);

        $each(validateArr, (key, value) => {
            var $el = this.additionalValidation(value);

            if ($el) {
                this.scrollTo.push($el);
            }
        });

        if (this.scrollTo.length > 0) {
            setTimeout(() => {
                scrollUtil.scrollTo(this.scrollTo[0]);
            }, 200);
        }
    }
    additionalValidation (fieldName) {
        var result = null;

        this.getById(this.config.fieldsPrefix + fieldName, (cmp) => {
            if (cmp.config.jsonConfig.errorMsg !== '') {
                result = cmp.$el;
            }
        });
        return result;
    }
    onChangeNumber (numberField) {
        numberField.setSkipValidation(false);
    }
    onChangeCard (selector) {
        var optionConfig = selector.getSelectedOptionConfig(),
            card = optionConfig.card;

        $each(addressMap, (key, value) => {
            this.callFnForId(this.config.fieldsPrefix + value, 'setValue', card ? card[key] : '', true);
        });

        this.callFnForId(this.numberFieldId, 'setSkipValidation', true);
        this.callFnForId(this.numberFieldId, 'clearError');
    }
}
