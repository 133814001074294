import InputGeneric from './InputGeneric';

export default class InputNumber extends InputGeneric {
    init () {
        super.init();
        if ('ontouchstart' in window) {
            this.$field.prop('type', 'number');
        }
    }
}
