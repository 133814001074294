import Block from '../common/Block';
import $ from 'jquery';
import screendetector from 'screendetector';
import preloader from 'preloader';
import eventMgr from 'eventMgr';
import 'grid';

export default class DetailView extends Block {
    init () {
        super.init();
        if ($('.plp-detail-view_button').length > 0) {
            this.event('click', this.onToggle);
            this.eventMgr('screenDetector.deviceChange', this.refresh);
            this.eventMgr('grid.refresh', this.refresh);
        }
    }
    showDetailImage() {
        if (screendetector.getDeviceId() === screendetector.MOBILE ||
            screendetector.getDeviceId() === screendetector.TABLET ||
            screendetector.getDeviceId() === screendetector.TABLET_LANDSCAPE ||
            screendetector.getDeviceId() === screendetector.DESKTOP_NEW ||
            screendetector.getDeviceId() === screendetector.TABLET_NEW) {
            // Mobile && Tablets
            $('.js-product_tile .product-image').each(function() {
                let $img = $(this).find('.main-image');
                let $mobileCarousel = $(this).find('.produt-images-mobile');

                if ($mobileCarousel.hasClass('slick-initialized')) {
                    $mobileCarousel.slick('slickGoTo', $img.data('detail-image-index'), true);
                }
            });
        } else {
            // Desktop
            $('.main-image').each(function () {
                $(this).attr('data-srcset', $(this).data('detail-image')).addClass('lazyload');
                $(this).attr('data-src', $(this).data('detail-image'));
            });
        }
    }
    showMainImage() {
        if (screendetector.getDeviceId() === screendetector.MOBILE ||
            screendetector.getDeviceId() === screendetector.TABLET ||
            screendetector.getDeviceId() === screendetector.TABLET_LANDSCAPE ||
            screendetector.getDeviceId() === screendetector.DESKTOP_NEW ||
            screendetector.getDeviceId() === screendetector.TABLET_NEW) {
            // Mobile && Tablets
            $('.js-product_tile .product-image').each(function() {
                let $mobileCarousel = $(this).find('.produt-images-mobile');

                if ($mobileCarousel.hasClass('slick-initialized')) {
                    $mobileCarousel.slick('slickGoTo', 0, true);
                }
            });
        } else {
            // Desktop
            $('.main-image').each(function () {
                $(this).attr('data-srcset', $(this).data('main-image')).addClass('lazyload');
                $(this).attr('data-src', $(this).data('main-image'));
            });
        }
    }
    refresh() {
        preloader.show($('.js-grid'));

        if (this.$el.hasClass(this.config.toggleclass)) {
            this.$el.html(this.config.productview);
            this.showDetailImage();
        } else {
            this.$el.html(this.config.detailview);
            this.showMainImage();
        }

        preloader.hide();
    }
    onToggle() {
        this.$el.toggleClass(this.config.toggleclass);
        this.refresh();
    }
}
