import LoginForm from '../account/LoginForm';

export default class CheckoutLoginForm extends LoginForm {
    init () {
        super.init();
        this.onChild('dwfrm_login_havepass', 'change', this.changeHavePass);

        this.callFnForId('dwfrm_profile_login_password', 'hide');
        this.callFnForId('dwfrm_profile_login_passwordconfirm', 'hide');
        this.event('click', '.js-guest-checkout-link a', this.openGuestCheckoutTab);
        this.event('click', '.js-login-checkout-link a', this.openLoginCheckoutTab);
        this.event('click', '#radio1', this.closeLoginSection);
        this.event('click', '#radio2', this.openLoginSection);
        this.clearError();
    }
    openGuestCheckoutTab (el, event) {
        event.preventDefault();
        $(document).find('.js-guest-checkout-tab').trigger('click');
    }
    openLoginCheckoutTab (el, event) {
        event.preventDefault();
        $(document).find('.js-login-checkout-tab').trigger('click');
    }
    closeLoginSection (el, event) {
        $('.checkout-start_registered').each(function() {
            $(this).addClass('hidden');
        });

        $('.checkout-start_guest').removeClass('hidden');
    }
    openLoginSection (el, event) {
        $('.checkout-start_registered').each(function() {
            $(this).removeClass('hidden');
        });

        var registeredEmail = $('.checkout-start_registered form input[name="dwfrm_login_username');
        var guestEmail = $('.checkout-start_guest form input[name="dwfrm_login_username"]');
        if (registeredEmail.val() === '') {
            registeredEmail.val(guestEmail.val());
        }

        $('.checkout-start_guest').addClass('hidden');
    }
    clearError () {
        this.getById('errorLabel', (cmpLabel) => {
            cmpLabel.hide();
            cmpLabel.clearError();
        });
    }
    changeHavePass (havePassCmp) {
        this.clearError();
        if (havePassCmp.getValue() === 'yes') {
            this.callFnForId('dwfrm_profile_login_password', 'hide');
            this.callFnForId('dwfrm_profile_login_passwordconfirm', 'hide');
            this.getById('btnGuest', (cmp) => {
                cmp.hide();
                cmp.disable();
            });
            this.callFnForId('dwfrm_login_password', 'show');
            this.getById('btnLogin', (cmp) => {
                cmp.show();
                cmp.enable();
            });
        } else {
            this.callFnForId('dwfrm_login_password', 'hide');
            this.getById('btnLogin', (cmp) => {
                cmp.hide();
                cmp.disable();
            });
            this.callFnForId('dwfrm_profile_login_password', 'show');
            this.callFnForId('dwfrm_profile_login_passwordconfirm', 'show');
            this.getById('btnGuest', (cmp) => {
                cmp.show();
                cmp.enable();
            });
        }
    }
}
