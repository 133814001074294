import Component from '../Component';
import screendetector from 'screendetector';
import eventMgr from 'eventMgr';

const emitter = eventMgr.getEmitter('hamburgermenu'),
    OPENED_CLASS = 'header-hamburger_icon--opened',
    NEEDTOMOVE = 30;

export default class HamburgerMenu extends Component {
    init () {
        super.init();
        this.event('click', this.clickEvent);
        this.eventMgr('body.touchend', this.touchMoved);
        this.eventMgr('headermenu.closemenu', this.clickEvent);
        this.eventMgr('screenDetector.deviceChange', (device) => {
            if (device !== screendetector.MOBILE) {
                this.closeMenu();
            }
        });
    }
    openMenu () {
        this.$el.attr('aria-expanded', 'false');
        this.$el.addClass(OPENED_CLASS);
        emitter.emit('open');
    }
    closeMenu () {
        this.$el.attr('aria-expanded', 'true');
        this.$el.removeClass(OPENED_CLASS);
        emitter.emit('close');
    }
    clickEvent () {
        if (this.$el.attr('aria-expanded') === 'false') {
            this.closeMenu();
        } else {
            this.openMenu();
        }
    }
    touchMoved (event) {
        if (screendetector.getDeviceId() !== screendetector.MOBILE) {
            return false;
        }

        if (event.length > NEEDTOMOVE) {
            if (event.direction === 'left') {
                this.closeMenu();
            } else {
                this.openMenu();
            }
        }
    }
}
