import Form from '../forms/FormGeneric';
import prefs from 'prefs';
import $ from 'jquery';

var cardIcons = '';

export default class PaymentForm extends Form {
    init () {
        super.init();

        this.isOneClick = false;

        this.showApplePayPaymentMethod();

        if (this.$el.find('.js-new-billing-address-only').length > 0) {
            this.onEnterNewBillingAddress();
        }

        if (this.$el.find('.js-payment-method:checked').length > 0) {
            this.onChangePaymentMethod();
        } else {
            this.event('change', '.js-payment-method', this.onChangePaymentMethod);
        }

        this.$el.find('.adyen-checkout__input:required').removeAttr('required');

        this.event('change', '.js-new-billing-address', this.onEnterNewBillingAddress);
        this.event('click', '.js-enable-paypal-ga', this.sendGADataPayPal);
        this.event('click', '.dw-apple-pay-button', this.sendGADataApplePay);
        this.event('click', '.js-enable-klarna', this.addToBrowserHistory);
        this.event('change', '#adyenCreditCardList', this.onChangeAdyenCreditCardList);

        var form = this.$el;

        if (prefs.RECAPTCHA_ENABLE && window.grecaptcha) {
            grecaptcha.ready(function() {
                grecaptcha.execute(prefs.RECAPTCHA_SITE_KEY).then(function(token) {
                    $('<input>').attr({
                        type: 'hidden',
                        name: 'g-recaptcha-response',
                        value: token
                    }).appendTo(form);
                });
            });
        }
    }

    sendGADataPayPal() {
        window.dataLayer.push({
            hitType: 'event',
            eventCategory: 'Checkout',
            eventAction: 'Payment Method',
            eventLabel: 'Pay Pal'
        });
    }

    sendGADataApplePay() {
        window.dataLayer.push({
            hitType: 'event',
            eventCategory: 'Checkout',
            eventAction: 'Payment Method',
            eventLabel: 'Apple Pay'
        });
    }

    sendGADataKlarna() {
        window.dataLayer.push({
            hitType: 'event',
            eventCategory: 'Checkout',
            eventAction: 'Payment Method',
            eventLabel: 'Klarna'
        });
    }

    addToBrowserHistory() {
        var BackToCheckout = $('#adyenBacktoCheckoutUrl').val();
        window.history.pushState({state:1}, "State 1", BackToCheckout);
        this.sendGADataKlarna();
    }

    showApplePayPaymentMethod() {
        if (window.ApplePaySession) {
            this.$el.find('.js-applepay-method').removeClass('hidden');
        }
    }

    onEnterNewBillingAddress (element) {
            this.getById('addressSelect', (cmp) => {
                if (cmp.getOptionsCount() > 1) {
                    cmp.setValue(cmp.$field.find('option:eq(1)').prop('value'));
                }
            });
    }

    onChangePaymentMethod () {
        var paymentMethodId;
        var $paymentMethodSelected = this.$el.find('.js-payment-method:checked');
        if ($paymentMethodSelected.length > 0) {
            paymentMethodId = $paymentMethodSelected.val().toLowerCase();

            var $billingForm = $('#js-billing-address-form');
            var $selectedTab = this.$el.find('#payment-tab-'+paymentMethodId);
            if ($billingForm.length > 0 && $selectedTab.length > 0) {
                $selectedTab.find('.js-billing-form').append($billingForm);
            }
        }

        if (this.$el.find('#is-DW_APPLE_PAY').prop('checked')) {
            this.$el.find('.js-applepay-btn').removeClass('hidden');
            this.getById(this.config.submitBtnId, (cmp) => {
                cmp.hide();
            });
        } else {
            this.$el.find('.js-applepay-btn').addClass('hidden');
            this.getById(this.config.submitBtnId, (cmp) => {
                cmp.show();
            });
        }

        if (this.$el.find('#is-PayPal').prop('checked')) {
            this.$el.find('.checkout-billing_next').addClass('js-enable-paypal-ga');
        } else {
            this.$el.find('.checkout-billing_next').removeClass('js-enable-paypal-ga');
        }

        //show/hide surcharge
        if (this.$el.find('#is-CASH_ON_DELIVERY').length) {
            if (this.$el.find('#is-CASH_ON_DELIVERY').prop('checked')) {
                $('.js-billing-cash-on-delivery-surcharge').removeClass('hidden');

                //show order total with surcharge
                $('.js-order-total').addClass('hidden');
                $('.js-order-total-with-cash-on-delivery').removeClass('hidden');

                //change btn price
                $('.js-btn-total-price').addClass('hidden');
                $('.js-btn-total-price-with-cod').removeClass('hidden');
            } else {
                $('.js-billing-cash-on-delivery-surcharge').addClass('hidden');

                //hide order total with surcharge
                $('.js-order-total-with-cash-on-delivery').addClass('hidden');
                $('.js-order-total').removeClass('hidden');

                //change btn price
                $('.js-btn-total-price-with-cod').addClass('hidden');
                $('.js-btn-total-price').removeClass('hidden');
            }
        }

        //  add/remove klarna add url to browser history
        if (this.$el.find("[id^='is-klarna']:checked").length) {
            this.$el.find('.checkout-billing_next').addClass('js-enable-klarna');
        } else {
            this.$el.find('.checkout-billing_next').removeClass('js-enable-klarna');
        }

        // Removing required fields of adyen credit card form to submit payment for other payment methods
        if (!this.$el.find('#is-CREDIT_CARD').prop('checked')) {
            this.$el.find('.adyen-checkout__input:required').removeAttr('required');
        } else {
            this.$el.find('.adyen-checkout__card__holderName__input').prop('required',true);
        }
    }

    beforeSubmit () {
        var result = true;

        var $paymentMethodSelected = this.$el.find('.js-payment-method:checked');
        var paymentMethodId = $paymentMethodSelected.val().toLowerCase();

        if (this.$el.find('#is-CREDIT_CARD').prop('checked')) {

            if (!window.CardValid) {
                window.AdyenCard.showValidation();
                return false;
            }

            this.clearCardData();
            var oneClickCard = window.AdyenOneClick;

            if (this.isOneClick) {
                $('#dwfrm_billing_paymentMethods_creditCard_selectedCardID').val($('#adyenCreditCardList option:selected').attr('id'));
                $('#dwfrm_billing_paymentMethods_creditCard_type').val($('#adyenCreditCardList option:selected').val());
                $('#dwfrm_billing_paymentMethods_creditCard_adyenEncryptedSecurityCode').val(oneClickCard.state.data.encryptedSecurityCode);
            } else {
                $('#dwfrm_billing_paymentMethods_creditCard_selectedCardID').val("");
                this.copyCardData(window.AdyenCard);
            }
        }

        // validate address form from current payment tab
        var $paymentMethodSelected = this.$el.find('.js-payment-method:checked');
        if ($paymentMethodSelected.length > 0) {
             var paymentMethodId = $paymentMethodSelected.val().toLowerCase();
            var $selectedTab = this.$el.find('#payment-tab-' + paymentMethodId);
            var $addressForm = $selectedTab.find('.js-billing-form');

            if ($addressForm.length > 0) {
                this.getById('billingAddressForm', (addressForm) => {
                    result = addressForm.isChildsValid();
                });

                if (!result) {
                    result = ($("[id$='delivery']").prop('checked'));
                }
            }
        }

        return result;
    }

    onSubmit () {
        return this.beforeSubmit();
    }

    copyCardData (card) {
        $('#dwfrm_billing_paymentMethods_creditCard_type').val(card.state.brand);
        $('#dwfrm_billing_paymentMethods_creditCard_adyenEncryptedCardNumber').val(card.state.data.encryptedCardNumber);
        $('#dwfrm_billing_paymentMethods_creditCard_adyenEncryptedExpiryMonth').val(card.state.data.encryptedExpiryMonth);
        $('#dwfrm_billing_paymentMethods_creditCard_adyenEncryptedExpiryYear').val(card.state.data.encryptedExpiryYear);
        $('#dwfrm_billing_paymentMethods_creditCard_adyenEncryptedSecurityCode').val(card.state.data.encryptedSecurityCode);
        $('#dwfrm_billing_paymentMethods_creditCard_owner').val(card.state.data.holderName);
        if (window.storeDetails) {
            $('#dwfrm_billing_paymentMethods_creditCard_saveCard').val(window.storeDetails);
        } else {
            $('#dwfrm_billing_paymentMethods_creditCard_saveCard').val(false);
        }
    }

    clearCardData () {
        $('#dwfrm_billing_paymentMethods_creditCard_type').val("");
        $('#dwfrm_billing_paymentMethods_creditCard_adyenEncryptedCardNumber').val("");
        $('#dwfrm_billing_paymentMethods_creditCard_adyenEncryptedExpiryMonth').val("");
        $('#dwfrm_billing_paymentMethods_creditCard_adyenEncryptedExpiryYear').val("");
        $('#dwfrm_billing_paymentMethods_creditCard_adyenEncryptedSecurityCode').val("");
        $('#dwfrm_billing_paymentMethods_creditCard_owner').val("");
    }

    async onChangeAdyenCreditCardList () {
        var selectedCard = $('#adyenCreditCardList').val();
        var AdyenCheckoutObject = await new AdyenCheckout(window.Configuration);
        if(window.AdyenOneClick) {
            window.AdyenOneClick.unmount();
        }

        if (!cardIcons) {
            var $cardIconsTooltip = $('#card .form-row_tooltip');

            // if it's missing from the new card, check the saved card wrapper
            if ($cardIconsTooltip.length == 0) {
                $cardIconsTooltip = $('#oneClickCard .form-row_tooltip');
            }

            if ($cardIconsTooltip.length > 0) {
                cardIcons = $cardIconsTooltip[0].innerHTML;
            }
        }

        this.initializeOneClick(AdyenCheckoutObject, selectedCard, cardIcons);

        window.CardValid = false;
        if (selectedCard !== "") {
            this.isOneClick = true;
            $("#selectedCard").slideDown("slow");
            $("#newCard").slideUp("slow");
            if(window.AdyenCard){
                window.AdyenCard.unmount();
            }
        }
        else {
            this.isOneClick = false;
            initializeCardComponent();
            $("#selectedCard").slideUp("slow");
            $("#newCard").slideDown("slow");
        }
    }

    initializeOneClick(AdyenCheckoutObject, selectedCard, cardIcons) {
        var paymentMethodsResponse = AdyenCheckoutObject.paymentMethodsResponse;
        var selectedPaymentMethod = paymentMethodsResponse.storedPaymentMethods.find(paymentMethod => paymentMethod.id === selectedCard);
        if (selectedPaymentMethod) {
            var cardNode = document.getElementById('oneClickCard');
            var nodeData = Object.assign(selectedPaymentMethod, {
                onChange: function (state) {
                    // state.isValid = true;
                    $('#dwfrm_billing_paymentMethods_creditCard_browserInfo').val(JSON.stringify(state.data.browserInfo));
                    window.CardValid = state.isValid;
                    document.querySelector('#adyenStateData').value = JSON.stringify(state.data);
                },
                onLoad: function() {
                    var tooltip = '<div class="form-row_tooltip tooltip tooltip_oneClick">'+ cardIcons +'</div>';
                    $('#oneClickCard .adyen-checkout__field__cvc').after(tooltip);
                }
            })
            window.AdyenOneClick = AdyenCheckoutObject.create('card', nodeData);
            window.AdyenOneClick.mount(cardNode);
        }
    }
}
