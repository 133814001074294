'use strict';

import logger from 'browser-console';
import EventEmitter from 'events';
import {Promise as Promise} from 'es6-promise-polyfill';
import $ from 'jquery';

var emitter = new EventEmitter(),
    emitters = [],
    allowedAjax = 0,
    actions = {};

const originalAjax = $.ajax;

$.ajax = function () {
    if (!allowedAjax) {
        logger.warn('Illegal using ajax');
    }
    return originalAjax.apply(this, arguments);
};


emitter.setMaxListeners(3000);

const eventMgr = {
    getEmitter: (namespace) => {
        if (emitters.indexOf(namespace) > -1) {
            logger.log('EventMgr: Given namespace "' + namespace +
                '" already exists. Event emitter creation failed.');
            return {
                emit: () => false
            };
        }
        emitters.push(namespace);
        return {
            emit: function (eventName) {
                try {
                    arguments[0] = namespace + '.' + eventName;
                    return emitter.emit.apply(emitter, arguments);
                } catch (e) {
                    logger.error(e);
                }
            }
        };
    },
    on: (eventName, cb) => emitter.on(eventName, cb),
    off: (eventName, cb) => emitter.removeListener(eventName, cb),
    once: (eventName, cb) => emitter.once(eventName, cb),
    execute: (actionName, params) => {
        if (actions[actionName]) {
            allowedAjax++;
            return actions[actionName](params).then((data) => {
                allowedAjax--;
                return data;
            }, (reason) => {
                allowedAjax--;
                return new Promise((res, rej) => {
                    rej(reason);
                });
            });
        } else {
            return new Promise((resolve, reject) => {
                reject(new Error(`Action is not found for "${actionName}"`));
            });
        }
    },
    registerAction: (actionName, handler) => {
        if (actions[actionName]) {
            logger.warn(`Overriding "${actionName}"!`);
        }
        actions[actionName] = handler;
    }
};

// make it global
window.eventMgr = eventMgr;

export default eventMgr;
