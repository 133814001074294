import Component from '../Component';
import scrollUtil from 'scrollUtil';

export default class BackToTop extends Component {
    init () {
        super.init();
        this.event('click', this.scrollToTop);
    }
    scrollToTop (el, e) {
        e.preventDefault();
        scrollUtil.scrollTo(0);
    }
}
