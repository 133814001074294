import Block from './Block';
import eventMgr from 'eventMgr';

const emitter = eventMgr.getEmitter('FooterSticky') ,
    STICKY_OFFSET = 300;

export default class FooterSticky extends Block {
    init () {
        super.init();
        this.isSticky = false;
        this.eventMgr('Header.isSticky', this.setSticky);
        this.eventMgr('scroll.scroll', this.onScroll);
    }

    setSticky (sticky = false) {
        if (sticky) {
            this.show();
            this.isSticky = true;
        } else {
            this.hide();
            this.isSticky = false;
        }
        emitter.emit('isSticky', this.isSticky);
    }
    onScroll (data) {
        if (data.scrollTop >= STICKY_OFFSET && !this.isSticky) {
            this.setSticky(true);
        } else if (data.scrollTop < STICKY_OFFSET && this.isSticky) {
            this.setSticky(false);
        }

    }
}
