import Block from '../common/Block';
import $ from 'jquery';
import eventMgr from 'eventMgr';
import 'grid';
import preloader from 'preloader';
import util from 'utils';

export default class LoadmoreBtn extends Block {
    init () {
        super.init();

        this.event('click', this.onButtonClick);
        this.eventMgr('grid.updated', this.refresh);
        this.eventMgr('grid.gotData', this.refresh);
        this.eventMgr('Refinements.clicked', this.refresh);
    }

    onButtonClick (el, ev) {
        ev.preventDefault();
        var originUrl = $(el).attr('href');
        originUrl = util.updateGridurl(originUrl);
        var newURL = new URL(originUrl);

        //if this.id==='loadMoreButtonBlog' will skip the checks and make ajax from here
        if (this.id === 'loadMoreButtonBlog') {
            eventMgr.execute('grid.getMoreItemsButton', {
                url: newURL.toString()
            });
            window.history.pushState({pagination: true}, '', originUrl);
            return;
        }

        var hiddenItems = $('.search-result_item.hidden').length;
        var lastProduct = $('.search-result_item').hasClass('last-product');
        if(lastProduct) {
            return false;
        }

        if ($('.loading-wrapper').hasClass('hidden')) { 
            var start = newURL.searchParams.get('start');
            if (hiddenItems) {
                var sz = newURL.searchParams.get('sz');
                newURL.searchParams.set('sz',sz - hiddenItems);
            }
            var selectedOption = $('.plp-viewmode_list--mobile').find(":selected").attr('data-value');
            newURL.searchParams.append('isajax', 'true');
            eventMgr.execute('grid.getMoreItemsButton', {
                url: newURL.toString()
            }).then (function() {
                util.updateViewMode(selectedOption, start);
            })
            window.history.pushState({pagination: true}, '', originUrl);
        }

        var $loadingWrapper = $('.loading-wrapper');
        var lastProduct = $('.search-result_item').hasClass('last-product');
        if (!lastProduct) {
            $loadingWrapper.removeClass('hidden');
            this.hide();
        }
    }

    refresh (data) {
        var $html = $('<div>' + data + '</div>'),
            $loadmore = $html.find('.js-loadmorebtn');

        if (this.id === 'loadMoreButtonBlog' && $loadmore.length === 0) {
            $('[data-cmp-id="loadMoreButtonBlog"]').remove();
        }
        if ($loadmore.length > 0){
            this.$el.attr('href', $loadmore.attr('href')).html($loadmore.html());
        }

        var lastProduct = $('.search-result_item').hasClass('last-product');
        if (!lastProduct) {
            this.$el.removeClass('hidden');
        } else {
            this.$el.addClass('hidden');
        }
    }
}
