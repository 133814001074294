import Button from '../forms/Button';
import dialog from 'dialog';
import page from 'pageUtils';

export default class ConfirmButton extends Button {
    init () {
        super.init();
        this.confirmed = false;
    }
    onClick (el, event) {
        if (this.confirmed) {
            this.emitter.emit('click', this);
            this.confirmed = false;
        } else {
            event.preventDefault();
            var isDialogOpened = dialog.isOpen(),
                currentDialog;

            if (isDialogOpened) {
                currentDialog = dialog.getCurrent();
            }
            dialog.open({
                content: this.config.content,
                options: {
                    title: this.config.title,
                    dialogClass: 'ui-dialog_small ui-dialog_confirm',
                    close: () => {
                        if (isDialogOpened) {
                            dialog.restore(currentDialog);
                        }
                    },
                    buttons: [{
                        text: this.config.okText,
                        'class': 'button button-regular',
                        click: () => {
                            if (this.$el.attr('href')) {
                                page.redirect(this.$el.attr('href'));
                            } else {
                                this.confirmed = true;
                                this.$el.trigger('click');
                            }

                        }
                    }, {
                        text: this.config.cancelText,
                        'class': 'button button-regular',
                        click: () => {
                            dialog.restore(currentDialog);
                        }
                    }]
                }
            });
        }
    }
}
