import Block from '../common/Block';
import eventMgr from 'eventMgr';
import $ from 'jquery';

export default class WelcomeBackWidget extends Block {
    init () {
        super.init();

        this.attachEvents();
        this.isDesktopView = $(window).width() > this.tabletBreakpointEnd;

        //Check on each half second if there are any recommendations received
        function checkRecommenderSlider() {
            window.timeoutId = setTimeout(() => {
                //Show pop-up only when sliders are fully loaded to not have visual rearrangements
                if ($('.welcomeback_carousel .tiles-container.slick-initialized').length > 0 ||
                    $('.welcomeback_carousel .tiles-container').length < 0) {

                    $('.welcomeback').css('opacity', 1);
                }

                if ($('.welcomeback-empty .vertical-carousel').length > 0) {
                    $('.welcomeback-empty').css({'display':'flex', 'opacity': 1});
                }

                checkRecommenderSlider();
            }, 500);
        }

        checkRecommenderSlider();

        //After 5s stop waiting for recommendations, hide carousel and show simple welcomebag popup
        setTimeout(() => {
            clearTimeout(window.timeoutId);
            if ($('.welcomeback_carousel .tiles-container').length > 0 || $('.welcomeback.full-basket').length > 0) {
                $('.welcomeback').css('opacity', 1);
            }
        }, 5000);
    }

    attachEvents() {
        this.event('click', this.$el, (el, ev) => this.closeModal(ev));
        this.event('click', '.wb-close', (el, ev) => this.closeModal(ev));
        this.event('click', '.wb-continueshopping', (el, ev) => this.closeModal(ev))
        eventMgr.on('cart.product.added.welcomeback', this.addedToCart.bind(this));
    }

    closeModal(ev) {
        if ($(ev.target).hasClass('welcomeback') || $(ev.target).hasClass('wb-close') || $(ev.target).hasClass('wb-continueshopping')) {
            ev.preventDefault();
            this.$el.fadeOut();
        } else {
            return;
        }
    }

    addedToCart(params) {
        this.$el.fadeOut();
    }
}
