'use strict';

import ajax from 'ajax';
import eventMgr from 'eventMgr';
import util from 'utils';

const emitter = eventMgr.getEmitter('grid');

const getData = function (params) {
    var url = params.url,
        isPopState = params.isPopState,
        isReplaceStateForMobile = params.isReplaceStateForMobile,
        refId = params.refId;

    emitter.emit('ajax.start');

    return ajax.load({
        url: url
    }).then((data) => {
        if (!isPopState) {
            //This piece of code for cases when refinements are added via mobile and they are added to query string
            //and after reloading of page the refinements are rendered in url's path
            if (isReplaceStateForMobile && util.isMobile()) {
                var dataURL = $(data).find('.js-last-breadcrumbs-link').attr('href');
                history.pushState(null, null, (dataURL || url));
            } else {
                history.pushState(null, null, url);
            }
        }
        if (refId) {
            emitter.emit(`updated.${refId}`, data, params.openRefinements);
        }
        
        emitter.emit('updated', data, params.openRefinements);
        
        return data;
    });
};

const getMoreData = (data) => {
    emitter.emit('ajax.start');

    return ajax.load({
        url: data.url
    }).then((data) => {
        emitter.emit('gotData', data);
        return data;
    }).then(()=> {
        if ($('.loading-wrapper').length > 0) {
            $('.loading-wrapper').addClass('hidden');
        }
    });
};

/**
 * This function is added because I didn't want to rewrithe the getMoreData function
 * The purpose is to not hide the loader when data came from ajax call, the loader
 * will be hided once the data is displayed, for better user expirience
 * @param {*string} data 
 * @returns string
 */
const getMoreDataButton = (data) => {
    emitter.emit('ajax.startButton');

    return ajax.load({
        url: data.url
    }).then((data) => {
        emitter.emit('gotData', data);
        return data;
    });
};

const gridRefresh = () => {
    emitter.emit('refresh');
    return new Promise((res) => {
        res();
    });
};

eventMgr.registerAction('grid.getMoreItems', getMoreData);
eventMgr.registerAction('grid.getMoreItemsButton', getMoreDataButton);
eventMgr.registerAction('grid.getData', getData);
eventMgr.registerAction('grid.refreshImages', gridRefresh);
