import Form from '../forms/Form';
import eventMgr from 'eventMgr';
import {each as $each} from 'jquery';

const addressMap = {
    country: 'countryCode',
    states_state: 'stateCode',
    postal: 'postalCode',
    city: 'city',
    address1: 'address1',
    address2: 'address2'
};

export default class ShippingForm extends Form {
    init () {
        super.init();
        this.isButtonShow = false;
        this.fieldsPrefix = this.config.fieldsPrefix || '';
        this.deliveryMethodId = this.config.deliveryMethod;
        this.onChild(this.deliveryMethodId, 'change', this.onChangeDeliverymethod);
        this.getById(this.deliveryMethodId, (cmp) => {
            if (cmp.getValue && cmp.getValue()) {
                this.onChangeDeliverymethod(cmp);
            }
        });

        this.event('change', '.js-shipping_method', this.onChangeShippingMethod);
        this.event('click', '[data-cmp-id="saveStoreButton"]', this.onSaveStore);

        this.onChild(this.config.clickAndCollectBlock, 'store.change', this.onStoreChange);
    }
    onChangeDeliverymethod (cmp) {
        if (!this.isButtonShow) {
            this.callFnForId('saveButton', 'show');
            this.isButtonShow = true;
        }
        this.callFnForId(this.config.addressBookField, 'setValue', true);
        this.callFnForId(this.config.useAsBillingAddress, 'setValue', true);
        this.callFnForId('homeDeliveryForm', 'setSkipValidationForFields', false);
        if (cmp.getValue() === 'clickandcollect') {
            this.changeShippingMethod(this.config.deliveryShippingMethod);
            this.callFnForId('saveButton', 'hide');
            this.getById(this.config.clickAndCollectBlock, (cmp) => {
                cmp.checkSelectedStoreId().then(() => {
                    this.callFnForId('saveStoreButton', 'show');
                });
            });
        } else {
            this.callFnForId('saveStoreButton', 'hide');
            this.callFnForId('saveButton', 'show');
        }
    }
    onSaveStore (el, ev, options) {
        this.callFnForId('deliveryShippingMethod', 'setValue', this.config.deliveryShippingMethod);
        this.callFnForId(this.config.addressBookField, 'setValue', '');
        this.callFnForId(this.config.useAsBillingAddress, 'setValue', '');
        this.callFnForId('homeDeliveryForm', 'setSkipValidationForFields', true);
        this.callFnForId('homeDeliveryForm', 'clearFields');
    }
    onChangeShippingMethod (el) {
        this.changeShippingMethod(el.value);
    }
    changeShippingMethod (methodID) {
        if (!methodID) {
            return;
        }
        var address = {
            shippingMethodID: methodID
        };

        $each(addressMap, (key) => {
            this.getById(this.fieldsPrefix + key, (childCmp) => {
                if (childCmp.getValue && childCmp.getValue()) {
                    address[addressMap[key]] = childCmp.getValue();
                }
            });
        });
        eventMgr.execute('checkout.shippingmethod.set', address);
    }
    onStoreChange (storeId) {
        if (storeId) {
            this.callFnForId('saveStoreButton', 'show');
        } else {
            this.callFnForId('saveStoreButton', 'hide');
        }
    }
}
