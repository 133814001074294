import Component from '../Component';
import eventMgr from '../eventMgr';
import dialog from 'dialog';
import $ from 'jquery';
import util from 'utils';

var emitter = eventMgr.getEmitter('DialogLink');

export default class DialogLink extends Component {
    init () {
        super.init();
        this.event('click', this.onClick);
    }
    onClick (el, event) {
        event.preventDefault();
        this.emitter.emit('click', this);
        var dialogUrl = $(el).attr('href') || $(el).attr('data-href'),
            options = this.config,
            isDialogOpened = dialog.isOpen(),
            dialogParams = {
                modal: true
            },
            currentDialog;

        const closeHandler = () => {
            if (isDialogOpened) {
                dialog.restore(currentDialog);
            } else {
                dialog.close();
            }

            if ($('.wrapper').length > 0) {
                $('.wrapper').attr('aria-hidden', false);
            }
        };

        if (!options.title && this.$el.attr('title')) {
            options.title = this.$el.attr('title');
        }

        if (this.config.bottomCloseButton) {
            options.buttons = options.buttons || [];
            options.buttons.push({
                text: this.config.bottomCloseButton,
                'class': 'ui-dialog-titlebar-close ui-dialog-bottom-close',
                click: closeHandler
            });
        }

        if (this.config.topBackButton) {
            options.buttons = [];
            options.buttons.push({
                text: this.config.topBackButton,
                'class': 'ui-dialog-titlebar-back ui-dialog-top-back js-back-button',
                click: closeHandler
            });
        }

        if (isDialogOpened) {
            currentDialog = dialog.getCurrent();
            dialogParams.target = 'second-dialog';
        }

        if ('source' in this.config) {
            dialogUrl = util.appendParamToURL(dialogUrl, 'source', this.config.source);
        }

        if ('cgid' in this.config) {
            dialogUrl = util.appendParamToURL(dialogUrl, 'cgid', this.config.cgid);
        }

        /* start is a dynamic param needed for quickview prev/next navigation */
        if ('start' in this.config && this.config.start === 'productnav') {
            dialogUrl = util.appendParamToURL(dialogUrl, 'start', util.getTilePosition(el));
        }

        if (dialogUrl) {
            dialogParams.url = dialogUrl;
        }

        if (this.config.iframe) {
            var $content = $('<div/>').append($('<iframe />').attr({
                'src': dialogParams.url,
                'class': 'ui-dialog_iframe'
            }));

            delete dialogParams.url;
            dialogParams.html = $content.html();
        }

        options = $.extend({}, options || {}, {
            open: () => {
                emitter.emit('open', this.config.source);
                util.updateGTM(true);

                if ($('.ui-dialog-titlebar-close').length > 0) {
                    $('.ui-dialog-titlebar').find('.ui-dialog-titlebar-close').focus();
                }
            },
            close: closeHandler
        });

        dialogParams.options = options;
        dialog.open(dialogParams);

        if ($('.wrapper').length > 0) {
            $('.wrapper').attr('aria-hidden', true);
        }
    }
}
