import Component from '../Component';
import util from 'utils';
import $ from 'jquery';

export default class WelcomeBackCookie extends Component {
    init () {
        super.init();
        /**
         * function to create the cookie for not showing welcomeBack popup again in a session
         * Welcome Back Popup will show only when both 'welcomeBackInitialVisit' and 'welcomeBackVisited' is not existing
         * 'welcomeBackInitialVisit' is to identify whether the user is visiting for the first time
         * only the returnback customer should show the welcomeback popup
         */
        function createWelcomeBackCookie() {
            var date = new Date();
            var welcomeBackAppearPeriod = parseInt($('.js-welcome-back-period').attr('data-welcome-back-appear-period'), 10);

            if (!util.getCookie('welcomeBackInitialVisit')) {
                util.setCookie('welcomeBackInitialVisit', date.toGMTString(), welcomeBackAppearPeriod);
            }

            if (util.getCookie('welcomeBackVisited')) {
                util.setCookie('welcomeBackInitialVisit', date.toGMTString(), welcomeBackAppearPeriod);
            }
        }
        createWelcomeBackCookie();
    }
}
