import Link from './Link';
import eventMgr from 'eventMgr';
import screendetector from 'screendetector';
import scrollUtil from 'scrollUtil';

const emitter = eventMgr.getEmitter('MenuLink'),
    OPENED = 'header-nav_item--opened',
    HOVER_CLASS = 'hover',
    CLOSE_DELAY = 350;

var isMenuOpened = false;
var isActiveMenuItem = false;
var lastHoveredItem = null;
var lastFocusItem = null;

export default class MenuLink extends Link {
    init () {
        super.init();
        this.hasTouch = 'ontouchstart' in window;
        this.clickCount = 0;
        this.timerOpenId = null;
        this.timerCloseId = null;
        this.link = this.getHref();
        this.hasFlyout = this.$el.find(this.config.flyout).length > 0;

        this.eventMgr('MenuLink.open', this.onOtherMenuOpen);

        this.event('click', this.$el, this.onClick);

        if (!this.link || this.config.link) {
            this.link = this.$el.find(this.config.link).attr('href');
        }

        this.event('mouseenter', this.openFlyout);
        this.event('mouseleave', this.openFlyout);
        this.event('focusin', this.openFlyout);
        this.event('focusout', this.openFlyout);
    }
    clearCount () {
        this.clickCount = 0;
    }
    onClick (el, event) {
        //Check for desktop device as per the new redesign requirements
        this.isDesktop = window.innerWidth > 1366;

        if (!this.hasFlyout || this.isDesktop || (!$(event.target).hasClass('header-nav_link--level_1') && !$(event.target).hasClass('header-nav_item'))) {
            return;
        } else if($(event.target).hasClass('header-nav_link--level_1')) {
            if(this.$el.hasClass(OPENED)){
                return;
            }
            event.preventDefault();
        }

        event.preventDefault();
        scrollUtil.scrollTo(this.$el);

        if (this.hasFlyout) {
            let $navFlyout = this.$el.find('.header-nav_flyout');
            if(this.$el.hasClass(OPENED) && $(event.target).hasClass(OPENED)) {
                emitter.emit('close');
                isMenuOpened = false;
                isActiveMenuItem = false;
                this.$el.removeClass(OPENED);
                $navFlyout.attr('aria-hidden', 'true');
            } else {
                emitter.emit('open', this.$el);
                isMenuOpened = true;
                isActiveMenuItem = true;
                this.$el.addClass(OPENED);
                $navFlyout.attr('aria-hidden', 'false');
            }
        }
    }
    onOtherMenuOpen ($el) {
        if (!this.$el.is($el)) {
            if (this.timerOpenId) {
                clearTimeout(this.timerOpenId);
            }

            this.$el.removeClass(OPENED).removeClass(HOVER_CLASS);
            this.$el.find('.header-nav_flyout').attr('aria-hidden', 'true');
            isActiveMenuItem = false;
            this.clearCount();
        }
    }
    openFlyout (el, event) {
        if (screendetector.getDeviceId() !== screendetector.MOBILE) {
            if ((event.type === 'mouseenter' || event.type === 'focusin') && this.hasFlyout) {
                isMenuOpened = true;
                lastHoveredItem = $(el);
                lastFocusItem = $(el);
                this.$el.find('.header-nav_flyout').attr('aria-hidden', 'false');

                if (!isActiveMenuItem) {
                    isMenuOpened = true;
                    isActiveMenuItem = true;
                    emitter.emit('open', this.$el);
                    this.$el.addClass(HOVER_CLASS);
                } else if (this.$el.is($(el))) {
                    if (this.timerOpenId) {
                        clearTimeout(this.timerOpenId);
                    }
                }
            } else {
                isMenuOpened = false;

                this.timerOpenId = setTimeout(() => {
                    if (!isMenuOpened) {
                        emitter.emit('close');
                        lastHoveredItem = null;
                        lastFocusItem = null;
                    }
                    isActiveMenuItem = false;
                    this.$el.removeClass(HOVER_CLASS);
                    this.$el.find('.header-nav_flyout').attr('aria-hidden', 'true');
                    this.timerOpenId = null;
                    $(window).trigger('scroll');
                    if (null !== lastHoveredItem)
                        lastHoveredItem.trigger('mouseenter');
                    if (null !== lastFocusItem)
                        lastFocusItem.trigger('focusin');
                }, CLOSE_DELAY);
            }
        }
    }
}
