import Block from '../common/Block';
import preloader from 'preloader';
import eventMgr from 'eventMgr';
import $ from 'jquery';
import 'product';
import scrollUtil from 'scrollUtil';
import screendetector from '../screendetector';


export default class ProductRecommendation extends Block {
    init () {
        super.init();
        
        this.swatchColorInit();
    }
    update (html) {
        
    }
    swatchColorInit() {
    	$('a.js-tile-color-swatch').off('click');
    	$('body').on('click', 'a.js-tile-color-swatch', function(e){
    		
    		var productTile = $(this).closest('.js-product_tile');
    		
    		$(productTile).find('.product-image > .product-link > img').attr('src', $(this).data('firstimage')).attr('srcset', '');
    		
    		$(productTile).find('.product-image_hover > img').attr('src', $(this).data('secondimage')).attr('srcset', '');
    		
    		$(productTile).find('a.js-tile-color-swatch').removeClass('selected');
    		$(this).addClass('selected');
    		
    		var pdpLink = $(this).data('href');
    		$(productTile).find('.product-image > a.product-link').attr('href', pdpLink);
    		$(productTile).find('a.product-name_link').attr('href', pdpLink);
    		$(productTile).find('a.product-subtitle-wrapper').attr('href', pdpLink);
    		$(productTile).find('a.product-show-more-wrapper').attr('href', pdpLink);
    	});
    }
}
