import Dropdown from '../common/Dropdown';
import $ from 'jquery';

const SELECTED_ITEM_CLASS = 'selected',
    HIDDEN = 'hidden';

export default class SummarySitesDropdown extends Dropdown {
	init() {
		super.init();
		
		this.event('click', '.js-summary-siteslist-item', this.onSelectWebsite);
		this.event('click', '.js-summary-siteslist-change', this.changeWebsite);
		
		this.sites = this.config.sitesConfig;
		this.selectedWebsiteIndex = 0;
		
		this.$summarySitesList = this.$el.find('.js-summary-siteslist-item');
		this.$selectedWebsiteTopElement = this.$el.find('.js-summary-siteslist-item.js-selected');
		this.$selectedWebsiteEl = this.$el.find('.js-summary-siteslist-item.hidden');
		this.$summarySitesListLabel = this.$el.find('.js-summary-siteslist-label');
	}
	
	onSelectWebsite(el, event) {
		event.preventDefault();
		
		let $selectedWebsiteEl = $(el);
		let selectedWebsiteIndex = $selectedWebsiteEl.data('index') || 0;
		let selectedSiteModel = this.getWebsiteModel(selectedWebsiteIndex);
		
		if (!$selectedWebsiteEl.hasClass('js-selected')) {
			this.updateSelectedWebsite($selectedWebsiteEl);
			this.$selectedWebsiteEl.removeClass(HIDDEN);
			this.$selectedWebsiteEl = $selectedWebsiteEl;
			this.selectedWebsiteIndex = selectedWebsiteIndex;
			this.$selectedWebsiteEl.addClass(HIDDEN);
			
			this.updateFlyoutLabel(selectedSiteModel);
		}
		
		this.hideFlyout();
	}
	
	updateFlyoutLabel(selectedSiteModel) {
		this.$summarySitesListLabel.find('span').text(selectedSiteModel.title);
		this.$summarySitesListLabel.find('img').attr('src', selectedSiteModel.flag);
	}
	
	updateSelectedWebsite($websiteToSelectEl) {
		this.$selectedWebsiteTopElement.html($websiteToSelectEl.clone().html());
	}
	
	changeWebsite() {
		let selectedWebsiteModel = this.getWebsiteModel(this.selectedWebsiteIndex);
		location.href = selectedWebsiteModel.url;
	}
	
	getWebsiteModel(index) {
		return this.sites[index];
	}
	
	showFlyout() {
		this.$summarySitesListLabel.addClass(HIDDEN);
		super.showFlyout();
	}
	
	hideFlyout() {
		this.$summarySitesListLabel.removeClass(HIDDEN);
		super.hideFlyout();
	}
}
