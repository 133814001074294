'use strict';

import util from 'utils';

var pageUtils = {
    title: '',
    type: '',
    params: util.getQueryStringParams(window.location.search.substr(1)),
    redirect: function (newURL) {
        setTimeout(() => {
            window.location.href = newURL;
        }, 0);
    },
    refresh: function (timeout = 500) {
        setTimeout(() => {
            window.location.assign(window.location.href);
        }, timeout);
    },
    setHash: function (newHash) {
        window.location.hash = newHash;
    }
};

module.exports = pageUtils;
