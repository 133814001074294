'use strict'

/**
 * Using IntersectionObserver to detect when images are in the viewport
 * and load them achiveing lazyLoading of the images. Read more here about
 * IntersectionObserver https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 */

class LazyImageObserver {

    constructor() {
        /**
         * will use hashMap to store the images, the key will be the image object
         * because we need to be unique, every object reference will be unique. The
         * carousel is createing copy of the images, this is the reason the string src
         * can't be used as key as it will not be unique for every image. Using Map
         * for faster algorithm, delete is constant in the map, in array it is linear.
         */
        this.lazyImages = new Map();
        this.lazyImageObserver = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    let lazyImage = entry.target;
                    let imageSetted = false;
                    lazyImage.onload = () => {
                        /**
                         * The image had loaded, remove its background css class
                         * and remove the image from the Map.
                         */
                        lazyImage.classList.remove('lazy_load_backgroud');
                        this.lazyImages.delete(lazyImage);
                    };
                    if(lazyImage.dataset.srcset!==undefined){
                        lazyImage.srcset = lazyImage.dataset.srcset;
                        imageSetted = true;
                    }else if(lazyImage.dataset.src!==undefined && imageSetted===false){
                        lazyImage.src = lazyImage.dataset.src;
                    }
                    this.lazyImageObserver.unobserve(lazyImage);
                }
            });
        });;

        /**
         * start the observer
         */
        this.startObserve();
    }

    addImage(image){
        /**
         * The image object will be the key, this way it will always be unique
         */
        this.lazyImages.set(image, image.dataset.src);
    }

    startObserve() {
        this.lazyImages.forEach((value, key) => {
            this.lazyImageObserver.observe(key);
        });
    }

    refreshObserver() {
        this.lazyImages.forEach((value, key) => {
            this.lazyImageObserver.observe(key);
        });
    }
}

/**
 * Initializing the object here and exporting it will make it singleton
 * across all the components that require it, we want to have only one instance
 * of it across all the componenets.
 */
let lazyImageObserverObj = new LazyImageObserver();

module.exports = lazyImageObserverObj;