import Component from '../Component';
import scrollUtil from 'scrollUtil';

export default class ScrollTo extends Component {
    init () {
        super.init();
        this.event('click', this.scrollToBlock);
    }
    scrollToBlock () {
        setTimeout(() => {
            scrollUtil.scrollTo(this.$el);
        }, 100);
        this.emitter.emit('click', this);
    }
}
