import Block from './Block';
import 'scroll';
import $ from 'jquery';
import screendetector from 'screendetector';
import debounce from 'lodash/debounce';

var stickyPDP ;

export default class stickySidebarSummary extends Block {
    init () {
        super.init();
        setStickyRightColumn(this.tabletBreakpointEnd);
    }
}

function setStickyRightColumn(tabletBreakpoint) {
    if (window.innerWidth >= tabletBreakpoint) {
        stickyPDP = new StickySidebar('.checkout-sidebar', {bottomSpacing: 0, resizeSensor: true, containerSelector: '.checkout-content-wrap'});
        $('.checkout-sidebar').data('sticky', stickyPDP);
    }
}
