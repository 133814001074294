import Component from '../Component';
import scrollUtil from 'scrollUtil';
import $ from 'jquery';

export default class ScrollToBlock extends Component {
    init () {
        super.init();
        this.event('click', (el) => {
            var $el = $(el);
            var block = $el.data('cmpId');

            setTimeout(() => {
                scrollUtil.scrollTo($('#' + block).offset().top-100);
            }, 100);
            this.emitter.emit('click', this);
        });
    }
}
