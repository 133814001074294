import Block from '../common/Block';
import preloader from 'preloader';
import eventMgr from 'eventMgr';
import $ from 'jquery';
import 'product';
import scrollUtil from 'scrollUtil';
import lazyImageObserverObj from 'lazyImageObserver';
import util from 'utils';

var stickyPDP;
const emitter = eventMgr.getEmitter('product.content');

var $productImageSection;
var $thumbnailSection;

var mainImageAreaTopPosition;
var singleImageHeight;
var thumbImageAreaHeight;
var thumbnailPositionTop;
var productImages;
export default class ProductContent extends Block {
    init() {
        super.init();

        this.event('click', '.js-variation_button', this.ajaxReload);

        this.eventMgr('product.variation.updated', this.update);

        this.eventMgr('product.allstyle.updated', this.listUpdate);

        this.event('click', '.js-show-additional-colours', this.showAdditionalColours);

        this.getApplicablePaymentMethods();

        var _this = this;

        setStickyRightColumnPDP(_this.mobileBreakpointEnd);

        $(window).resize(function () {
            setStickyRightColumnPDP(_this.tabletBreakpointEnd);
            setVideoHeight();
        });

        var selectedColor = $('.product-swatches.color').find($('.product-item.selected').not('.unselectable'));

        if (selectedColor.hasClass('hidden')) {
            this.showAdditionalColours($('.tenth'));
        }

        $('.js-get-all_styles').on('click', function () {
            if ($('.view-all_styles.loaded').hasClass('active')) {
                $('.view-all_styles.loaded').removeClass('active');
            } else {
                $('.view-all_styles.loaded').addClass('active');
            }

            if (!$('.view-all_styles').hasClass('loaded')) {
                getAllStylesFromPrimary();
            }
        });

        util.updateReviewsView();

        /**
         * Todo, remove extra ajax listeners and try again
         */
        /**
         * This will show the magicZoom wizard, attaching listener to all a tags
         * in the .pdp-main class. Need to use static class like pdp-main, because
         * here we are using event delegation, for the images that come after the ajax call.
         * In order the click listener to work for them we need to select the first static
         * parent which in this case is .pdp-main. Read more here https://learn.jquery.com/events/event-delegation/
         */
        $('.pdp-main').off().on('click', '.product-image_section_desktop .productImage, .product-image_section_mobile .product-image-mobile', (event) => {
            event.preventDefault();
            $('.magicZoomContainer a').remove();
            let activeClass;

            if (event.currentTarget.closest('.product-image_section_mobile')) {
                $('.product-image_section_mobile').addClass('js-active');
                activeClass = '.product-image_section_mobile';
            } else {
                activeClass = '.product-image_section_desktop';
            }

            $('' + activeClass + '').find('a.activeProductImage').removeClass('activeProductImage');
            let aTag = event.currentTarget;
            aTag.classList.add('activeProductImage');
            let $magicZoom = this.$el.find(activeClass).find('.magicZoomContainer');

            var imgCount = $('#vertical-carousel-mobile .product-image-mobile:not(".slick-cloned") img').length;

            this.$el.find(activeClass).find('a').not('.slick-cloned').each(function() {
                let dataImgCount = $(this).attr('data-img-count');

                if (dataImgCount <= imgCount) { // control the zoom gallery only to images
                    let imageSource = $(this).find('img:first').attr('data-src');
                    let imageTitle = $(this).find('img:first').attr('alt');
                    let aHrefTag = $(this).attr('href');
                    let activeImage = $(this).hasClass('activeProductImage');

                    /**
                     * Will append all the a tags with MagicZoom class added and all the images
                     * src will be replaced with data-src value to show them on the magicZoom.
                     */
                    $magicZoom.append(`<a href="${aHrefTag}"  class="${activeImage ? 'activeProductImage' : 'productImage'} MagicZoom"
                    data-options="hint:false; zoomMode:off; cssClass: mz-show-arrows; transitionEffect: false; zoomCaption: off; zoomPosition: inner; expand: window;" 
                    title="${imageTitle}" data-gallery="gallery" data-img-count="${dataImgCount}"> <img class="primary-image" src="${imageSource}" alt="${imageTitle}"/> </a>`);
                }
            });
            MagicZoom.start();
            let activeimageZoomID = this.$el.find(activeClass).find('.magicZoomContainer').find('.activeProductImage').attr('id');
            sessionStorage.setItem('PDPmaagicZoomID', activeimageZoomID);
            sessionStorage.setItem('maagicZoomID', activeimageZoomID);
            MagicZoom.expand(activeimageZoomID);

            setTimeout(function(){
                if (!$('body').hasClass('PLP-zoom-expand')) {
                    var zoomID = sessionStorage.getItem('PDPmaagicZoomID');
                    sessionStorage.removeItem('PDPmaagicZoomID');
                    MagicZoom.expand(zoomID);
                }
            }, 500);
        });

        /**
         * Select all the images in the .product-image_section_desktop and add them
         * in the intersectionObserver listener. We need to convert them to proper DomElement
         * before adding them to the observer, we cannot pass jquery objects to the Observer.
         */
        let jsElel = this.$el[0];
        let images = jsElel.querySelector('.product-image_section_desktop').querySelectorAll('.primary-image');
        for (let i = 0; i < images.length; i++){
            if (!images[i].classList.contains('lazy_load_none')){
                lazyImageObserverObj.addImage(images[i]);
            }
        }
        lazyImageObserverObj.refreshObserver();

        /**
         * PDP - Image Scrolling - Thumbnail
         */

        let scrollTopBigContent = 0;
        let movingContentTop = 0;

        this.setThumnailData();

        $(window).scroll(() => {
            let mainImageAreaHeight = (singleImageHeight * productImages.length);
            scrollTopBigContent = $thumbnailSection.offset().top - mainImageAreaTopPosition;
            if (scrollTopBigContent > 0) {
                movingContentTop = ((thumbImageAreaHeight * scrollTopBigContent) / mainImageAreaHeight) - 12; // 12 is the margin around the thumbnail
                $('.selection-marker').css('top', movingContentTop);
            }

            var thumbnailBottom = $('.product-thumbnail_section_desktop').innerHeight() + thumbnailPositionTop;
            var rightSideStickyContainer = document.querySelector('.product-detail-inner');
            var style = window.getComputedStyle(rightSideStickyContainer);
            var rightSidePos = style.position;

            if (rightSidePos === 'fixed') {
                $thumbnailSection.css('position','fixed');

                //Change thumnail positioning depending on Left Side information asset configuaration exists, under all videos
                if ($('.product-information-left').length > 0) {
                    this.checkLeftInfoAsset($thumbnailSection, thumbnailBottom);
                }
            } else {
                $thumbnailSection.css('position','absolute');
                if ($('.product-information-left').length > 0) {
                    this.checkLeftInfoAsset($thumbnailSection, thumbnailBottom);
                }
            }

            if ($('.js-desktop-video').length > 0) {
                handleVideoPlay(_this.mobileBreakpointEnd);
                setVideoHeight();
            }
        });

        /**
         * Thumbnail click
         */
        $('.product-thumbnail_section_desktop a, .product-thumbnail_section_desktop .play-button').on('click', function(e) {
            e.preventDefault();
            var imageIndex = $(this).attr('data-thumb-index');
            var imgHeight = $('.product-image_section_desktop a[data-image-index="0.0"] img.primary-image').height();
            var headerHeight = $('.header-wrapper').height();

            $('html, body').animate({
                scrollTop: (imageIndex * imgHeight) + headerHeight + (imageIndex * 2) // the expression (imageIndex * 2) compensates for the 2px margin between the images
            }, '300');
        });
    }

    checkLeftInfoAsset($thumbnailSection, thumbnailBottom){
        if ($('.product-information-left').offset().top > $(window).scrollTop() + thumbnailBottom) {
            $thumbnailSection.css('position','fixed');
        } else {
            $thumbnailSection.css('position','absolute');
        }
    }

    showAdditionalColours(el, event) {
        var hiddenColours = $('.product-item.hidden');

        hiddenColours.each((colour) => {
            $(hiddenColours[colour]).removeClass('hidden')
        });

        $(el).addClass('hidden');
    }

    ajaxReload(el, event) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        var $el = $(el),
            url = $el.attr('href') || $el.data('variationUrl'),
            selected = $el.data('selected'),
            expandColor = $el.data('expandcolor') || false;

        if (selected) {
            return;
        }

        if (url) {
            this.removeShopThelook(this.$el);
            preloader.show(this.$el);
            eventMgr.execute('product.variation.get', {
                url: url,
                expandcolor: expandColor,
                donthidepreloader: true
            }).then(() => {
                this.getApplicablePaymentMethods();
            }).then(() => {
                this.setThumnailData();
            });
        }
    }

    removeShopThelook(el) {
        var $el = $(el);
        $el.closest('.pdp-main').find('.shopthelook-wrapper').remove();
    }

    update(html) {
        var _this = this;

        _this.$el.replaceWith(html);
        var $live = $('<div>').html(html);
        var imgCount = $live.find('.product-image_container img').length;
        $('.product-image_container img', $live).load(function () {
            imgCount--;
            if (imgCount == 0) {
                scrollUtil.scrollTo(0);
                setTimeout(() => {
                    setStickyRightColumnPDP(_this.tabletBreakpointEnd);
                    MagicZoom.start();
                }, 100);
            }
        });


        // Skip it if current view is quick view
        if (!this.$el.closest('.pdp-main[data-cmp="quickView"]').length) {
            var variationUrl = this.$el.find('.js-variation-url').data('url');
            variationUrl && history.replaceState({}, document.title, variationUrl);
        }

        if (yotpo) {
            util.refreshYotpoWidget();
        }
    }

    listUpdate(data) {
        var $html = $('<div>' + data + '</div>'),
            $productlist = $html.find('.product-allstyles');

        $('.all-styles-list').html($productlist.html());
        $('.js-get-all_styles').addClass('loaded');

        var resizeEvent = window.document.createEvent('UIEvents');
        resizeEvent.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(resizeEvent);
    }

    getApplicablePaymentMethods() {
        var $el = $('.product-alternative_payment'),
            url = $el.data('applicablepaymentmethods');

        if (url) {
            eventMgr.execute('product.payment_methods.get', {
                url: url
            }).then((data) => {
                if (data) {
                    var responseData = data.methodResp;

                    if (responseData) {
                        if (!responseData.klarna) {
                            $('.product-klarna_descriptions').hide();
                        }
                        if (!responseData.payPal) {
                            $('#PDP_paypal').hide();
                        }
                        if (!responseData.applePay) {
                            $('.js-apple-pay-pdp-button').hide();
                        } else {
                            if (window.ApplePaySession) {
                                if (!$('.js-apple-pay-pdp-button').hasClass('applepay-button')) {
                                    $('.js-apple-pay-pdp-button').addClass('applepay-button');
                                    $('.js-apple-pay-pdp-button').removeClass('hidden');
                                }
                            } else {
                                $('.js-apple-pay-pdp-button').removeClass('applepay-button');
                                $('.js-apple-pay-pdp-button').addClass('hidden');
                            }
                        }
                    }
                }
            });
        }
    }

    setThumnailData() {
        $productImageSection = $('.product-image_section_desktop');
        $thumbnailSection = $('.product-thumbnail_section_desktop');

        util.waitForEl($productImageSection, () => {
            mainImageAreaTopPosition = $productImageSection.offset().top;
            singleImageHeight = $productImageSection.find('a[data-image-index="0.0"] img.primary-image').height();
            productImages = $productImageSection.find('a');
        }, 100);

        util.waitForEl($thumbnailSection, () => {
            thumbImageAreaHeight = $thumbnailSection.height();
            thumbnailPositionTop = $thumbnailSection.position().top;
        }, 100);
    }
}

function setStickyRightColumnPDP(mobileBreakpointEnd) {
    if (window.innerWidth >= mobileBreakpointEnd) {
        // PDP right column sticky, sticky-sidebar

        stickyPDP = new StickySidebar('.product-detail', {
            containerSelector: '.product-main_content',
            innerWrapperSelector: '.product-detail-inner',
            stickyClass: 'sticky-right-pdp',
            resizeSensor: false,
            topSpacing: $('.header-content.js-sticky-part').innerHeight() + 15,
            bottomSpacing: 0,
            minWidth: 767
        });
        $('.product-detail').data('sticky', stickyPDP);
    }
}

function getAllStylesFromPrimary() {
    var $el = $('.js-get-all_styles');
    var url = $el.attr('data-url');
    var productID = $el.attr('data-productid');
    var windowWidth = $(window).width();
    var ipad = false;

    if (windowWidth < 1550) {
        ipad = true;
    }

    if (url && productID) {
        eventMgr.execute('product.all_style.get', {
            url: url,
            productStyle: $el.attr('data-product-style'),
            pid: productID,
            deviceIpad: ipad
        }).then((data) => {
            $('.view-all_styles').removeClass('hidden');
            $('.product-all_styles .tiles-container').addClass('active');
            $('.js-get-all_styles').addClass('active');

            window.setTimeout(() => {
                var resizeEvent = window.document.createEvent('UIEvents');
                resizeEvent.initUIEvent('resize', true, false, window, 0);
                window.dispatchEvent(resizeEvent);
            }, 100);
        });
    }
}

// Handle the video play for desktop
function handleVideoPlay (mobileBreakpointEnd) {
    var windowWidth = $(window).width();

    if (windowWidth >= mobileBreakpointEnd) {
        var windowHeight = $(window).height();
        var scrollTop = $(window).scrollTop();
        var scrollBottom = scrollTop + windowHeight;

        $('.js-desktop-video').each(function() {
            var videoTop = $(this).offset().top;
            var videoHeight = $(this).height();

            var playTrigger = videoTop + (videoHeight * 0.4); // 40% of the video height
            var stopTrigger = videoTop + (videoHeight * 0.6); // 60% of the video height

            if (scrollBottom >= playTrigger && scrollTop <= stopTrigger) {
                $(this).get(0).play();
                handleVideoControls($(this).closest('.js-vid'), 'play')
            } else {
                $(this).get(0).pause();
                $(this).get(0).muted = true;
                handleVideoControls($(this).closest('.js-vid'), 'pause');
            }
        });
    }
}

/**
 * Function to handle the video controls on sliding - play and muted
 */
function handleVideoControls(element, control) {
    var $this = element;

    var muteBtn = $this.find('.pdp-video-controls .muteUnmute');
    var playBtn = $this.find('.pdp-video-controls .playPause');

    if (control === 'play') {
        muteBtn.removeClass('unmuted').addClass('muted');
        playBtn.removeClass('play').addClass('pause');
    } else {
        muteBtn.removeClass('unmuted').addClass('muted');
        playBtn.removeClass('pause').addClass('play');
    }
}

function setVideoHeight() {
    let singleImageHeight = $('.product-image_section_desktop a[data-image-index="0.0"] img.primary-image').height();
    $('.js-vid').height(singleImageHeight);
}
