'use strict';

import Component from './Component';
import logger from 'browser-console';
import eventMgr from 'eventMgr';
import RegistryMgr from './RegistryMgr';
import {Promise as Promise} from 'es6-promise-polyfill';
import $ from 'jquery';
import lazyImageObserverObj from 'lazyImageObserver';

var inited = false;

const rootCmp = new Component(document),
    tryFn = (fn, failFn) => {
        try {
            return fn();
        } catch (e) {
            if (failFn) {
                return failFn(e);
            }
        }
    },

    getFirstLayer = (origElement, selector) =>
        origElement.find(selector).filter((i, el) => {
            var nearestMatch = $(el).parent().closest('[data-cmp]:not([data-cmp=""])');

            return !nearestMatch.length || !origElement.find(nearestMatch).length;
        }),

    initComponent = (domNode, cmpName) => {
        var currentCmp,
            registredCmps = RegistryMgr.getComponents(),
            $domNode = $(domNode),
            cmpConf = {},
            parent;

        if (registredCmps[cmpName]) {
            if (!$domNode.data('cmp-instance')) {
                if ($domNode.attr('data-json-config')) {
                    tryFn(() => {
                        cmpConf = $.extend(cmpConf, $.parseJSON($domNode.attr('data-json-config')));
                    }, (error) => {
                        logger.error('Error while loading component config', $domNode, error);
                    });
                }
                cmpConf = $.extend(cmpConf, $domNode.data());

                currentCmp = new (registredCmps[cmpName].default)($domNode, cmpConf);
                currentCmp.cmpName = cmpName;
                currentCmp.items = [];
                $domNode.data('cmp-instance', currentCmp);
                parent = $domNode.parent().closest('[data-cmp]').data('cmp-instance') || rootCmp;

                if (parent) {
                    parent.items.push(currentCmp);
                }
                if (currentCmp.$el) {
                    initCmps(currentCmp);
                }
                if (typeof currentCmp.init === 'function') {
                    currentCmp.init();
                }
            } else {
                currentCmp = $domNode.data('cmp-instance');
                if (currentCmp.$el) {
                    initCmps(currentCmp);
                }
            }
        } else {
            logger.error(`Cant find component "${cmpName}" in registry.`);
        }
    },
    initCmps = (baseCmp) => {
        var cmp = baseCmp || rootCmp,
            $firstLayer = getFirstLayer(cmp.$el, '[data-cmp]');

        $firstLayer.each((i, domNode) => {
            var cmpName = $.trim($(domNode).data('cmp'));

            if (cmpName) {
                initComponent(domNode, cmpName);
            }
        });
        inited = true;
        return new Promise((res) => {
            res();
        });
    },
    releaseCmps = (baseCmp) => {
        var cmp = baseCmp || rootCmp,
            item,
            i;

        for (i = 0; i < cmp.items.length; ++i) {
            item = cmp.items[i];
            if (item) {
                if (typeof item.isBindedToDom === 'function' &&
                        !item.isBindedToDom() &&
                        typeof item.destroy === 'function') {
                    item.destroy();
                    cmp.items.splice(i, 1);
                } else {
                    releaseCmps(item);
                }
            }
        }
        return new Promise((res) => {
            res();
        });
    },
    updateComponents = (baseCmp) => {
        if (inited) {
            releaseCmps(baseCmp);
            initCmps(baseCmp);
        }
        return new Promise((res) => {
            res();
        });
    };

eventMgr.registerAction('componentmgr.update', updateComponents);
eventMgr.registerAction('componentmgr.release', releaseCmps);
eventMgr.registerAction('componentmgr.initcmp', initCmps);


$(document).ajaxStop(() => {
    setTimeout(updateComponents, 10);
});

exports.releaseCmps = releaseCmps;
exports.updateComponents = updateComponents;
exports.initCmps = initCmps;

var resolver = () => {};
const initedPromice = new Promise((res) => {
    resolver = res;
});

eventMgr.registerAction('componentmgr.inited', () => initedPromice);


// auto init
(function (document) {
    var isLoaded = false;

    function init() {
        if (typeof document.addEventListener === 'function') {
            document.removeEventListener('DOMContentLoaded', init);
        }
        if (!isLoaded) {
            setTimeout(() => {
                if (!inited) {
                    initCmps();
                    logger.info('inited cmpMgr');
                    resolver();
                }
            }, 1);
            isLoaded = true;
        }
    }
    logger.info('starting cmpMgr');
    var onLoad = window.onload;

    if (typeof document.addEventListener === 'function') {
        document.addEventListener('DOMContentLoaded', init);
    }
    // Fallback for browsers that doesn't support DOMContentLoaded event
    window.onload = () => {
        init();
        if (onLoad) {
            onLoad();
        }
    };

}(document));
