import InputText from '../forms/InputText';

export default class InputPostal extends InputText {
    init () {
        super.init();
    }
    clearError () {
        this.$el
        .removeClass('state-error')
        .find('.form-row_error').remove();
    }
}
