import Block from './Block';
import screendetector from 'screendetector';

const SHADOW = 'shadow';

export default class ShadowBlock extends Block {
    init () {
        super.init();

        this.eventMgr('SearchSuggestion.open', this.shadowOn);
        this.eventMgr('SearchSuggestion.close', this.shadowOff);
        this.eventMgr('hamburgermenu.open', this.shadowOn);
        this.eventMgr('hamburgermenu.close', this.shadowOff);
        this.eventMgr('minicart.mobile.open', this.shadowOn);
        this.eventMgr('minicart.mobile.close', this.shadowOff);
        this.eventMgr('MenuLink.open', () => {
            if (screendetector.getDeviceId() !== screendetector.MOBILE) {
                this.shadowOn();
            }
        });
        this.eventMgr('MenuLink.close', () => {
            if (screendetector.getDeviceId() !== screendetector.MOBILE) {
                this.shadowOff();
            }
        });
    }
    shadowOn () {
        if (!this.shadow) {
            this.$el.addClass(SHADOW);
            this.shadow = true;
        }
    }
    shadowOff () {
        if (this.shadow) {
            this.$el.removeClass(SHADOW);
            this.shadow = false;
        }
    }
}
