import Component from '../Component';

export default class PrintLink extends Component {
    init () {
        super.init();
        this.event('click', this.printPage);
    }
    printPage (el, e) {
        e.preventDefault();
        window.print();
    }
}
