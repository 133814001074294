import Component from '../Component';

export default class Link extends Component {
    setHref (url) {
        if (url) {
            this.$el.attr('href', url);
        } else if (this.config.defaultHref) {
            this.$el.attr('href', this.config.defaultHref);
        }
    }
    getHref () {
        return this.$el.attr('href');
    }
}
