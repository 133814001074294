import FormGeneric from '../forms/FormGeneric';
import {check, determine} from '../cardtype';
import eventMgr from 'eventMgr';

const emmiter = eventMgr.getEmitter('DetectCartType');

export default class DetectCardType extends FormGeneric {
    init () {
        super.init();
        this.isValid = true;
        this.onChild(this.config.numberField, 'change', this.onChange);
        this.initing = true;
        this.getById(this.config.numberField, this.onChange);
        this.initing = false;
        this.allowedCards = this.config.allowedCards.split(',');
    }
    validate () {
        this.getById(this.config.numberField, (numberFieldCmp) => {
            if (!numberFieldCmp.shown || numberFieldCmp.disabled || numberFieldCmp.skipValidation) {
                this.isValid = true;
            } else {
                this.onChange(numberFieldCmp);
            }
        });
        return this.isValid;
    }
    setFocus () {
        this.getById(this.config.numberField, (numberFieldCmp) => {
            numberFieldCmp.setFocus();
        });
    }
    onChange (numberFieldCmp) {
        this.getById(this.config.typeField, (typeFieldCmp) => {
            if (!this.initing) {
                this.isValid = numberFieldCmp.validate();
            }

            this.checkResult = check(numberFieldCmp.getValue());

            if (typeof this.checkResult === 'object' && this.checkResult.masked) {
                this.isValid = true;
                emmiter.emit('activate', typeFieldCmp.getValue());
            } else if (this.checkResult) {
                const cardType = determine(numberFieldCmp.getValue(), this.allowedCards);

                if (numberFieldCmp.getValue()) {
                    if (cardType && cardType !== 'unknown') {
                        emmiter.emit('activate', cardType);
                        typeFieldCmp.setValue(cardType);
                    } else {
                        emmiter.emit('activate', '');
                        numberFieldCmp.setError(numberFieldCmp.config.parseError);
                        this.isValid = false;
                    }
                }
            } else {
                if (!this.initing) {
                    numberFieldCmp.setError(numberFieldCmp.config.parseError);
                    this.isValid = false;
                }
                emmiter.emit('activate', '');
            }
        });
    }
}
