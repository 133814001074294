import eventMgr from 'eventMgr';

let LazyLoad = require('vanilla-lazyload');

var lz = new LazyLoad({ elements_selector: ".lazy", threshold: -50});

const update = () => {
    lz.update();

    return new Promise((res) => {
        res();
    });
};

$(document).on('ajaxComplete', function(){
    update();
})

eventMgr.registerAction('lazyload.update', update);
