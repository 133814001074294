import Block from '../common/Block';
import {each, isFunction} from 'jquery';

export default class FormGeneric extends Block {
    init () {
        super.init();
        if (this.$el.is('form')) {
            this.event('submit', this.onSubmit);
        }
        this.eventMgr('FormSubmitter.submit.' + this.id, this.submit);

        if (this.config.match) {
            each(this.config.match, (key, item) => {
                this.getById(key, (cmp1) => {
                    this.getById(item.field, (cmp2) => {
                        cmp1.setMatchCmp(cmp2, item);
                    });
                });
            });
        }
    }
    submit (actionName) {
        this.beforeSubmit(actionName);
        this.$el.find('[name=' + actionName + ']').trigger('click');
    }
    beforeSubmit () {}
    onSubmit () {
        this.beforeSubmit();
        return this.isChildsValid(() => {
            this.emitter.emit('submit');
        });
    }
    getFormFields (component, fields = {}) {
        (component || this).eachChild((cmp) => {
            if (!(cmp.disabled || cmp.skipSubmission && cmp.skipSubmission())) {
                const name = cmp.getName && cmp.getName();

                if (name) {
                    fields[name] = cmp.getValue();
                }

                if (cmp.config.isFormContainer) {
                    this.getFormFields(cmp, fields);
                }
            }
        });
        return fields;
    }
    isChildsValid (cb) {
        var valid = true;

        this.eachChild((item) => {
            if (isFunction(item.validate)) {
                if (!item.validate()) {
                    if (valid && item.setFocus) {
                        item.setFocus();
                    }
                    valid = false;
                }
            }
            item.$el.find('.form-row_error').removeAttr('aria-live');
        });
        if (valid && typeof cb === 'function') {
            cb();
        }
        return valid;
    }
    validate () {
        return this.isChildsValid();
    }
    isValid () {
        var valid = true;

        this.eachChild((itemCmp) => {
            if (!itemCmp.isValid()) {
                valid = false;
                return false;
            }
        });
        return valid;
    }
    /* jscs:disable disallowEmptyBlocks */
    setFocus () {
    }
    /* jscs:enable disallowEmptyBlocks */
    onlyValid (fn) {
        return (arg1, arg2) => this.isChildsValid(fn.bind(this, arg1, arg2));
    }
}
