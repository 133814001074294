import Block from '../common/Block';
import eventMgr from 'eventMgr';
import $ from 'jquery';
import 'product';
import 'quickbuy';
import resources from 'resources';
export default class QuickBuy extends Block {
    init () {
        super.init();
        this.eventMgr('quickbuy.update', this.updateModal);
        this.selectedVariantId = null;
        this.defaultOverflow = $('body').css('overflow');
        this.tile = this.$el.closest('.tile');
        this.$notificationBar = $('.notification-bar');
        this.isWelcomeBack = this.config.iswelcomeback;
    }

    updateModal(data) {
        if (data.qbModal[0] === this.$el[0]) {
            this.selectedVariantId = null;
            this.isDesktopView = $(window).width() > this.tabletBreakpointEnd;
            this.qbBtn = data.qbBtn;
            $(this.qbBtn).hide();

            if (!this.isDesktopView) {
                this.$el.appendTo('body');
            }

            this.$el.show();
            this.$el.html(data.qbTemplate);
            this.attachEvents();
            var isMinicartPopUp = this.$el.closest('.product-minicartnew_carousel').length > 0;

            if (!this.isDesktopView) {
                setTimeout(() => {
                    this.$el.find('.qb-modal-content').css({'maxHeight': '100%'});
                    $('body').css('overflow', 'hidden');
                }, 300);
            } else {
                const infoWrapper = this.$el.siblings('.product-info-wrapper');

                this.$el.css({'marginBottom': infoWrapper.outerHeight(true) + 'px'});
                if (isMinicartPopUp || this.isWelcomeBack) {
                    this.$el.css('height', '65%');
                } else {
                    this.$el.css('height', '40%');
                }
            }
            this.selectedVariantId = null;
            this.$el.addClass('qb-active');
        }
    }

    attachEvents() {
        this.event('click', '.qb-close', this.closeModal);
        this.event('click', '.qb-modal-background', this.closeModal);
        this.event('click', '.qb-product-color__button', this.colorClick);
        this.event('click', '.qb-product-size__button', this.sizeClick);
        this.event('click', '.qb-add-to-bag', this.addToBag);

        this.desktopMouseLeaveEvent();

        this.addToBagBtn = this.$el.find('.qb-add-to-bag');
        this.addToBagHtml = this.addToBagBtn.html();
    }

    closeModal(el, ev) {
        if (this.isDesktopView) {
            this.$el.css('height', '0');
        } else {
            this.$el.find('.qb-modal-content').css({'maxHeight': '0'});
            $('body').css('overflow', this.defaultOverflow);
        }
        setTimeout(() => {
            this.$el.html('');
            this.tile.find('.add-to-bag-btn').show();
            if (!this.isDesktopView) {
                this.tile.children('.qbUrl').after(this.$el);
            }
        }, 1000);
        this.$el.removeClass('qb-active');
    }

    colorClick(el, ev) {
        this.$el.off('mouseleave');
        const $modalBody = $(el).closest('.qb-modal-content_body');
        $modalBody.find('.qb-product-color__button').removeClass('qb-color-selected');
        $modalBody.find('.qb-product-size__button').removeClass('size-selected');
        const variants = $(el).data('variants');
        const $sizeBtns = $modalBody.find('.qb-product-size__button');

        variants.forEach(v => {
            $sizeBtns.each(function(index) {
                if($(this).data('sizeid') == v.sizeId) {
                    $(this).removeClass('qb-disabled');
                    $(this).attr('data-variant-id', v.id);
                    if (!v.isInStock) {
                        $(this).addClass('qb-disabled');
                    }
                }
            });
        });

        $modalBody.find('.qb-product-image_container img').attr('src', $(el).data('imgurl'));
        $modalBody.find('.qb-price').html(variants[0].priceHtml);
        this.$el.find('.qb-view-details a').attr('href', $(el).data('pdpurl'));
        this.selectedVariantId = null;
        $(el).addClass('qb-color-selected');
        setTimeout(() => this.desktopMouseLeaveEvent(), 0);
    }

    sizeClick(el) {
        const $modalBody = $(el).closest('.qb-modal-content_body');
        if ($(el).hasClass('qb-disabled')){
            return;
        };
        $modalBody.find('.qb-product-size__button').removeClass('size-selected');
        this.selectedVariantId = $(el).data('variant-id');
        $(el).addClass('size-selected');
        this.addToBagBtn.html(this.addToBagHtml);
        this.addToBagBtn.removeClass('add-to-bag-disabled');
        if(!this.isDesktopView) {
            this.$el.find('.qb-product-size_container').removeClass('size-not-selected');
        } else {
            this.$el.find('.qb-product-size').removeClass('size-not-selected');
        }
    }

    showNotificationBar() {
        let header = $('.header-initialized'),
            headerOffset = header.offset(),
            headerHeight = header.outerHeight(),
            viewInterval = window.SitePreferences.ADD_TO_BAG_NOTIFICATION_INTERVAL;

        this.$notificationBar.empty().html(resources.ITEM_ADDED_TO_BAG_TICK + 1 + ' ' + resources.ITEM_ADDED_TO_BAG);
        let top = headerOffset.top + headerHeight;
        if(header.hasClass('scrolled-down')) {
            top = 0;
        }
        this.$notificationBar.css({'position': 'fixed', 'top': top});
        this.$notificationBar.slideDown('slow');

        setTimeout(() => {
            this.closeNotificationBar();
        }, viewInterval);
    }

    closeNotificationBar () {
        this.$notificationBar.hide('slow').empty();
        this.$notificationBar.css({'position': 'absolute', 'top': 'initial'});
    }

    addToBag(el) {
        if (this.selectedVariantId) {
            eventMgr.execute('cart.add', {
                pid: this.selectedVariantId,
                Quantity: 1,
                productName: this.config.productName,
                isWelcomeBack: this.isWelcomeBack,
                selectedSize: this.$el.find('.size-selected').data('sizename'),
                selectedColor: this.$el.find('.qb-color-selected').data('colorname'),
                productPrice: this.$el.find('.qb-price').text().trim(),
                productImgHref: this.$el.find('.qb-color-selected').data('imgurl')
            });
            
            this.closeModal();
        } else {
            this.addToBagBtn.addClass('add-to-bag-disabled');
            if(!this.isDesktopView) {
                this.$el.find('.qb-product-size_container').addClass('size-not-selected');
            } else {
                this.$el.find('.qb-product-size').addClass('size-not-selected');
            }
            this.addToBagBtn.html('<span>Please select a size</span>')
        }
    }

    desktopMouseLeaveEvent() {
        if (this.isDesktopView) {
            this.$el.one('mouseleave', this.closeModal.bind(this));
        }
    }
}
