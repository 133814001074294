import Block from './Block';
import $ from 'jquery';
import screendetector from '../screendetector';
import _debounce from 'lodash/debounce';
import eventMgr from '../eventMgr';
import 'slick-carousel';
import preloader from 'preloader';
import lazyImageObserverObj from 'lazyImageObserver';
import util from 'utils';

const defaultConfig = {
    dots: true,
    infinite: true,
    speed: 300,
    autoplay: false,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    useTransform: false,
    lazyLoad: 'ondemand'
};

const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

// https://github.com/kenwheeler/slick/issues/1071
(function ($) {
    'use strict';
    //create temporal object to get slick object
    var getSlick = function () {
        var $tmp = $('<div>').slick();
        var slick = $tmp[0].slick.constructor;

        $tmp.slick('unslick');
        return slick;
    };

    if ($.fn.slick) {
        var Slick = getSlick();

        if (Slick) {
            //hook checkResponsive method
            var checkResponsiveOrig = Slick.prototype.checkResponsive;

            Slick.prototype.checkResponsive = function (initial, forceUpdate) {
                var _ = this;

                if (_.options.autoSlidesToShow &&
                        !_.options.infinite &&
                        _.options.variableWidth
                    ) {
                    var sliderWidth = _.$slider.width();
                    var width = 0,
                        length = _.$slides.length;

                    for (var i = 0; i < length; i++) {
                        width += $(_.$slides[i]).outerWidth(true);
                    }
                    _.averageSlidesWidth = width / length;
                    _.options.slidesToShow =
                    Math.floor(sliderWidth / _.averageSlidesWidth) || 1;
                    //force update arrows
                    if (_.lastSlidesToShow !== _.options.slidesToShow) {
                        _.lastSlidesToShow = _.options.slidesToShow;
                        if (initial === true) {
                            _.currentSlide = _.options.initialSlide;
                        }
                        _.refresh(initial);
                    }
                }
                return checkResponsiveOrig.apply(this, arguments);
            };

            //hook getLeft method
            var getLeftOrig = Slick.prototype.getLeft;

            Slick.prototype.getLeft = function (slideIndex) {
                var _ = this;

                if (
                _.options.autoSlidesToShow &&
                !_.options.infinite &&
                _.options.variableWidth
                ) {
                    var targetSlide = _.$slideTrack
                        .children('.slick-slide')
                        .eq(slideIndex);

                    if (targetSlide[0]) {
                        var diff = 0;

                        if (slideIndex) {
                            var sliderWidth = _.$slider.width();
                            var otherSlidesWidth =
                                (_.slideCount - slideIndex) * _.averageSlidesWidth;

                            if (otherSlidesWidth < sliderWidth) {
                                diff = sliderWidth - otherSlidesWidth;
                            }
                        }
                        return (targetSlide[0].offsetLeft - diff) * -1;
                    }
                    return 0;
                }
                return getLeftOrig.apply(this, arguments);
            };
        }
    }
})(jQuery);

export default class Carousel extends Block {
    init () {
        var option;
        var initialImageIndex;

        super.init();

        for (option in this.config.slotOptions) {
            this.config[option] = this.config.slotOptions[option];
        }
        delete this.config.slotOptions;

        this.$el.removeClass('hidden');
        this.slickConfig = $.extend({}, defaultConfig, this.config);
        /**
         * init event have to be moved before the slick call
         */
        this.$el.on('init', () => {
            this.executeSlickFn('slickGoTo', this.config.slideToGo > 0 ? +this.config.slideToGo : 0, true);
            this.executeSlickFn('setSlideClasses', +this.config.slideToGo);

            if (this.config.cmpId !== 'colorCarousel') {
                this.config.slideToGo = null;
            }
            /**
             * if it is plpProductTileCarousel or plpProdutTileMoboleCarousel
             * will get all the images and add them to the lazyImageObserver array
             * where the IntesrectionObserver will watch if the image need to be displayed
             * if yes it will be displayed, this way we achive lazy loading of the background carousel images
             */
            if (this.config.cmpId == 'plpProdutTileCarousel'){
                /**
                 * Convert to Js Object and pass to the observer proper DOM Element.
                 * Will not work with jQuery object.
                 */
                let allImages = this.$el[0].querySelectorAll('img');
                for (let i = 0; i < allImages.length; i++){
                    if (!allImages[i].classList.contains('lazy_load_none')){
                        allImages[i].classList.add('lazy_load_backgroud');
                        lazyImageObserverObj.addImage(allImages[i]);
                    }
                }
                lazyImageObserverObj.refreshObserver();
            }
            /**
             * We are using the same code as above here, but can't put it one if statement
             * because many carousels are loaded at the same time and they are interrupting
             * so it must be in two different if statemnts.
             */
            else if (this.config.cmpId == 'plpProdutTileMoboleCarousel') {
                let allImages = this.$el[0].querySelectorAll('img');
                for (let i = 0; i < allImages.length; i++){
                    allImages[i].classList.add('lazy_load_backgroud');
                    lazyImageObserverObj.addImage(allImages[i]);
                }
                lazyImageObserverObj.refreshObserver();
            }
        });

        if (this.config.initDelay) {
            this.initSlick = _debounce(this.initSlick, this.config.initDelay);
        }


        if (!this.config.disable) {
            if (!this.config.disableOn) {
                this.initSlick();
            } else {
                this.initDisabling();
            }
        }

        var slick = this.$el;
        //Required attribute for Accessibility
        util.waitForEl(this.$el.find('.slick-track'), function() {
            slick.find('.slick-track').attr('aria-label','slick-track').attr('aria-busy', true);
        });

        this.event('touchstart', this.stopTouch);
        this.event('touchend', this.stopTouch);
        this.event('touchmove', this.stopTouch);
        this.event('beforeChange', this.onSlideChange);
        this.event('carouselUpdate', this.onCarouselUpdate);
    }
    initDisabling () {
        this.eventMgr('screenDetector.mobile', this.onDeviceChange);
        this.eventMgr('screenDetector.tablet', this.onDeviceChange);
        this.eventMgr('screenDetector.tablet_new', this.onDeviceChange);
        this.eventMgr('screenDetector.desktop_new', this.onDeviceChange);
        this.eventMgr('screenDetector.tablets', this.onDeviceChange);
        this.eventMgr('screenDetector.desktop', this.onDeviceChange);
        this.onDeviceChange(screendetector.getDeviceId());
    }
    initSlick () {
        if (!this.slickInitialized && !this.$el.hasClass('slick-initialized')) {

            this.$el.slick(this.slickConfig);
            if ((this.config.cmpId === 'colorCarousel')) {
                this.$el.slick('slickGoTo', this.config.slideToGo > 0 ? +this.config.slideToGo : 0, true);
            }
            else if (this.config.cmpId === 'plpProductColor') {
                let selectedColorIndex = this.$el.find('.tile-color-swatch.selected').index();

                this.$el.slick('slickGoTo', selectedColorIndex > 3 ? selectedColorIndex - 3 : 0);
            } else if ($('.plp-detail-view_button').length > 0 &&
                $('.plp-detail-view_button').hasClass('active-detail') &&
                this.config.cmpId === 'plpProdutTileMoboleCarousel') {

                let detailsIndex = this.$el.closest('.js-product_tile').find('.main-image').data('detail-image-index');

                this.$el.slick('slickGoTo', detailsIndex > 0 ? detailsIndex : 0, true);
            }

            if (this.config.video) {
                var video = this.$el.find('video').first();

                if (video.length > 0) {
                    video.get(0).play();
                }
            }
            this.eachChild((cmp) => {
                if (typeof cmp.reinit === 'function') {
                    cmp.reinit();
                }
            });
            this.slickInitialized = true;

            if (this.config.resizeafterload) {
                this.$el.resize();
            }

            preloader.hide();
            this.initIndicators();
            this.accessibilityFixes();

            if (isIOS) {
                this.shortDescriptionIOSFix();
            }
        }

        var _this = this;

        $(window).resize(function(){
            _this.initIndicators();
        });

        preloader.hide();
    }
    unSlick () {
        if (this.slickInitialized) {
            this.$el.slick('unslick');
            this.slickInitialized = false;
        }
    }
    onCarouselUpdate () {
        this.unSlick();
        this.initSlick();
    }
    onDeviceChange (device) {
        var disable = this.config.disableOn.indexOf(device) >= 0;

        if (disable) {
            this.unSlick();
        } else {
            this.initSlick();
        }
    }
    onSlideChange (el, event, slick, currentIndex, newIndex) {
        //Slider videos
        if (this.config.cmpId == 'pdpImagesCarousel'){
            var $productImageSection = $('.product-image_section_mobile');
            let singleImageHeight = $productImageSection.find('a[data-slick-index="0"] img').height();

            $productImageSection.find('video').css('height', singleImageHeight);

            handleVideoPlay(slick, currentIndex, newIndex);
        }

        if (this.slickConfig.infinite) {
            eventMgr.execute('componentmgr.update', this);
        }
        this.emitter.emit('changeSlide', newIndex, currentIndex);
    }
    executeSlickFn (fnName, ...args) {
        if (this.slickInitialized) {
            this.$el.slick(fnName, ...args);
        }
    }
    stopTouch (el, e) {
        e.stopPropagation();
    }

    initIndicators(){
        if (this.$el) {
            var $parentSlider = this.$el.parent();
            var $windowWidth = $(window).width();

            if ($windowWidth > this.tabletBreakpointEnd && $parentSlider.hasClass('last-visited')) {
                return;
            }

            var $slider = $parentSlider.children('.slick-slider');
            var $progressBar = $parentSlider.children('.progress');
            var $progressBarLabel = $progressBar.children('.slider__label');

            var sliderCount = $slider.find('.slick-slide').not('.slick-cloned').length;
            var defaultCalc = (100 / (sliderCount - 1));

            var slidesToShow = function() {
                if (!this.config.jsonConfig) {
                    return this.config.slidesToShow || defaultConfig.slidesToShow;
                }
                let index = 1;
                if ($(window).width() > this.tabletBreakpointEnd) {
                    index = 0;
                }
                return this.config.jsonConfig.responsive[index].settings.slidesToShow;
            }

            if (sliderCount <= slidesToShow.apply(this)) {
                $progressBar.css('display', 'none');
            } else {
                $progressBar.css('background-size', defaultCalc + '% 100%').attr('aria-valuenow', defaultCalc);

                $slider.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
                    var calc = ((nextSlide) / (slick.slideCount - 1)) * 100;

                    if (calc === 0) {
                        calc = defaultCalc;
                    }
                    $progressBar.css('background-size', calc + '% 100%').attr('aria-valuenow', calc);
                    $progressBarLabel.text(calc + '% completed');
                });
            }
        }
    }

    accessibilityFixes() {
        let carousel = this.$el;
        util.waitForEl('.slick-slide[role="option"]', () => {
            carousel.find('.slick-slide[role="option"]').removeAttr('role');
        }, 500);

    }

    shortDescriptionIOSFix() {
        util.waitForEl('.product-subtitle', () => {
            $('.product-subtitle').removeClass('line-clamp');

            setTimeout(function() {
                $('.product-subtitle').addClass('line-clamp');
            }, 100);
        }, 200);
    }
}

/*
* Function to handle the video play/pause
*/
function handleVideoPlay(slick, currentIndex, newIndex) {
    var prevSlideElement = $(slick.$slides.get(currentIndex));
    var prevvideoElement = prevSlideElement.find('video')[0];

    var currentSlideElement = $(slick.$slides.get(newIndex));
    var videoElement = currentSlideElement.find('video')[0];

    if (videoElement) {
        videoElement.play();
        videoElement.muted = true;
        handleVideoControls(currentSlideElement);
    }

    if (prevvideoElement) {
        prevvideoElement.pause();
        prevvideoElement.muted = true;
        handleVideoControls(prevSlideElement);
    }
}

/**
 * Function to handle the video controls on sliding - play and muted
 */
function handleVideoControls(element) {
    var $this = element;

    var muteBtn = $this.find('.pdp-video-controls .muteUnmute');
    var playBtn = $this.find('.pdp-video-controls .playPause');

    muteBtn.removeClass('unmuted').addClass('muted');
    playBtn.removeClass('play').addClass('pause');
}