import Dropdown from '../common/Dropdown';
import $ from 'jquery';
import eventMgr from '../eventMgr';
import screendetector from 'screendetector';
import 'cart';

const emitter = eventMgr.getEmitter('minicart'),
    FLYOUT_CLASS = '.js-minicart-flyout',
    HIDDEN = 'hidden',
    HIDE_DELAY = 5000;

const timer = {
    id: null,
    clear: function () {
        if (this.id) {
            window.clearTimeout(this.id);
            delete this.id;
        }
    },
    start: function (duration, callback) {
        this.id = setTimeout(callback, duration);
    }
};

export default class Minicart extends Dropdown {
    init () {
        super.init();

        this.offset = 0;
        this.$minicart = this.$el.find(FLYOUT_CLASS);

        this.event('click', '.js-minicart-button', this.onButtonClick);
        this.event('mouseenter', '.js-minicart-button', this.onButtonMouseEnter);
        this.event('click', '.paypal-button', this.sendGADataPayPal);
        this.event('click', '.dw-apple-pay-button', this.sendGADataApplePay);
        this.event('DOMSubtreeModified', '.mini-cart_content', this.onContentLoad);

        eventMgr.on('cart.minicart.updated', (params) => {
            this.$el.html(params.html);
            /**
             * if it is mobile or tablet only update the minicart html,
             * do not show the flyout on mobile or tablet
             */
            if (params.isMobileClick) return;
            this.$minicart = this.$el.find(FLYOUT_CLASS);
            if (screendetector.getDeviceId() === screendetector.MOBILE) {
                this.setMobileCSS(this.offset);
            }
            if (params.showFlyout && screendetector.getDeviceId() === screendetector.DESKTOP 
                || screendetector.getDeviceId() === screendetector.DESKTOP_NEW) {
                this.showFlyout();
            }
            timer.start(HIDE_DELAY, this.hideFlyout.bind(this));
        });

        eventMgr.on('cart.updated', () => {
            eventMgr.execute('cart.minicart.get', {
                url: this.config.minicartSource
            });
        });

        eventMgr.on('Header.headerHeight', (offset) => {
            if (screendetector.getDeviceId() === screendetector.MOBILE) {
                this.setMobileCSS(offset);
                this.offset = offset;
            } else {
                this.offset = 0;
            }
        });

        eventMgr.on('Header.stickyHeight', (offset) => {
            if (screendetector.getDeviceId() === screendetector.MOBILE) {
                this.stickyOffset = offset;
                if (this.dialogOpened) {
                    this.setMobileCSS(this.stickyOffset);
                }
            } else {
                this.stickyOffset = 0;
            }
        });

        this.eventMgr('screenDetector.deviceChange', (newDevice) => {
            if (newDevice !== screendetector.MOBILE) {
                this.clearMobileCSS();
                this.offset = 0;
                this.stickyOffset = 0;
            }
        });

        this.eventMgr('dialog.open', () => {
            this.dialogOpened = true;
            if (screendetector.getDeviceId() === screendetector.MOBILE) {
                this.setMobileCSS(this.stickyOffset);
                this.setSizeMinicart();
            }
        });

        this.eventMgr('dialog.close', () => {
            this.dialogOpened = false;
            if (screendetector.getDeviceId() === screendetector.MOBILE) {
                this.setMobileCSS(this.offset);
            }
        });

        $(window).on('resize', () => {
            if (screendetector.getDeviceId() === screendetector.MOBILE && this.currentOffset > 0) {
                this.setMobileCSS(this.currentOffset);
                this.setSizeMinicart();
            }
        });

        this.event('focusin', (el, event) => {
            event.stopPropagation();
        });

        this.$minicart.on('focusin', (el, event) => {
            event.stopPropagation();
        });

        if (this.hasTouch) {
            this.event('touchstart', this.onTouchStart);
        }
    }

    sendGADataPayPal(el, event) {
        var href = $(el).attr('href');

        event.preventDefault();
        window.dataLayer.push({
            hitType: 'event',
            eventCategory: 'Mini Cart Payment',
            eventAction: 'Express checkout',
            eventLabel: 'Pay Pal Express Checkout'
        });
        window.location = href;
    }

    sendGADataApplePay(el, event) {
        window.dataLayer.push({
            hitType: 'event',
            eventCategory: 'Mini Cart Payment',
            eventAction: 'Express checkout',
            eventLabel: 'Apple Pay Express Checkout'
        });
    }

    onContentLoad() {
        if (window.ApplePaySession && (this.$el.find('isapplepay').length === 0)) {
            if (!this.$el.find('.js-apple-pay-button').hasClass('applepay-button')) {
                this.$el.find('.js-apple-pay-button').addClass('applepay-button');
                this.$el.find('.js-apple-pay-button').removeClass('hidden');
            }
        } else {
            this.$el.find('.js-apple-pay-button').removeClass('applepay-button');
            this.$el.find('.js-apple-pay-button').addClass('hidden');
        }
    }

    onButtonClick () {
        return true;
    }

    onTouchStart () {
        timer.clear();
    }

    onMouseEnter () {
        timer.clear();
        super.onMouseEnter();
    }

    onMouseLeave (el, e) {
        timer.clear();
        this.onHide();
        super.onMouseLeave(el, e);
    }

    onButtonMouseEnter () {
        if (screendetector.getDeviceId() === screendetector.DESKTOP && this.$el.find('.js-mini-cart_total').length) {
            this.onShow();
            this.setSizeMinicart();
        }
    }

    onShow () {
        timer.clear();
        this.$minicart.removeClass(HIDDEN);
        this.setSizeMinicart();
        if (screendetector.getDeviceId() === screendetector.MOBILE) {
            emitter.emit('mobile.open');
        }
    }

    onHide () {
        timer.clear();
        this.$minicart.addClass(HIDDEN);
        emitter.emit('mobile.close');
    }

    setMobileCSS (offset) {
        var newHeight = (window.innerHeight - offset);

        this.$minicart.css({
            'top': offset + 'px',
            'maxHeight': newHeight + 'px'
        });

        this.currentOffset = offset;
    }

    clearMobileCSS () {
        this.$minicart.css({
            'top': '',
            'maxHeight': ''
        });
    }

    setSizeMinicart () {
        const $footer = $(document).find('.footer-sticky');
        const headerHight = $(document).find('.header-initialized').outerHeight();
        const footerMinicartHeight = this.$el.find('.mini-cart_totals').outerHeight();
        const headerMinicartHeight = this.$el.find('.mini-cart_header').outerHeight();
        const offset = 100;
        let footerSize = 0;

        if ($(window).width() > this.tabletBreakpointEnd && !$footer.hasClass('hidden')) {
            footerSize = $footer.outerHeight();
        }

        const distancesSum = footerSize + headerHight + footerMinicartHeight + headerMinicartHeight + offset;
        const productsSectionHeight = $(window).height() - distancesSum;

        this.$el.find('.mini-cart_products').css('max-height', productsSectionHeight);
    }
}
