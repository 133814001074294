import InputGeneric from './InputGeneric';

export default class Label extends InputGeneric {
    init () {
        //super.init(); we don't need init parent here
        if (!this.id) {
            this.id = this.$el.data('id');
        }
        this.$field = this.$el;
    }
    getValue () {
        return this.$el.text();
    }
    setValue (val = '') {
        this.setError();
        return this.$el.text(val);
    }
    setRawValue (val = '') {
        this.setError();
        return this.$el.html(val);
    }
    setError (err = '') {
        if (err) {
            this.$el.addClass('m-error');
            this.$el.text(err);
        } else {
            this.$el.removeClass('m-error');
            this.$el.text('');
        }
    }
    isValid () {
        return true;
    }
    validate () {
        return true;
    }
}
