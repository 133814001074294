'use strict';

import {Promise as Promise} from 'es6-promise-polyfill';
import $ from 'jquery';
import _get from 'lodash/get';
import _escape from 'lodash/escape';
import resources from 'resources';

var cachedScriptPromises = {};

const util = {
    /**
     * @function
     * @description appends the parameter with the given name and value to the given url and returns the changed url
     * @param {String} url the url to which the parameter will be added
     * @param {String} name the name of the parameter
     * @param {String} value the value of the parameter
     */
    appendParamToURL: function (url, name, value) {
        var urlParts = url.split('#'),
            newUrl = urlParts[0];
        // if the param already exists
        var pattern = new RegExp('\\b(' + name + '=).*?(&|$)');

        if (newUrl.search(pattern) >= 0) {
            // replace it
            newUrl = newUrl.replace(pattern, '$1' + value + '$2');
        } else {
            var separator = newUrl.indexOf('?') !== -1 ? '&' : '?';

            newUrl = newUrl + separator + name + '=' + encodeURIComponent(value);
        }
        if (urlParts.length > 1) {
            newUrl = newUrl + '#' + urlParts[1];
        }
        return newUrl;
    },

    // general extension functions
    format: function () {
        var s = arguments[0],
            len = arguments.length - 1,
            i,
            reg;

        for (i = 0; i < len; i++) {
            reg = new RegExp('\\{' + i + '\\}', 'gm');
            s = s.replace(reg, arguments[i + 1]);
        }
        return s;
    },

    /**
     * @function
     * @description return the parameters and its value from the given url
     * @param {String} url the url from which the parameter will be returned
     * @param {String} name of parameter that will be returned from url
     */
    getParamFromURL: function (url, name) {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)')
            .exec(url) || ['', ''])[1].replace(/\+/g, '%20')) || null;
    },
    /**
     * @function
     * @description remove the parameter and its value from the given url and returns the changed url
     * @param {String} url the url from which the parameter will be removed
     * @param {String} name the name of parameter that will be removed from url
     */
    removeParamFromURL: function (url, name) {
        if (url.indexOf('?') === -1 || url.indexOf(name + '=') === -1) {
            return url;
        }
        var hash,
            params,
            domain = url.split('?')[0],
            paramUrl = url.split('?')[1],
            newParams = [],
            i;

        // if there is a hash at the end, store the hash
        if (paramUrl.indexOf('#') > -1) {
            hash = paramUrl.split('#')[1] || '';
            paramUrl = paramUrl.split('#')[0];
        }
        params = paramUrl.split('&');
        for (i = 0; i < params.length; i++) {
            // put back param to newParams array if it is not the one to be removed
            if (params[i].split('=')[0] !== name) {
                newParams.push(params[i]);
            }
        }
        return domain + '?' + newParams.join('&') + (hash ? '#' + hash : '');
    },

    /**
     * @function
     * @description appends the parameters to the given url and returns the changed url
     * @param {String} url the url to which the parameters will be added
     * @param {Object} params
     */
    appendParamsToUrl: function (url, params) {
        $.each(params, (name, value) => {
            url = util.appendParamToURL(url, name, value);
        });
        return url;
    },
    /**
     * @function
     * @description extract the query string from URL
     * @param {String} url the url to extra query string from
     **/
    getQueryString: function (url) {
        var qs,
            a;

        if ($.type(url) !== 'string') { return; }
        a = document.createElement('a');
        a.href = url;
        if (a.search) {
            qs = a.search.substr(1); // remove the leading ?
        }
        return qs;
    },
    /**
     * @function
     * @description
     * @param {String}
     * @param {String}
     */
    elementInViewport: function (el, offsetToTop) {
        var top = el.offsetTop,
            left = el.offsetLeft,
            width = el.offsetWidth,
            height = el.offsetHeight;

        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        if (typeof(offsetToTop) !== 'undefined') {
            top -= offsetToTop;
        }

        if (window.pageXOffset !== null) {
            return (
                top < (window.pageYOffset + window.innerHeight) &&
                left < (window.pageXOffset + window.innerWidth) &&
                (top + height) > window.pageYOffset &&
                (left + width) > window.pageXOffset
            );
        }

        if (document.compatMode === 'CSS1Compat') {
            return (
                top < (window.document.documentElement.scrollTop + window.document.documentElement.clientHeight) &&
                left < (window.document.documentElement.scrollLeft + window.document.documentElement.clientWidth) &&
                (top + height) > window.document.documentElement.scrollTop &&
                (left + width) > window.document.documentElement.scrollLeft
            );
        }
    },

    /**
     * @function
     * @description Appends the parameter 'format=ajax' to a given path
     * @param {String} path the relative path
     */
    ajaxUrl: function (path) {
        return this.appendParamToURL(path, 'format', 'ajax');
    },

    /**
     * @function
     * @description
     * @param {String} url
     */
    toAbsoluteUrl: function (url) {
        if (url.indexOf('http') !== 0 && url.charAt(0) !== '/') {
            url = '/' + url;
        }
        return url;
    },
    /**
     * @function
     * @description Loads css dynamically from given urls
     * @param {Array} urls Array of urls from which css will be dynamically loaded.
     */
    loadDynamicCss: function (urls) {
        var i, len = urls.length;

        for (i = 0; i < len; i++) {
            this.loadedCssFiles.push(this.loadCssFile(urls[i]));
        }
    },

    /**
     * @function
     * @description Loads css file dynamically from given url
     * @param {String} url The url from which css file will be dynamically loaded.
     */
    loadCssFile: function (url) {
        return $('<link/>').appendTo($('head')).attr({
            type: 'text/css',
            rel: 'stylesheet'
        }).attr('href', url); // for i.e. <9, href must be added after link has been appended to head
    },
    // array to keep track of the dynamically loaded CSS files
    loadedCssFiles: [],

    /**
     * @function
     * @description Removes all css files which were dynamically loaded
     */
    clearDynamicCss: function () {
        var i = this.loadedCssFiles.length;

        while (0 > i--) {
            $(this.loadedCssFiles[i]).remove();
        }
        this.loadedCssFiles = [];
    },
    /**
     * @function
     * @description Extracts all parameters from a given query string into an object
     * @param {String} qs The query string from which the parameters will be extracted
     */
    getQueryStringParams: function (qs) {
        if (!qs || qs.length === 0) { return {}; }
        var params = {},
            unescapedQS = decodeURIComponent(qs);
        // Use the String::replace method to iterate over each
        // name-value pair in the string.

        unescapedQS.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
             ($0, $1, $2, $3) => {
                 params[$1] = $3;
             }
        );
        return params;
    },

    fillAddressFields: function (address, $form) {
        for (var field in address) {
            if (field === 'ID' || field === 'UUID' || field === 'key') {
                continue;
            }
            // if the key in address object ends with 'Code', remove that suffix
            // keys that ends with 'Code' are postalCode, stateCode and countryCode
            $form.find('[name$="' + field.replace('Code', '') + '"]').val(address[field]);
            // update the state fields
            if (field === 'countryCode') {
                $form.find('[name$="country"]').trigger('change');
                // retrigger state selection after country has changed
                // this results in duplication of the state code, but is a necessary evil
                // for now because sometimes countryCode comes after stateCode
                $form.find('[name$="state"]').val(address.stateCode);
            }
        }
    },
    /**
     * @function
     * @description Updates the number of the remaining character
     * based on the character limit in a text area
     */
    limitCharacters: function () {
        $('form').find('textarea[data-character-limit]').each(function () {
            var characterLimit = $(this).data('character-limit');
            var charCountHtml = String.format(resources.CHAR_LIMIT_MSG,
                '<span class="char-remain-count">' + characterLimit + '</span>',
                '<span class="char-allowed-count">' + characterLimit + '</span>');
            var charCountContainer = $(this).next('div.char-count');

            if (charCountContainer.length === 0) {
                charCountContainer = $('<div class="char-count"/>').insertAfter($(this));
            }
            charCountContainer.html(charCountHtml);
            // trigger the keydown event so that any existing character data is calculated
            $(this).change();
        });
    },
    /**
     * @function
     * @description Binds the onclick-event to a delete button on a given container,
     * which opens a confirmation box with a given message
     * @param {String} container The name of element to which the function will be bind
     * @param {String} message The message the will be shown upon a click
     */
    setDeleteConfirmation: function (container, message) {
        $(container).on('click', '.delete', () =>
            /* eslint-disable no-alert */
            window.confirm(message)
            /* eslint-enable no-alert */
        );
    },
    /**
     * @function
     * @description Built in template engine. Usage example: template('<div>{{ x.sub }}</div>', {x:{sub:'test'}})
     * @param {String} html as sting
     * @param {Object} data, used in template
     */
    template: function (templateString, data) {
        data = data || {};
        return (templateString || '').replace(/\{\{([^\}]+)\}\}/g, (match, attr) =>
            _escape(_get(data, attr.trim(), ''))
        );
    },
    isMobile: function () {
        var mobileAgentHash = [
            'mobile',
            'tablet',
            'phone',
            'ipad',
            'ipod',
            'android',
            'blackberry',
            'windows ce',
            'opera mini',
            'palm'
        ];
        var idx = 0;
        var isMobile = false;
        var userAgent = (navigator.userAgent).toLowerCase();

        while (mobileAgentHash[idx] && !isMobile) {
            isMobile = (userAgent.indexOf(mobileAgentHash[idx]) >= 0);
            idx++;
        }
        return isMobile;
    },
    // jscs:disable disallowIdentifierNames
    // jshint ignore:start
    log: window.console,
    Alert: (...args) => {
        /* eslint-disable no-alert */
        window.alert(...args);
        /* eslint-enable no-alert */
    },
    // jshint ignore:end
    // jscs:disable disallowIdentifierNames
    loadScript: function loadScript(source) {
        return new Promise((resolve, reject) => {
            var script = document.createElement('script'),
                prior = document.getElementsByTagName('script')[0];

            script.async = true;
            script.type = 'text/javascript';
            prior.parentNode.insertBefore(script, prior);

            script.onload = script.onreadystatechange = function (_, isAbort) {
                if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
                    script.onload = script.onreadystatechange = null;
                    script = undefined;

                    if (isAbort) {
                        reject();
                    } else {
                        resolve();
                    }
                }
            };
            script.src = source;
        });
    },

    cachedGetScript: function (url) {
        if (!cachedScriptPromises[url]) {
            cachedScriptPromises[url] = $.Deferred((defer) => $.getScript(url)
                    .then(defer.resolve, defer.reject)).promise();
        }
        return cachedScriptPromises[url];
    },

    getTilePosition: function(el) {
        var $tile = $(el).closest('.search-result_item');
        var $list = $('#search-result-items').find('.search-result_item');

        return $list.index($tile) + 1;
    },

    setCookie: function (key, value, minutes) {
        var date = new Date();

        date.setMinutes(date.getMinutes() + minutes);

        document.cookie = key + '=' + value + '; expires=' + date.toGMTString() + '; path=/';
    },

    getCookie: function (cookieName) {
        var cookies = document.cookie.split(';');

        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();

            if (cookie.startsWith(cookieName + '=')) {
                return true;
            }
        }

        return false;
    },

    setCrossSubdomainCookie: function (key, value, minutes) {
        var expires = '';
        if (minutes){
            const date = new Date();
            date.setMinutes(date.getMinutes() + minutes);
            expires = 'expires=' + date.toGMTString() + ';'
        }
        const domain = 'domain=' + (document.domain.match(/[^\.]*\.[^.]*$/)[0]) + ';';
        document.cookie = key + '=' + value + ';' + expires + 'path=/;' + domain;
    },

    /**
     * @description funtion that check storage Availability on window object
     * @param {String} storage type(name)
     * @return {Boolean}
     */
    storageAvailable: function (type) {
        var storage = [];
        try {
            storage = window[type];
            var test = '__storage_test__';
            storage.setItem(test, test);
            storage.removeItem(test);
            return true;
        } catch (e) {
            return e instanceof DOMException && (
                // everything except Firefox
                e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === 'QuotaExceededError' ||
                // Firefox
                e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
                // acknowledge QuotaExceededError only if there's something already stored
                storage.length !== 0;
        }
    },

    /**
     * @function
     * @description Updates the GTM DataLayer when changes are detected
     */
    updateGTM: function(dialog) {
        try {
            if (typeof dataLayerArr != 'undefined') {
                var dataLayer = window.dataLayer || [];
                for (var i = 0; i < dataLayerArr.length; i++) {
                    // modify dataLayer to include user data so it is not cached
                    var currDataLayer = dataLayerArr[i];

                    if (typeof currDataLayer == 'object') {
                        var gtmEvent = currDataLayer['event'];
                        if (gtmEvent === 'productImpressions' && dialog) {
                            return;
                        }
                        var gtagUserData = document.getElementsByClassName('gtm-user-data')[0].getAttribute('data-gtag') || '';
                        var gtagPageData = JSON.parse(document.getElementsByClassName('gtm-page-data')[0].getAttribute('data-gtag') || {});
                        var gtagFunnelData = document.getElementsByClassName('gtm-funnel-data')[0].getAttribute('data-gtag') || '';
                        var gtagCountryData = document.getElementsByClassName('gtm-country-data')[0].getAttribute('data-gtag') || '';

                        currDataLayer['user.id'] = gtagUserData;

                        // Update page information for carousel impressions as they are async and it's missing
                        if (gtmEvent === 'productImpressions') {
                            currDataLayer['funnelPageType'] = gtagFunnelData;
                            currDataLayer['countryId'] = gtagCountryData;
                            currDataLayer['page'] = gtagPageData;
                        }

                        // Update the action field list value for PDP
                        if (gtmEvent === 'productView' || gtmEvent === 'quickView') {
                            var listValue;
                            var initialList = document.getElementsByClassName('gtm-list-data')[0];
                            var initialListValue = initialList.value;

                            if ('gtmListValue' in sessionStorage) {
                                listValue = sessionStorage.getItem('gtmListValue');
                                sessionStorage.removeItem('gtmListValue');
                            } else {
                                listValue = initialListValue;
                            }

                            initialList.value = listValue;

                            currDataLayer.ecommerce.detail.actionField.list = listValue;
                        }

                        if (gtmEvent === 'Customer Data') {
                            var gtagCurrencyData = document.getElementsByClassName('gtm-currency-data')[0].getAttribute('data-gtag') || '';
                            currDataLayer.ecommerce.currencyCode = gtagCurrencyData;
                        }
                    }

                    dataLayer.push(currDataLayer);
                }
            }
        } catch (err) {
            console.error('Error sending DataLayer: ' + err.message);
        }
    },

     /**
     * @function
     * @description Waits for an element to be loaded
     * @param {String} selector needed element
     * @param {Function} callback the function that needs to be executed after element load
     * @param {Integer} interval waiting time(ms)
     */
    waitForEl: (selector, callback, interval) => {
        if ($(selector).length) {
            callback();
        } else {
            setTimeout(function() {
                util.waitForEl(selector, callback, interval);
            }, interval);
        }
    },

    /**
     * @function
     * @description Waits for a dialog to be open
     * @param {jquery-ui/dialog} dialog needed dialog
     * @param {Function} callback the function that needs to be executed after dialog is open
     * @param {Integer} interval waiting time(ms)
     */
    waitForDialog: (dialog, callback, interval) => {
        if (dialog.isOpen()) {
            callback();
        } else {
            setTimeout(function() {
                util.waitForDialog(dialog, callback, interval);
            }, interval);
        }
    },

    /**
     * @function
     * @description Waits for carousel to be initialized
     * @param {Jquery element} container for the carousel
     * @param {Function} callback
     * @param {Integer} interval
     */
    waitForCarouselToInitialize: (container, callback, interval) => {
        if (container.find('.tiles-container').hasClass('slick-initialized')) {
            callback();
        } else {
            setTimeout(() => {
                util.waitForCarouselToInitialize(container, callback, interval)
            }, interval);
        }
    },

    /**
     * Check if given element is in the viewport.
     * @param {DOM Element} element given element
     * @returns {Boolean} true/false
     */
    isInViewport(element) {
        var rect = element.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    },
    /**
     * Refresh yotpo widgets
     */
    refreshYotpoWidget() {
        setTimeout(() => {
            var id = $('#yotpo-bottomline-top-div').data('productId');
            for (let i = 0, len = yotpo.widgets.length; i < len; i++) {
                if ($(yotpo.widgets[i].element).attr('data-product-id')) {
                    $(yotpo.widgets[i].element).attr('data-product-id', id);
                    $(yotpo.widgets[i].element).attr('data-url', location.origin + location.pathname);
                }
            }
            var api = new Yotpo.API(yotpo);
            api.refreshWidgets();
        }, 1000);
    },

    /**
     * Updates reviews stars and if there are no reviews for product, hiddes reviews view.
     */
    updateReviewsView() {
        util.waitForEl('.yotpo-bottomline > a', function() {
            var reviewsNumber = parseInt($('.yotpo-bottomline > a').text());

            if (reviewsNumber) {
                $('.accordion-title.yopto.reviews').closest('.accordion-list').removeClass('hidden');
                $('.yotpo-bottomline > a').text('(' + reviewsNumber + ')');
                $('.write-question-review-button-text').addClass('hidden');
            } else {
                $('.accordion-title.yopto.reviews').closest('.accordion-list').addClass('hidden');
            }
        }, 500);
    },

    /**
     * updating the product/model switching in the PLP page - keeping the selected grid layout
     */
    updateViewMode(selectedOption, start) {
        if (!selectedOption) {
            selectedOption = $('.plp-viewmode_list--mobile').find(':selected').attr('data-value');
        }

        if (selectedOption == 'model'){
            $('.search-result_items').addClass('view-mode-model');
            $('.search-result_items').removeClass('view-mode-product');
        } else {
            $('.search-result_items').addClass('view-mode-product');
            $('.search-result_items').removeClass('view-mode-model');
        }

        var tabletBreakpointEnd = 1366;
        var windowWidth = $(window).width();
        if (windowWidth < tabletBreakpointEnd) { // mobile/tablet
            selectedOption = $('.plp-viewmode_list--mobile').find(':selected').attr('data-value');
            $('.search-result_item').each(function(index) {
                if (selectedOption == 'model'){
                    $(this).find('.produt-images-mobile').slick('slickGoTo', 1);
                } else {
                    $(this).find('.produt-images-mobile').slick('slickGoTo', 0);
                }
            });
        } else { // desktop
            selectedOption = $('.plp-viewmode_value').text();
            var selectedOptionName = $('.plp-viewmode_value').attr('data-value-name');
            var productImg;
            var modelImg;

            $(this).find('.not-roundend').removeClass('lazy');
            $(this).find('.main-image').removeClass('lazy');

            $('.plp-viewmode_label').text(selectedOptionName);
            $('.search-result_item').each(function(index) {
                if (index < start) {
                    return;
                }

                if (selectedOption == 'model'){
                    productImg = $(this).find('.product-link.main > picture').html();
                    modelImg = $(this).find('.product-image_hover').find('.item-list:first-child').find('picture').html();

                    $(this).find('.product-link.main > picture').html(modelImg);
                    $(this).find('.product-image_hover').find('.item-list:first-child').find('picture').html(productImg);
                } else {
                    productImg = $(this).find('.product-link.main > picture').html();
                    modelImg = $(this).find('.product-image_hover').find('.item-list:first-child').find('picture').html();

                    $(this).find('.product-link.main > picture').html(productImg);
                    $(this).find('.product-image_hover').find('.item-list:first-child').find('picture').html(modelImg);
                }
            });

            var gridCount = $('.grid-mode').find('.clicked').attr('data-grid');

            if (gridCount == 2) {
                $('.search-result_items').removeClass('js-mobile-two-per-row js-desktop-four-per-row').addClass('js-mobile-one-per-row js-desktop-two-per-row');
            } else {
                $('.search-result_items').removeClass('js-mobile-one-per-row js-desktop-two-per-row').addClass('js-mobile-two-per-row js-desktop-four-per-row');
            }
        }
    },

    /**
     * update url with gridcount
     */
    updateGridurl(currenturl, gridCount) {
        if (!gridCount){
            gridCount = $('.grid-mode').find('.clicked').attr('data-grid');
        }
        var currenturlparts = currenturl.split('?');
        if (currenturlparts.length >= 2){
            currenturl = this.removeParamFromURL(currenturl, 'gc');
            if (gridCount){
                currenturl = currenturl + '&gc=' + gridCount;
            }
        } else {
            currenturl = currenturl + '?gc=' + gridCount;
        }
        return currenturl;
    }
};

module.exports = util;
