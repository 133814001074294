import Component from '../Component';
import scrollUtil from 'scrollUtil';
import $ from 'jquery';


export default class InputGeneric extends Component {
    init () {
        this.event('change', '.js-input_field', this.update);
        this.event('focusout', '.js-input_field', this.focusOut);
        this.event('paste', '.js-input_field', () => {
            setTimeout(this.update.bind(this), 10);
        });
        this.$field = this.$el.find('.js-input_field');

        var errorId = this.$el.data('cmp-id') + '_error';

        this.$el.find('.form-row_field').append('<div id="' + errorId + '" class="form-row_error"></div>');
        this.$field.attr('aria-describedby', errorId);
        
        this.initValue = this.getValue();
        if (!this.id && this.$field.first().attr('name')) {
            this.id = this.$field.first().attr('name');
        }
        this.shown = true;
        this.disabled = false;
        this.skipValidation = false;
    }
    getValue () {
        return this.$field.val();
    }
    getName () {
        if (this.shown && this.isValid()) {
            return this.$field.attr('name');
        }
    }
    setFocus () {
        scrollUtil.scrollTo(this.$el, () => {
            if (this.$field) {
                this.$field.focus();
            }
        });
    }
    setValue (newVal = '', silently = false) {
        this.$field.val(newVal);
        if (!silently) {
            this.update();
        }
    }
    update () {
        if (this.isDurty()) {
            this.$el.addClass('state-durty');
        } else {
            this.$el.removeClass('state-durty');
        }
        this.validate();
        this.emitter.emit('change', this);
    }
    focusOut () {

    	var validateFocusOut = this.$field.data('focusoutvalidation');
    	
    	if (validateFocusOut) {
    		this.validate();
    	} else {
    		this.update();
    	}
    }
    isDurty () {
        return this.initValue !== this.getValue();
    }
    clearError () {
        this.setError();
    }
    setError (error) {
        this.$el
            .removeClass('state-error').removeClass('form-row_select--unselected')
            .addClass('state-valid')
            .find('.form-row_error').empty();

        if (error) {
            this.$el
                .removeClass('state-valid')
                .addClass('state-error').addClass('form-row_select--unselected')
                .find('.form-row_error')
                .attr('aria-live', 'assertive')
                .append(
                    '<p>' + $('<div>').text(error).html() + '</p>' /*escapes content*/
                );
        }
    }
    isValid (callback) {
        const value = this.getValue() ? (this.getValue() + '').replace(/\r?\n/g, '\r\n') : '';
        var error;

        if (this.config.required && !value) {
            error = this.config.requiredtext;
        }

        if (!error && value) {
            if (this.config.minlength && value.length < this.config.minlength) {
                error = (this.config.minlengthtext + '').replace('{0}', this.config.minlength);
            } else if (this.config.maxlength && value.length > this.config.maxlength) {
                error = (this.config.maxlengthtext + '').replace('{0}', this.config.maxlength);
            } else if (this.config.regEx && !(new RegExp(this.config.regEx, 'ig')).test(value)) {
                error = this.config.parseError;
            } else if (this.cmpToMatch) {
                const cmpt1 = this.getValue(),
                    cmpt2 = this.cmpToMatch.getValue();

                var match = false;

                if (this.cmpToMatchOpts.ignoreCase) {
                    match = (cmpt1 || '').toLowerCase() === (cmpt2 || '').toLowerCase();
                } else {
                    match = cmpt1 === cmpt2;
                }
                if (!match) {
                    error = this.cmpToMatchOpts.message;
                }
            }
        }

        this.error = error;
        if (!error && callback) {
            callback();
        }
        return !error;
    }
    validate (isSkippedNoValidate, fieldID) {
        //added skipping of input validation
        var isSkippedInputValidation = (this.$el.hasClass('js-input-novalidate-default') && !isSkippedNoValidate && !(this.$el.find('#' + fieldID).length));

        if (!this.shown || this.disabled || this.skipValidation || isSkippedInputValidation) {
            return true;
        }
        const valid = this.isValid();

        if (!valid) {
            this.setError(this.error);
        } else {
            this.setError();
        }
        return valid;
    }
    hide () {
        this.shown = false;
        this.$el.addClass('hidden');
    }
    show () {
        this.shown = true;
        this.$el.removeClass('hidden');
    }
    disable () {
        this.disabled = true;
        this.$field.attr('disabled', true);
    }
    enable () {
        this.disabled = false;
        this.$field.attr('disabled', false);
    }
    setReadonly () {
        this.$field
            .on('focus', function () {
                $(this).trigger('blur');
            })
            .prop('readonly', true);
    }
    isDisabled () {
        return this.disabled;
    }
    setMatchCmp (cmpToMatch, options = {}) {
        this.cmpToMatch = cmpToMatch;
        this.cmpToMatchOpts = options;
        this.cmpToMatch.on('change', () => {
            if (this.isDurty()) {
                this.validate();
            }
        });
    }
    skipSubmission () {
        return false;
    }
    setSkipValidation (value) {
        this.skipValidation = !!value;
    }
    isSkipValidation () {
        return this.skipValidation;
    }
}
