import InputGeneric from './InputGeneric';

export default class InputText extends InputGeneric {
    init () {
        super.init();
        this.event('keyup', '.js-input_field', this.onKeyUp);
    }
    onKeyUp () {
        this.emitter.emit('keyup', this);
    }
}
