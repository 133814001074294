import Block from './Block';
import eventMgr from 'eventMgr';
import _debounce from 'lodash/debounce';
import $ from 'jquery';
import util from 'utils';
import urls from 'urls';
import 'search';

const emitter = eventMgr.getEmitter('SearchSuggestion'),
    delay = 500;

export default class SearchSuggestion extends Block {
    init () {
        super.init();
        this.changeField = _debounce((query) => {
            this.onSuggest(query);
        }, delay);
        this.onFocusOut = _debounce(() => {
            this.clearResults();
        }, 200);

        this.eventMgr('InputSearch.changeField', this.changeField);
        this.eventMgr('InputSearch.controlClick', this.clearResults);
        this.eventMgr('InputSearch.onReset', this.clearResults);
        this.eventMgr('hamburgermenu.close', this.clearResults);
    }

    onSuggest (query) {
        // if it's empty clear the resuts box and return
        if (!query.length) {
            this.clearResults();
            return;
        }

        emitter.emit('onSuggest');
        var reqUrl = util.appendParamToURL(urls.searchsuggest, 'q', query);

        eventMgr.execute('search.getSuggest', {
            url: reqUrl
        }).then((data) => {
            this.setResult(data);
        });
    }

    clearResults () {
        this.getById('resultsContainer', (resultsContainer) => {
            resultsContainer.setHtml();
            this.$el.hide();
            emitter.emit('close');
        });
    }

    setResult (html) {
        this.getById('resultsContainer', (resultsContainer) => {
            resultsContainer.setHtml(html);
            if ($.trim(html)) {
                this.$el.show();
                emitter.emit('open');
            } else {
                this.clearResults();
            }
        });
    }
}
