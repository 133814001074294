import Block from './Block';
import $ from 'jquery';

export default class InfinityScroll extends Block {
    init () {
        super.init();
        $('.js-loadmorebtn').hide(); // If InfinityScroll is enabled hide the load more button

        let self = this;
        $(document).ready( function () {
            self.refresh();
        });
    }
    refresh () {
        /**
         * replacing jquery infinite scroll with new browser api IntersectionObserver
         * for smoother expirience and more accurate event handling, the IntersectionObserver
         * is not supported by some old browsers like IE9 so will add also jQuery support
         * for older browsers
         */
        if ("IntersectionObserver" in window) {
            console.log('supported');
            let callback = (entries, observer) => {
                if (entries.some(entry => entry.isIntersecting)) {
                    this.triggerProductLoading();
                }
            };


            let observer = new IntersectionObserver(callback);
            let target = document.querySelector('.search-result_bottom');
            observer.observe(target);
        } else {
            $('.search-result_bottom').viewportChecker({
                callbackFunction: function (elem, action) {
                    this.triggerProductLoading();
                },
                repeat: true
            });
        }
    }

    triggerProductLoading() {
        let $button = $('.js-loadmorebtn');
        let $loadingWrapper = $('.loading-wrapper');
        $button.trigger('click');

        var lastProduct = $('.search-result_item').hasClass('last-product');
        if (!lastProduct) {
            $loadingWrapper.removeClass('hidden');
        }
    }
}