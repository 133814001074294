import Component from '../Component';
import $ from 'jquery';
import eventMgr from 'eventMgr';
import utils from 'utils';
import screendetector from '../screendetector';
import scrollUtil from 'scrollUtil';
import 'grid';
import FilterMobileCarousel from '../common/FilterMobileCarousel';
import util from 'utils';

const emitter = eventMgr.getEmitter('Refinements');

export default class Refinements extends Component {
    init() {
        super.init();

        this.states = {
            isOpened: false
        };

        this.changeStates();

        this.event('click', '.js-main-refinement-title', this.onBarOpened);
        this.event('click', '.' + this.config.triggerclass, this.onRefinementClick);
        this.event('click', '.js-clear-refinement', this.clearRefinement);
        this.event('click', '.js-refinement-title', this.onToggle);
        this.event('click', '.js-applyrefilters', this.applyFilters);
        this.eventMgr(`grid.updated.${this.config.cmpId}`, this.refresh);
        this.event('click', '.js-refinements-more', this.refinemetMoreLess);
        this.event('click', '.close-icon-filter', this.onOutsideClick);
        this.event('mouseenter', '.js-refinement-block', this.toggleClassDesktop);
        this.event('click', '.refinement-clear_button', this.hideFilter);

        this.appendBreadCurmb();

        if (!this.config.skipOutsideClick) {
            this.eventMgr('Refinements.outsideClick', this.onOutsideClick);
        }

        var windowWidth = $(window).width();
        var tabletBreakpoint = this.tabletBreakpointEnd;

        $(window).on('resize', (e) => {
            var windowResizeWidth = $(window).width();

            if (windowWidth != windowResizeWidth) {
                this.setFilterBodyHeight(e);
                this.toggleClassDesktop(e);
                windowWidth = windowResizeWidth;
            }
        });

        this.removeEmptyfilterComponent();

        $(window).on('load', () => {
            this.removeEmptyfilter();
            this.toggleClassDesktop();
        });
        this.updateFilterCount(tabletBreakpoint);
        this.appendSlickCarousel(tabletBreakpoint);
    }

    onOutsideClick(e) {
        var windowWidth = $(window).width();
        var tabletBreakpoint = this.tabletBreakpointEnd;

        if ($('.close-icon-filter').hasClass('opend')) {
            this.states.isOpened = true;
        }

        if (windowWidth <= tabletBreakpoint) {
            if (this.states.isOpened && !this.$el.is(e.target) && // if the target of the click isn't the container...
                this.$el.has(e.target).length === 0) { // ... nor a descendant of the container
                this.states.isOpened = false;
                this.applyStates();
                $('body').css('position', 'static');
                $('.search-result_options').removeClass('opend');
                $('.close-icon-filter').removeClass('opend');
                $('.refinements-and-sort .refinements').addClass('refinements-closed').removeClass('refinements-opened');
            }
        }
    }

    hideFilter() {
        $('.refinement-gropping .refinement-item').each(function () {
            $(this).removeClass('refinement-item_selected');
        });
        $('.search-result_options').removeClass('opend');
        $('body').css('position', 'static');
        $('.close-icon-filter').removeClass('opend');
    }

    appendBreadCurmb() {
        if ($('.breadcrumb-refinement').length > 0) {
            $('.breadcrumb-desktop').append($('.breadcrumb-wrapper.desktop'));
            $('.search-result_options .breadcrumb-wrapper.desktop').remove();
        }

    }

    onBarOpened() {
        var tabletBreakpoint = this.tabletBreakpointEnd;

        this.$el.toggleClass(this.config.closedClass + ' ' + this.config.opennedClass);
        this.states.isOpened = !this.states.isOpened;
        this.setFilterBodyHeight();

        if ($('.refinement-item, .refinement-swatch').hasClass('refinement-item_selected')) {
            $('.refinement-apply_button').addClass('active');
        } else {
            $('.refinement-apply_button').removeClass('active');
        }

        $('.search-result_options').removeClass('nav-up').addClass('opend nav-down');
        $('.header').removeClass('scrolled-down nav-up').addClass('nav-down');
        $('.close-icon-filter').addClass('opend');

        this.removeEmptyfilterComponent();
        this.removeEmptyfilter();
        this.updateFilterCount(tabletBreakpoint);
    }

    toggleClassDesktop(event) {
        var windowWidth = $(window).width();

        if (windowWidth >= this.tabletBreakpointEnd) {
            $('.refinements-and-sort .refinements').addClass('refinements-opened')
                .removeClass('refinements-closed').attr('data-closed-class', 'refinements-opened');
            $('.js-refinement-block').addClass('refinements-opened').removeClass('refinements-closed');

            $('.search-result_options').removeClass('opend');
            $('.close-icon-filter').removeClass('opend');
            $('body').css('position', 'static');
        } else {
            if (event && event.type === 'resize') {
                $('body').css('position', 'static');
                $('.search-result_options').removeClass('opend');
            }
        }
    }

    onToggle(el, ev) {
        ev.preventDefault();
        this.toggleClassDesktop();

        var $el = $(el),
            $parentToggle = $el.parents('.js-toggle').first(),
            windowWidth = $(window).width();

        this.setFilterBodyHeight();

        if (windowWidth < this.tabletBreakpointEnd) {
            if (!$parentToggle.hasClass('js-refinement-block')) {
                $parentToggle.toggleClass(this.config.closedClass + ' ' + this.config.opennedClass);
                return false;
            }

            this.$el.find('.js-toggle.' + this.config.opennedClass).not($parentToggle)
                .removeClass(this.config.opennedClass)
                .addClass(this.config.closedClass);

            $parentToggle.toggleClass(this.config.closedClass + ' ' + this.config.opennedClass);

            sessionStorage.setItem('refinementOpened', $parentToggle.data('refinement-id'));

            if ($parentToggle.hasClass('refinements-opened')) {
                $parentToggle.closest('.refinements').addClass('refinements-opened').removeClass('refinements-closed');
            }

            $parentToggle.find('.js-item-extra').addClass('refinement-item_extra');
            $parentToggle.find('.js-refinements-more').removeClass('hidden');


            this.changeStates();
        }
        return false;
    }

    setFilterBodyHeight(event) {
        var windowWidth = $(window).width();

        if (windowWidth <= this.tabletBreakpointEnd) {
            var filterHeight = '',
                fiterHeadFootHeight = $(window).height()
                    - ($('.refinements-header-filter').height() + $('.filter-footer').height());

            filterHeight = fiterHeadFootHeight;
            $('.refinements-body-filter').css({'overflow-y': 'auto', 'height': filterHeight});
            var emptyBreadcrumb = !$.trim($('.breadcrumb-wrapper').html());

            if (emptyBreadcrumb) {
                $('.breadcrumb-wrapper').remove();
            }

            $('body').css('position', 'fixed');
        } else {
            $('body').css('position', 'static');
            $('.refinements-body-filter').css({'overflow-y': 'initial', 'height': 'auto'});
        }
    }

    removeEmptyfilterComponent() {
        $('.refinement-gropping .gropping-right').each(function () {
            var empty = !$.trim($(this).html());

            if (empty) {
                $(this).closest('.refinement-gropping').remove();
            }
        });

    }

    removeEmptyfilter() {
        $('.refinements-body-filter .js-refinement-block').each(function () {
            var emptyFilter = !$.trim($(this).find('ul').html());

            if (emptyFilter) {
                $(this).remove();
            }
        });
    }

    changeStates() {
        $('.js-toggle').each((i, el) => {
            this.states[$(el).data('refinementId')] = $(el).hasClass(this.config.opennedClass);
        });
    }

    applyStates() {
        if (this.states.isOpened) {
            this.$el.removeClass(this.config.closedClass).addClass(this.config.opennedClass);
        } else {
            this.$el.removeClass(this.config.opennedClass).addClass(this.config.closedClass);
        }

        this.$el.find('.js-toggle').each((i, el) => {
            var $el = $(el);

            if (this.states[$el.data('refinementId')]) {
                $el.removeClass(this.config.closedClass).addClass(this.config.opennedClass);
            } else {
                $el.removeClass(this.config.opennedClass).addClass(this.config.closedClass);
            }
        });
    }

    clearRefinement(el, ev) {
        ev.preventDefault();

        var url = $(el).attr('href');
        var viewMode = $('select.plp-viewmode_list--mobile').find(':selected').attr('data-value');
        var URLParameterToEnableModelViewType = $('.refinements').attr('data-url-parameter-to-enable-mode-viewtype');
        if (viewMode != ''){
            url = utils.removeParamFromURL(url, URLParameterToEnableModelViewType, viewMode);
            var urlParts = url.split('?');

            if (urlParts.length >= 2){
                url = url + '&' + URLParameterToEnableModelViewType + '=' + viewMode;
            } else {
                url = url + '?' + URLParameterToEnableModelViewType + '=' + viewMode;
            }
        }
        var gridCount = $('.grid-mode').find('.clicked').attr('data-grid');

        url = util.appendParamsToUrl(url, {
            'gc': gridCount
        });
        this.getData(url).then(function () {
            var selectedOption = $('.plp-viewmode_list--mobile').find(':selected').attr('data-value');

            util.updateViewMode(selectedOption);
            eventMgr.execute('lazyload.update');
        });
        return false;
    }

    onRefinementClick(el, ev) {
        ev.preventDefault();

        var $el = $(el),
            windowWidth = $(window).width();

        let viewMode = '';

        if ($el.data('refinementValue') === 'clear' || windowWidth >= 320) {
            var attValue = $el.attr('data-refinement-value');
            viewMode = $('select.plp-viewmode_list--mobile').find(':selected').attr('data-value');
            var url = $el.attr('href');
            if (!url) {
                url = $el.data('url');
            }

            if (url.indexOf(attValue) == -1 && $el.data('refinementValue') !== 'clear') {
                let isClosing = $el.parent().hasClass(this.config.selectedRefinementClass);
                let refName = $el.attr('data-refinement-name');

                if (isClosing && refName === 'custitem_customer_facing_name') {
                    url = this.appendRefinementsToUrl(url, isClosing, refName, attValue);
                } else {
                    $el.parent().toggleClass(this.config.selectedRefinementClass);
                    url = this.appendRefinementsToUrl(url);
                }
            }

            var URLParameterToEnableModelViewType = $('.refinements').attr('data-url-parameter-to-enable-mode-viewtype');

            if (viewMode != ''){
                url = utils.removeParamFromURL(url, URLParameterToEnableModelViewType, viewMode);
                var urlParts = url.split('?');

                if (urlParts.length >= 2){
                    if ($el.data('refinementValue') === 'clear'){
                        url = url + URLParameterToEnableModelViewType + '=' + viewMode;
                    } else {
                        url = url + '&' + URLParameterToEnableModelViewType + '=' + viewMode;
                    }
                } else {
                    url = url + '?' + URLParameterToEnableModelViewType + '=' + viewMode;
                }
                url = util.updateGridurl(url);
            }

            var $this = this;

            this.getData(url).then(function() {
                $this.setBannerPosition();
                var selectedOption = $('.plp-viewmode_list--mobile').find(':selected').attr('data-value');

                util.updateViewMode(selectedOption);
            });
        } else {
            $el.parent().toggleClass(this.config.selectedRefinementClass);
        }

        if ($('.refinement-item, .refinement-swatch').hasClass('refinement-item_selected')) {
            $('.refinement-apply_button').addClass('active');
        } else {
            $('.refinement-apply_button').removeClass('active');
        }

        this.emitter.emit('clicked');

        return false;
    }

    refresh(data, openRefinements) {
        var $html = $('<div>' + data + '</div>'),
            $refinements = $html.find('.js-refinements'),
            $breadcrumb = $html.find('.breadcrumb-wrapper.desktop'),
            windowWidth = $(window).width(),
            refinementId = sessionStorage.getItem('refinementOpened'),
            visualRefinements = $html.find('.visual_refinements'),
            $mobileFiltersMenu = undefined;
        var tabletBreakpoint = this.tabletBreakpointEnd;

        $html.find('.refinements-mobile').each(function () {
            if (!$(this).hasClass('menu-mobile')) {
                $mobileFiltersMenu = $(this);
                return false;
            }
        });

        if (refinementId) {
            $refinements.find('[data-refinement-id=\'' + refinementId + '\']').removeClass('refinements-closed').addClass('refinements-opened');
        }

        $('.refinements-and-sort .js-refinements').html($refinements.html());
        $('.breadcrumb-desktop .breadcrumb-wrapper').remove();
        $('.breadcrumb-desktop').append($($breadcrumb));
        if ($html.find('.visual_refinements').hasClass('visual_refinements_hidden')) {
            $('.visual_refinements').hide();
        } else if (!$html.find('.visual_refinements').hasClass('visual_refinements_hidden')) {
            $('.visual_refinements').html(visualRefinements.html());
            $('.visual_refinements').show();
        }

        $('.refinements-mobile').each(function () {
            $(this).removeClass('flex_centered');
            if (!$(this).hasClass('menu-mobile')) {
                $(this).html($mobileFiltersMenu.html());
                return false;
            }
        });

        this.removeEmptyfilterComponent();
        this.removeEmptyfilter();
        this.setFilterBodyHeight();
        this.updateFilterCount(tabletBreakpoint);

        if ($('.refinement-item, .refinement-swatch').hasClass('refinement-item_selected')) {
            $('.refinement-apply_button').addClass('active');
        } else {
            $('.refinement-apply_button').removeClass('active');
        }

        if ($('.search-result_options').hasClass('opend')) {
            $('body').css('position', 'fixed');
        } else {
            $('body').css('position', 'static');
        }

        if (openRefinements) {
            if (windowWidth < this.tabletBreakpointEnd) {
                scrollUtil.scrollTo();
            } else {
                this.states.isOpened = true;
            }
        }
        this.applyStates();
        this.appendSlickCarousel(tabletBreakpoint);
        this.setBannerPosition();
        this.setBreadcrumbColor();
    }

    applyFilters(el, ev) {
        ev.preventDefault();

        var url = this.appendRefinementsToUrl($(el).attr('href'));
        var $this = this;

        this.getData(url, true).then(function() {
            $this.removeEmptyfilterComponent();
            $this.removeEmptyfilter();
            $this.setBreadcrumbColor();
            $this.setBannerPosition();

            var selectedOption = $('.plp-viewmode_list--mobile').find(':selected').attr('data-value');
            util.updateViewMode(selectedOption);
        });

        $('body').css('position', 'static');
        $('.search-result_options').removeClass('opend');
        $('.close-icon-filter').removeClass('opend');
    }

    getData(url, isReplaceStateForMobile) {
        if (isReplaceStateForMobile) {
            this.$el.removeClass(this.config.opennedClass).addClass(this.config.closedClass);
            this.states.isOpened = false;
        }

        return eventMgr.execute('grid.getData', {
            url: url,
            openRefinements: true,
            isReplaceStateForMobile: isReplaceStateForMobile,
            refId: this.config.cmpId
        });
    }

    appendRefinementsToUrl(url, isClosing = false, refName = undefined, refValue = undefined) {
        var refs = {};
        var i = 1;

        if (isClosing && refName === 'custitem_customer_facing_name') {
            var that = this;
            $('[data-refinement-value="' + refValue + '"]').parent().each(function () {
                $(this).removeClass(that.config.selectedRefinementClass);
            })
        }
        $('.' + this.config.selectedRefinementClass + ' .' + this.config.triggerclass).each((i, refEl) => {
            var data = $(refEl).data(),
                name = data.refinementName;

            refs[name] = refs[name] || [];
            if (refs[name].indexOf(data.refinementValue) === -1) {
                refs[name].push(data.refinementValue);
            }
        });

        $.each(refs, (refName, refValue) => {
            url = utils.appendParamToURL(url, 'prefn' + i, refName);
            url = utils.appendParamToURL(url, 'prefv' + i, refValue.join('|'));
            i++;
        });

        return url;
    }

    refinemetMoreLess(el, ev) {
        ev.preventDefault();

        var $el = $(el);

        $el.addClass('hidden');
        $el.parents('.js-refinement-block').find('.js-item-extra').removeClass('refinement-item_extra');
    }

    updateFilterCount(tabletBreakpoint) {
        $('.refinements-body-filter .js-refinement-block').each(function () {
            var refinementId = $(this).data('refinement-id');
            var filterSelectionCount = $(this).find('ul .refinement-item_selected').length;

            if (refinementId === 'custitem_customer_facing_name' && $(this).find('.refinements-mobile .group-container').length > 0) {
                filterSelectionCount = filterSelectionCount / 2;
            }

            if (filterSelectionCount) {
                $(this).find('.refinement-selection-count').css('display', 'inline-block').text(filterSelectionCount)
                .closest('.refinement-title').addClass('selectionCounter');
            }

            // For mobile - to display the filter options having checkbox design in 2 colums if options are 4 or more,  else display in single column
            // For desktop - greater than 8 (display in 8 column multiple rows) - less than 8 (single row)
            var windowWidth = $(window).width();
            var filterCount = $(this).find('ul li').length;

            if (windowWidth <= tabletBreakpoint) {
                if (filterCount < 4) {
                    $(this).find('.refinement-checkbox').closest('ul').css('display','block');
                }
            } else {
                if (filterCount > 8) {
                    $(this).find('.refinement-checkbox').closest('ul').addClass('desktopCheckbox');
                }
            }
        });
    }

    appendSlickCarousel (tabletBreakpoint) {
        var windowWidth = $(window).width();
        var acceptedCount = parseInt(windowWidth / 82); // 82 - is the width of each color swatch
        var slickInit = function () {
            var swatchCount = $('.custitem_primary_colour').find('li').length;

            if (swatchCount > acceptedCount) {
                const filterConfig = {
                    dots: false,
                    infinite: false,
                    speed: 300,
                    autoplay: false,
                    slidesToShow: acceptedCount,
                    slidesToScroll: 5,
                    swipeToSlide: false,
                    rows: 1
                };
                if (windowWidth > tabletBreakpoint) {
                    if ($('.custitem_primary_colour').hasClass('slick-initialized')){
                        $('.custitem_primary_colour')[0].slick.unslick();
                    }

                    $('.custitem_primary_colour').slick(filterConfig);
                }
            }
        }

        $('.type-custitem_primary_colour').closest('.js-refinement-block').off('mouseenter').on('mouseenter', slickInit);
    }

    setBreadcrumbColor() {
        var $breadcrumbColor = $('.plp-banner-wrapper').attr('data-breadcrumb-color');

        if ($breadcrumbColor) {
            $('.js-breadcrumbs-wrap').addClass($breadcrumbColor);
        }
    }

    setBannerPosition() { // set the position of thr PLP top banner
        var $windowWidth = $(window).width();
        var $tabletBreakpoint = this.tabletBreakpointEnd;
        var $headerWrapper = $('.header-wrapper');
        var $headerWrapperHeight = $headerWrapper.innerHeight();
        var $transparentBackgrdOptions = ['fullinverce', 'bgonlyverce'];
        var $headerColor = $('.header.transparent').attr('data-color-variant');

        if ($('.plp-banner-wrapper').length > 0) {
            if ($windowWidth < $tabletBreakpoint) {
                var $breadcrumheight = $('.js-breadcrumbs-wrap').innerHeight();
                if ($('.plp-banner-wrapper').hasClass('no-banner')) {
                    if (($transparentBackgrdOptions.indexOf($headerColor) !== -1)) {
                        var $topHeight = $headerWrapperHeight + $breadcrumheight;
                        $('.plp-banner-wrapper .plp-banner_content').css('padding-top', $topHeight);
                    } else {
                        $('.plp-banner-wrapper .plp-banner_content').css('padding-top', $breadcrumheight);
                    }
                }
            }
        }
    }
}
