/* eslint-disable no-undef */
import Component from '../Component';
import $ from 'jquery';

export default class MagicZoomHelper extends Component {
    init() {
        this.registerCallbacks();
    }

    registerCallbacks () {
        MagicZoom.registerCallback('onExpandOpen', function() {
            $('body').addClass('PLP-zoom-expand');
            $('.product-detail .product-name, .product-name-pdp, .product-detail-inner').addClass('zoom-fixed');
            $('.zooInOut .out').addClass('noactive');
            if ($('.product-image_section_mobile').hasClass('js-active')) {
                $('.zooInOut.mobile').show();
                $('.mz-nav-controls.mz-visible > .mz-button.mz-button-prev').css('visibility', 'hidden');
                $('.mz-nav-controls.mz-visible > .mz-button.mz-button-next').css('visibility', 'hidden');
            } else {
                sessionStorage.setItem('maagicZoomID', arguments[0]);
                $('.zooInOut.desktop').show();
            }

            // set alt to zoom images
            var alt = $('#vertical-carousel-mobile .product-image-mobile img').attr('alt');
            $('.magic-thumb img, .mz-image-stage img').attr('alt', alt);
        });
        MagicZoom.registerCallback('onExpandClose', function() {
            if (!$('.product-image_section_mobile').hasClass('js-active')) {
                MagicZoom.refresh();
            }
            $('.magicZoomContainer a').remove();
            $('body').removeClass('PLP-zoom-expand');
            $('.zooInOut').hide();
            $('.cusrsor-content').hide();
            $('.product-detail .product-name, .product-name-pdp, .product-detail-inner').removeClass('zoom-fixed');
            $('.zooInOut .out').removeClass('noactive');
        });
        MagicZoom.registerCallback('onZoomIn', function() {
            $('.product-detail .product-name, .product-name-pdp, .product-detail-inner').removeClass('zoom-fixed');
            $('.zooInOut .in').addClass('noactive');
            $('.zooInOut .out').removeClass('noactive');
            if (!$('.zooInOut.desktop .in').hasClass('clicked')) {
                $('.zooInOut.desktop .in').trigger('click');
            }
        });
        MagicZoom.registerCallback('onZoomOut', function() {
            $('.product-detail .product-name, .product-name-pdp, .product-detail-inner').addClass('zoom-fixed');
            $('.zooInOut .in').removeClass('noactive');
            $('.zooInOut .out').addClass('noactive');
        });
    }
}
