/* jshint bitwise: false*/
import 'es6shim';

export const check = (cardNumber) => {
    if (cardNumber.indexOf('***********') !== -1) {
        return {
            masked: true
        };
    }

    if (!(/^[0-9]+$/).test(cardNumber)) {
        return false;
    }

    var noLength = cardNumber.length,
        oddoeven = noLength & 1,
        sum = 0;

    for (var i = 0; i < noLength; i++) {
        var digit = parseInt(cardNumber.charAt(i), 10);

        if (!((i & 1) ^ oddoeven)) {
            digit *= 2;

            if (digit > 9) {
                digit -= 9;
            }
        }
        sum += digit;
    }
    return (sum % 10 === 0);
};


const regexToCardsMaping = {
    'bijcard': /^5100081/,
    'jcb': /^(?:2131|1800|35\d{3})\d{11}$/,
    'cup': /^(62[0-9]{14,17})$/,
    'visa': /^4[0-9]{12}(?:[0-9]{3})?$/,
    'cartebancaire': /^(4|5|6)\d{15}/,
    'mc': /^5[1-5][0-9]{14}$/,
    'amex': /^3[47][0-9]{13}$/,
    'diners': /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    //jscs:disable maximumLineLength
    'discover': /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/, // eslint-disable-line max-len
    //jscs:enable maximumLineLength
    'maestro': /^(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}$/
};

export const determine = (cardNumber, allowedTypes) => {
    cardNumber = cardNumber.replace(/[\s-]/g, '');
    allowedTypes = allowedTypes || [];
    if (!allowedTypes.length) {
        allowedTypes = Object.keys(regexToCardsMaping);
    }
    return allowedTypes.find((key) => regexToCardsMaping[key].test(cardNumber)) || 'unknown';
};
