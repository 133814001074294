import AddressForm from './AddressForm';
import debounce from 'lodash/debounce';
import eventMgr from 'eventMgr';
import $ from 'jquery';

var toshiTimeSelected = false;

export default class HomeDelivery extends AddressForm {
    init () {
        this.debounceUpdateShippingmethod = debounce(() => {
            eventMgr.execute('checkout.shippingmethod.update', {
                countryCode: this.country,
                postalCode: this.getPostal(),
                stateCode: this.state,
                city: this.getCity()
            });
        }, 200);

        super.init();

        this.postalCode = null;
        this.city = null;
        eventMgr.on('checkout.shippingmethod.updated', this.onShippingmethodUpdate.bind(this));

        this.onChild(this.config.postalField, 'change', this.updateShippingmethod);
        this.onChild(this.config.cityField, 'change', this.updateShippingmethod);

        /**
         * Toshi Integration
         */
        $('#dwfrm_singleshipping_shippingAddress').on('submit', function(e){
            var isToshiMethod = $('.checkout-shipping_methods').find('[name$="_shippingMethodID"]:checked').data('istoshi');

            if (isToshiMethod && !toshiTimeSelected){
                e.preventDefault();

                if (window.showErrorMessage !== undefined) {
                    window.showErrorMessage();
                }
            } else {
                if (window.hideErrorMessage !== undefined) {
                    window.hideErrorMessage();
                }
            }
        });
    }
    onChangeCountry (countrySelector) {
        super.onChangeCountry(countrySelector);
        this.updateShippingmethod();
    }
    onChangeState (stateSelector) {
        super.onChangeState(stateSelector);
        this.updateShippingmethod();
    }
    updateShippingmethod () {
        this.debounceUpdateShippingmethod();
    }
    onShippingmethodUpdate (data) {
        this.callFnForId('shippingMethodList', 'setHtml', data);
        if (this.$el.find('.js-shipping_method:checked').length === 0) {
            this.$el.find('.js-shipping_method[data-default="true"]').prop('checked', 'checked');
        }

        this.initToshi();
    }
    getPostal () {
        this.getById(this.config.postalField, (postalField) => {
            this.postalCode = postalField.getValue();
        });
        return this.postalCode ? this.postalCode : '';
    }
    getCity () {
        this.getById(this.config.cityField, (cityField) => {
            this.city = cityField.getValue();
        });
        return this.city;
    }
    initToshi () {
        var $form = $('form.address');
        var toshiMethods = $('.checkout-shipping_methods').find('[data-istoshi="true"]');

        if (toshiMethods.length > 0) {
            var method = $(toshiMethods[0]);
            var key = method.data('toshikey');
            var mode = method.data('toshi-type');
            const modal = window.toshi.createBookingIntegration({
                api: {
                    url: toshiClient.toshiModalUrl,
                    key: key
                },
                ui: {
                    mode: mode
                }
            });

            /**
             * This is fired by the ecommerce integration when the customer attempts
             * to proceed without selecting a timeslot
             */
            window.showErrorMessage = () => {
                modal.setInlineErrorMessage(
                    toshiClient.errorMsg
                );
            };

            window.hideErrorMessage = () => {
                modal.setInlineErrorMessage(undefined);
            };

            modal.setFirstName($form.find('input[name$="_firstName"]').val());
            modal.setLastName($form.find('input[name$="_lastName"]').val());
            modal.setPhone($form.find('input[name$="_phone"]').val());

            $form.find('input[name$="_firstName"]').on('change', function () {
                modal.setFirstName($form.find('input[name$="_firstName"]').val());
            });

            $form.find('input[name$="_lastName"]').on('change', function () {
                modal.setLastName($form.find('input[name$="_lastName"]').val());
            });

            $form.find('input[name$="_phone"]').on('change', function () {
                modal.setPhone($form.find('input[name$="_phone"]').val());
            });

            modal.setEmail($form.find('input[name$="_email"]').val());

            modal.setAddress({
                addressLine1: $form.find('input[name$="_address1"]').val(),
                addressLine2: $form.find('input[name$="_address2"]').val(),
                town: $form.find('input[name$="_city"]').val(),
                province: $form.find('select[id$="_country"]').val(),
                postcode: $form.find('input[name$="_postal"]').val(),
                country: $form.find('select[id$="_country"]').val()
            });

            $form.find('input[name$="_email"]').on('change', function () {
                modal.setEmail($form.find('input[name$="_email"]').val());
            });

            modal.setProducts(window.toshiClient.products);
            modal.setBrandCheckoutReference(window.toshiClient.checkoutID);

            modal.mount($('.shipping-method-toshi')[0]);

            modal.onOrderCreated(function (e) {
                toshiTimeSelected = true;
                method.prop('checked', true);
                method.trigger('change');

                if (window.hideErrorMessage !== undefined) {
                    window.hideErrorMessage();
                }
            });
        }
    }
}
