'use strict';

import eventMgr from 'eventMgr';
import ajax from 'ajax';
import urls from 'urls';
import prefs from 'prefs';
import $ from 'jquery';
import dialog from 'dialog';
import screendetector from 'screendetector';
import resources from 'resources';
import util from 'utils';

const emitter = eventMgr.getEmitter('cart');


const isPortable = () => screendetector.getDeviceId() === screendetector.MOBILE ||
    screendetector.getDeviceId() === screendetector.TABLET;

const addToCard = (params) => {
    var url = urls.addProduct;
    var isMobileClick = params.isMobileClick;
    if (params.url) {
        url = params.url;
        delete params.url;
    }
    return ajax({
        data: params,
        url: url,
        type: 'POST'
    }).then((resp) => {
        emitter.emit('minicart.updated', {
            html: resp,
            showFlyout: false,
            isMobileClick: isMobileClick
        });
        if (dialog.isOpen()) {
            getProductInfo(params);
        } else {
            $('.js-open-minibag-popup a').trigger('click');
            util.waitForDialog(dialog, function() {
                $('.js-cancel').on('click', closeDialog);
                var container = $('.product-minicartnew');
                var recommenderMissing = true;

                function showRecommenderSlider() {
                    if (container.find('.tiles-container').hasClass('slick-initialized')){
                        $('.product-minicartnew-dialog').removeClass('opacity');
                        recommenderMissing = false;
                    }
                }

                function hideRecommenderSlider() {
                    $('.product-minicartnew_carousel').hide();
                    $('.product-minicartnew-dialog').removeClass('opacity');
                }

                //Check on each half second if there are any recommendations received
                function checkRecommenderSlider() {
                    window.timeoutId = setTimeout(() => {
                        showRecommenderSlider();
                        checkRecommenderSlider();
                    }, 500);
                }

                checkRecommenderSlider();

                //After 5s stop waiting for recommendations, hide carousel and show simple minibag popup
                setTimeout(() => {
                    clearTimeout(window.timeoutId);
                    if (recommenderMissing) {
                        hideRecommenderSlider();
                    }
                }, 5000);
            }, 500)

        }
        if (isPortable()) {
            let $justAdded = $(resp).find('.js-justadded');

            if ($justAdded.length) {
                dialog.open({
                    html: $justAdded.html(),
                    options: {
                        title: resources.PRODUCT_JUST_ADDED
                    }
                });
            }
        }
        emitter.emit(`product.added.${params.id}`, params);
        if (params.isWelcomeBack) {
            emitter.emit('product.added.welcomeback', params);
        }
    });
};

const closeDialog = () => {
    if (dialog.isOpen()) {
        dialog.close();
    }
}

const getProductInfo = (params) => 
    ajax({
        data: params,
        url: urls.miniCartProductInfo,
        type: 'GET'
    }).then((resp) => {
        if(resp.success) {
            updateMiniCartInfo(resp);
        }
    });

const updateMiniCartInfo = (product) =>  {
    var minicartDescription = $('.product-minicartnew_description');
    if(minicartDescription.length > 0) {
        minicartDescription.find('.mini-cart_name').text(product.name);
        minicartDescription.find('.product-attribute[data-attribute="size"] span.product-value').text(product.size);
        minicartDescription.find('.product-attribute[data-attribute="color"] span.product-value').text(product.color);
        minicartDescription.find('.pricing-product_sales_price').text(product.salePrice);
        minicartDescription.find('.pricing-product_standard_price').text(product.standardPrice);
        minicartDescription.find('.mini-cart_image a img').attr("src", product.image);

        if(product.qty > 1) {
            minicartDescription.find('.min-cart_qty').css('display','block');
            minicartDescription.find('.min-cart_qty span.value').text(product.qty);
        } else {
            minicartDescription.find('.min-cart_qty').css('display','none');
        }
    }
};

const updateCartProduct = (params) =>
    ajax({
        data: params,
        url: urls.addProduct,
        type: 'POST'
    }).then((resp) => {
        emitter.emit('updated', {
            html: resp
        });
        if (prefs.IS_CART) {
            return ajax({
                url: urls.cartShow,
                target: '.js-main_cart'
            });
        }
    });

const updateCart = (params) =>
    ajax.load({
        type: params.method,
        url: params.action,
        data: params.fields
    }).then((resp) => {
        emitter.emit('updated', {
            html: resp
        });
    });

const getMinicart = (params) =>
    ajax({
        url: params.url
    }).then((resp) => {
        emitter.emit('minicart.updated', {
            html: resp,
            showFlyout: false
        });
    });

eventMgr.registerAction('cart.add', addToCard);
eventMgr.registerAction('cart.update.product', updateCartProduct);
eventMgr.registerAction('cart.update', updateCart);
eventMgr.registerAction('cart.minicart.get', getMinicart);
