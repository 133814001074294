import Block from './Block';
import eventMgr from '../eventMgr';
import _debounce from 'lodash/debounce';
import $ from 'jquery';

const emitter = eventMgr.getEmitter('Dropdown'),
    LEAVE_DELAY = 400;

$(document).on('mouseup touchend', (e) => {
    emitter.emit('outsideClick', e);
});

export default class Dropdown extends Block {
    init () {
        super.init();
        this.hasTouch = 'ontouchstart' in window;

        this.buttonElement = false;
        if (this.$el.find('button').length > 0 && this.$el.find('button').attr('aria-expanded')) {
            this.buttonElement = this.$el.find('button');
        }

        this.event('focusout', '.dropdown-login_content ul li:last-child', this.hideFlyout);
 
        if (!this.config.noMouseLeave) {
            this.onMouseLeave = _debounce(this.onMouseLeave, LEAVE_DELAY);
            this.event('mouseleave', this.onMouseLeave);
            this.event('mouseenter', this.onMouseEnter);
        }
        this.onChild(this.config.button, 'click', this.onButtonClick);

        if (this.config.opened) {
            this.showFlyout();
        }

        if (this.config.group) {
            this.eventMgr('Dropdown.openInGroup', this.onOpenInGroup);
        }
        if (!this.config.skipOutsideClick) {
            this.eventMgr('Dropdown.outsideClick', this.onOutsideClick);
        }
    }
    onOutsideClick (e) {
        if (this.isOpened() && !this.$el.is(e.target) && // if the target of the click isn't the container...
            this.$el.has(e.target).length === 0) { // ... nor a descendant of the container
            this.hideFlyout();
        }
    }
    onOpenInGroup (group) {
        if (this.config.group === group) {
            this.hideFlyout();
        }
    }
    onMouseEnter () {
        this.onMouseLeave.cancel();
    }
    onMouseLeave (el, e) {
        e.preventDefault();
        this.hideFlyout();
    }
    hideFlyout () {
        if (!this.isOpened()) { return; }

        this.callFnForId(this.config.flyout, 'hide');
        if (this.config.activeClass) {
            this.removeClass(this.config.activeClass);
        }
        this.onHide();
        this.buttonExpanded(false);
    }
    showFlyout () {
        if (this.isOpened()) {
            this.onShow();
            return;
        }

        if (this.config.group) {
            emitter.emit('openInGroup', this.config.group);
        }
        this.callFnForId(this.config.flyout, 'show');
        if (this.config.activeClass) {
            this.addClass(this.config.activeClass);
        }
        this.onShow();
    }
    onButtonClick () {
        if (this.config.noMouseLeave || this.hasTouch) {
            this.getById(this.config.flyout, (flyout) => {
                if (flyout.isShown()) {
                    this.hideFlyout();
                    this.buttonExpanded(false);
                } else {
                    this.showFlyout();
                    this.buttonExpanded(true);
                }
            });
        } else {
            this.showFlyout();
            this.buttonExpanded(true);
        }
    }
    isOpened () {
        var shown = false;

        if (this.config.flyout) {
            this.getById(this.config.flyout, (flyout) => {
                shown = flyout.isShown();
            });
        }

        return shown;
    }
    onShow () {
        this.recalculateSticky();
    }
    onHide () {
        this.recalculateSticky();
    }

    recalculateSticky(){
        if(this.$el.parents('.product-detail').length) {
            var pdpSticky = $('.product-detail').data('sticky');
            if (pdpSticky) {
                pdpSticky.updateSticky();
            }
        }
    }

    buttonExpanded (expanded) {
        if (expanded) {
            if (this.buttonElement) {
                this.buttonElement.attr('aria-expanded', 'true');
            }
        } else {
            if (this.buttonElement) {
                this.buttonElement.attr('aria-expanded', 'false');
            }
        }
    }
}
