'use strict';

import eventMgr from 'eventMgr';
import ajax from 'ajax';
import urls from 'urls';
import Promise from 'promise';
import res from 'resources';
import page from 'pageUtils';

const emitter = eventMgr.getEmitter('checkout');

const updateSummary = (params) =>
    ajax({
        type: 'GET',
        url: urls.summaryRefreshURL,
        data: params
    }).then((summaryHTML) => {
        if (summaryHTML.redirectTo) {
            page.redirect(summaryHTML.redirectTo);
        }
        emitter.emit('summary.updated', summaryHTML);
    });

const setShippingMethod = (address) =>
    ajax({
        type: 'POST',
        url: urls.selectShippingMethodsList,
        data: address
    }).then(() => {
        updateSummary(address);
    });

const setShopId = (params) =>
    ajax({
        type: 'POST',
        url: urls.setCCShopId,
        data: params
    }).then(() => {
        updateSummary(params);
    });

const shippingmethodUpdate = (params) =>
    new Promise((resolve, reject) => {
        ajax({
            type: 'POST',
            url: urls.checkoutShippingMethods,
            data: params
        }).then((result) => {
            if (result.redirectTo) {
                page.redirect(result.redirectTo);
            }
            if (result) {
                emitter.emit('shippingmethod.updated', result);
                updateSummary(params);
                resolve(result);
            } else {
                reject(result.msg);
            }
        }).fail(reject.bind(this, res.BAD_RESPONSE));
    });

const getStores = (params) =>
    new Promise((resolve, reject) => {
        ajax({
            type: 'POST',
            url: urls.checkoutGetStores,
            data: params
        }).then((result) => {
            if (result) {
                emitter.emit('stores.updated', result);
                resolve(result);
            } else {
                reject(result.msg);
            }
        }).fail(reject.bind(this, res.BAD_RESPONSE));
    });

const getBillingAddress = (params) =>
    ajax({
        type: 'POST',
        url: urls.billingAddressForm,
        data: params
    });

eventMgr.registerAction('checkout.shippingmethod.set', setShippingMethod);
eventMgr.registerAction('checkout.shippingmethod.update', shippingmethodUpdate);
eventMgr.registerAction('checkout.stores.get', getStores);
eventMgr.registerAction('checkout.stores.set.id', setShopId);
eventMgr.registerAction('checkout.billing.form.get', getBillingAddress);
